const lightTheme = {
    palette : 
    {
        common:
        {
            black:'#000',
            white:'#FFF'
        },
        /*primary : 
        {
            light: '#08A9EC',
            main : '#029BDD',
            dark: '#0088CA',
            contrastText:'#FFF'
        },*/
        /*primary :
        {
            light: '#0599DE',
            main: '#0D0C28',  //GetBites
            //main:'#0b65a9', //snowie
            dark: '#0076B6',
            contrastText:'#FFF',
            contrastAux:'#595959'
        },*/
        primary :
        {
            light: '#3D3C53',
            main: '#0D0C28',  //GetBites
            //main:'#0b65a9', //snowie
            dark: '#09081C',
            contrastText:'#FFF',
            contrastAux:'#595959'
        },
        primaryContrast :
        {
            light: '#FFF',
            main: '#CCC',  //GetBites
            //main:'#0b65a9', //snowie
            dark: '#bbb',
            contrastText:'#0D0C28',
            contrastAux:'#595959'
        },
        secondary:
        {
            light: '#467BD4',
            main : '#4169c1',//'#c2196b',//'#00d0c6', 0288D1 //F33232 //00d0c6
            dark: '#374BA1',
            contrastText : '#FFF',
        },
        /*secondary:
        {
            light: '#039BE5',
            main : '#4169c1',//'#c2196b',//'#00d0c6', 0288D1 //F33232 //00d0c6
            dark: '#991d1d',
            contrastText : '#FFF',
        },*/
        accent:
        {
            light: '#6EA03F',
            main : '#3b6a25',
            dark: '#4B7637',
            contrastText : '#FFF',
        },
        red:
        {
            light: '#039BE5',
            main : '#F33232',
            dark: '#01579B',
            contrastText : '#FFF',
        },
        primaryAux :
        {
            light: '#FFF',
            main : '#FFF',//'#00d0c6', 0288D1
            dark: '#FFF',
            contrastText : '#0A0A0A',
        },
        cancel:
        {
            light: '#039BE5',
            main:'#CBCEC9',
            dark: '#A1A3A0',
            contrastText : '#000',
        },
        text:
        {
            primary:'#212121',
            secondary:'#474D57',
            primaryTitle:'#EAECEF',
            primaryText:'#B7BDC6',
            link:'#C99400'
        },
        background : 
        {
            default:'#F6F6F6',
            main:'#FFF',
            paper : '#FFF',
            paperDark : '#0B0E11',
            contrastBg:'#FCD535',
            appBar:'#FFF'
        },
        grey:{
            light: '#D6D9DB',
            main:'#c2c7ca',
            dark:'#aeb4b8',
            contrastText:'#4a5760'
        },

    },
    components:
    {
        MuiPaper:
        {
            styleOverrides:{
                root:{
                    borderRadius:10,
                    padding:20,
                    "&:hover":{
                        cursor:'hand'
                    }
                },
            }
        },
        MuiInputBase: {
            styleOverrides:{
                root: {
              
                    "&:hover":{
                        
                    }
                  
                }  
            },
        },
        MuiInputLabel: {
            styleOverrides:{
                root: {
              
                    "&:focused": {
                        color: "#212121"
                    }
                  
                }  
            },
        },
    }
}

export default lightTheme;