import { useContext, useState } from "react";
import { Chip, IconButton, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faPlay, faStop, faTrash } from "@fortawesome/free-solid-svg-icons";
import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";
import { ChangeStatus, GetAll } from "../../../controllers/vendor/LocationsController";
import UserContext from "../../../navigation/context/userContext";

export default function LocationsDataTable(props)
{
    const {userData} = useContext(UserContext);
    const [locations,setLocations] = useState(props.data);

    const handleGetData = async () => {
        let response = await GetAll(userData.user.commerceid);
        if(response.success === true)
        {
            setLocations(response.data);
        }
    }

    const handleChangeStatus = async (id) => {
        let response = await ChangeStatus(id);
        if(response.success === true)
        {
            handleGetData();
        }
    }

    const columns = [
        {
            name : 'branchid',
            label : '#',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'name',
            label : 'Name',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'description',
            label : 'Description',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'street',
            label : 'Address',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'city',
            label : 'City',
            options : 
            {
                filter:true,
                searchable:true
            }
        },
        {
            name : 'state',
            label : 'State',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'zip_code',
            label : 'Zip code',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'status',
            label : 'Status',
            options : 
            {
                customBodyRender : (value, tableMeta, updateValue) => (
                    value === 1
                    ? <Chip label='active' color='success'/>
                    : <Chip label='inactive' color='error' />
                ),
                filter:false,
                searchable:true            
            }
        },
        {
            name : '',
            label : 'Actions',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                            <Link
                                //to='/locations' 
                                onClick = { () => handleChangeStatus(locations[dataIndex].branchid)  }  
                            >
                                <IconButton>
                                {
                                    locations[dataIndex].status === 1
                                    ?
                                    <Tooltip title='Inactivate' placement="top">
                                        <FontAwesomeIcon icon={faStop}/>
                                    </Tooltip>
                                    : 
                                    <Tooltip title='Activate' placement="top">
                                        <FontAwesomeIcon icon={faPlay}/>
                                    </Tooltip>
                                }
                                </IconButton>
                            </Link>

                            
                            <Link
                                to='/locations/update' 
                                onClick={ ()=> localStorage.setItem('SNOWIE_LOCATION',JSON.stringify(locations[dataIndex])) }
                                replace
                            >
                                <Tooltip title='Edit' placement="top">
                                    <IconButton>
                                        <FontAwesomeIcon icon={faEdit}/>
                                    </IconButton>
                                </Tooltip>
                            </Link>
                        </div>
                    );
                },

                filter:false,
                print:false
            }
        }
    ];

    

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
    };

    return(
        <MUIDataTable
            title='Locations List'
            data={locations}
            columns={columns}
            options={options}
        />
    )
}