import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import DashboardIcon from '@mui/icons-material/Dashboard';
import StoreIcon from '@mui/icons-material/Store';

import ReceiptIcon from '@mui/icons-material/Receipt';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GroupIcon from '@mui/icons-material/Group';
import LockIcon from '@mui/icons-material/Lock';
import GroupsIcon from '@mui/icons-material/Groups';
import SchoolIcon from '@mui/icons-material/School';

import YouTubeIcon from '@mui/icons-material/YouTube';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';


import { ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import AuthContext from '../../navigation/context/authContext';





export default function MainListItems(){

    const { signOut } = useContext(AuthContext);
    let history = useHistory();
    
    const redireccionar = (ruta) => {
        history.push(ruta);
    }

    const handleSignOut = () => {
        signOut();
        history.push('/');
    }

    return(
        <div>            
            <ListItem button onClick={() => redireccionar('/') }>
                <Tooltip title='Dashboard' placement="right-start">
                    <ListItemIcon>                
                        <DashboardIcon />
                    </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Dashboard" />
            </ListItem>

            <ListItem button onClick={() => redireccionar('/store/profile') }>
                <Tooltip title='Store Profile' placement="right-start">
                    <ListItemIcon>                
                        <StoreIcon />
                    </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Store Profile" />
            </ListItem>

            <ListItem button onClick={() => redireccionar('/locations') }>
                <Tooltip title='Locations' placement="right-start">
                    <ListItemIcon>
                        <LocationOnIcon />
                    </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Locations" />
            </ListItem>

            <ListItem button onClick={() => redireccionar('/cashiers') }>
                <Tooltip title='Employees' placement="right-start">
                    <ListItemIcon>
                        <GroupIcon />
                    </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Employees" />
            </ListItem>

            <ListItem button onClick={() => redireccionar('/customers') }>
                <Tooltip title='Customers' placement="right-start">
                    <ListItemIcon>
                        <GroupsIcon />
                    </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Customers" />
            </ListItem>

            <ListItem button onClick={() => redireccionar('/transactions') }>
                <Tooltip title='Transactions' placement="right-start">
                    <ListItemIcon>
                        <ReceiptIcon />
                    </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Transactions" />
            </ListItem>

            
            <ListItem button onClick={() => redireccionar('/courses') }>
                <Tooltip title='Transactions' placement="right-start">
                    <ListItemIcon>
                        <SchoolIcon />
                    </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Courses" />
            </ListItem>
      

            <ListItemButton target='_blank' href='https://www.youtube.com/@SnowieRewards/featured'>
                <Tooltip title='Training Videos' placement="right-start">
                    <ListItemIcon>
                        <YouTubeIcon />
                    </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Training Videos" />
            </ListItemButton>

            <ListItem button onClick={()=>redireccionar('/password')}>
                <Tooltip title='Password' placement="right-start">
                    <ListItemIcon>
                        <LockIcon />
                    </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Password" />
            </ListItem>

            <ListItem button onClick={()=>handleSignOut()}>
                <Tooltip title='Logout' placement="right-start">
                    <ListItemIcon>
                        <ExitToAppIcon />
                    </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Logout" />
            </ListItem>
        </div>
    )    
}
    