import { GET, POST } from "../ConsumeApiController";
import CustomerRoutes from "../../apiRoutes/CustomerRoutes";



export async function show_total_amount()
{
    let balance_total = await GET(CustomerRoutes.PROVIDERS.show_total_balance);
    if(balance_total === null)
        return [];

    return balance_total.data;
}


export async function show_detail_amount(providerID)
{
    let transactions = await GET(CustomerRoutes.PROVIDERS.show_detail_amount+providerID);
    return transactions.data;
}

export async function kiosk_listing_by_vendor(providerID)
{
   
    let kiosks = await GET(CustomerRoutes.PROVIDERS.kiosk_listing_by_vendor+providerID);
    
    return kiosks.data;
}


export async function show_supplier_by_kiosko(branchid)
{
    let commerce = await GET(CustomerRoutes.PROVIDERS.show_supplier_by_kiosko+branchid);
    return commerce.data;
}