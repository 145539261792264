import { Grid, Paper, Box, Avatar, Typography } from '@mui/material';

import logo from '../../../assets/images/logos/logo2.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { imagesURL } from '../../../apiRoutes/apiBase';
export default function ProviderCard(props)
{
    const history = useHistory();
    const redirect = (route) => {
        history.push(route)
    }

    return(
        <Grid item xs={12} sm={12}>
            <Paper elevation={3} sx={{'&:hover':{cursor:'pointer'}}}
                onClick={()=>redirect(`/rewards/details/${props.data.supplierid}/${props.data.supplier}`)}
            >
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <Avatar variant='rounded' sx={{width:'100%',height:'auto',bgcolor:'transparent'}}>
                            <img src={imagesURL+'stores/'+props.data.image_path} alt='snowie rewards' style={{width:'100%',height:'auto'}}/>
                        </Avatar>
                    </Grid>
                    <Grid item xs={8} display={'flex'} alignItems={'center'}>
                        <Grid container spacing={3}>
                            <Grid item xs={8} display={'flex'} alignItems={'center'}>
                                <Typography variant='h6' fontWeight={'bold'}>
                                    {props?.data.supplier}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} height={'100%'}>
                                <Typography variant='h4' fontWeight={'bold'} textAlign={'center'}>
                                    {props?.data.total_amount}
                                </Typography>
                                <Typography textAlign={'center'} color={'#757575'}>Points</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}