import { useContext, useEffect, useState } from 'react';
import { Container, Box, Grid, Typography, useTheme, Button } from '@mui/material';
import SectionName from '../../../components/SectionName';

import UserContext from '../../../navigation/context/userContext';
import { kiosk_listing_by_vendor, show_detail_amount } from '../../../controllers/customer/ProvidersController';
import Loader from '../../../components/Loader';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSnowflake } from '@fortawesome/free-solid-svg-icons';
import UserQr from '../../../components/UserQr';
import History from './history';


export default function ProviderDetail()
{
    const theme = useTheme();
    const {userData} = useContext(UserContext);
    const [transactions,setTransactions] = useState([]);
    const [kiosks,setKiosks] = useState([]);
    const [balance, setBalance] = useState(0);

    const [isVisible,setIsVisible] = useState(false);
    const [isLoading,setIsLoading] = useState(false);

    const {providerID,providerName} = useParams();

    const [openQR,setOpenQR] = useState(false);
    const [openHistory,setOpenHistory] = useState(false);

    useEffect(()=>{
        
        GetTransactions();
        GetKiosks();
        window.scrollTo(0,0);
    },[])

    const GetTransactions = async () => {
        setIsLoading(true);
        let _showDetails = await show_detail_amount(providerID);
        const { balance, details } = _showDetails;
        setTransactions(details);
        console.log('balance ===> ',balance[0])
        setBalance(balance[0].total_amount);
        setIsLoading(false);
    }

    const GetKiosks = async () => {
        let _kiosks = await kiosk_listing_by_vendor(providerID);
        setKiosks(_kiosks);
    }

    const handleOpenQR = () => {
        setOpenQR(!openQR);
    }

    const handleCloseQR = () => {
        setOpenQR(false);
        GetTransactions();
    }

    const handleOpenHistory = () => {
        setOpenHistory(!openHistory);
    }

    return(
        <Box sx={{minHeight:'95vh'}}>
            <SectionName title={'Loyalty Card'}/>
            <Container sx={{mt:17}}>
                <Grid container spacing={3} justifyContent={'center'}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant='h6' fontWeight={'bold'} textAlign={'center'}>{providerName}</Typography>
                        <Typography variant='body1' textAlign={'center'} color={'#757575'}>Loyalty Card</Typography>
                    </Grid>
                </Grid>

                {/**row 1 */}
                <Grid container spacing={3} justifyContent={'center'} mt={3}>
                    <Grid item lg={1} xl={1} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
                        <FontAwesomeIcon
                            icon={faSnowflake}
                            size={'4x'}
                            color={
                                balance >= ( (parseInt(balance/6)) + 1 )
                                ? theme.palette.primary.main
                                : theme.palette.grey.main9
                            }
                        />
                    </Grid>
                    <Grid item lg={1} xl={1} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
                        <FontAwesomeIcon
                            icon={faSnowflake}
                            size={'4x'}
                            color={
                                balance >= ( (parseInt(balance/6)) + 2 )
                                ? theme.palette.primary.main
                                : theme.palette.grey.main
                            }
                        />
                    </Grid>
                    <Grid item lg={1} xl={1} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
                        <FontAwesomeIcon
                            icon={faSnowflake}
                            size={'4x'}
                            color={
                                balance >= ( (parseInt(balance/6)) + 3 )
                                ? theme.palette.primary.main
                                : theme.palette.grey.main
                            }
                        />
                    </Grid>
                </Grid>
                
                {/**row 2 */}
                <Grid container spacing={3} justifyContent={'center'} mt={1}>
                    <Grid item lg={1} xl={1} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
                        <FontAwesomeIcon
                            icon={faSnowflake}
                            size={'4x'}
                            color={
                                balance >= ( (parseInt(balance/6)) + 4 )
                                ? theme.palette.primary.main
                                : theme.palette.grey.main
                            }
                        />
                    </Grid>
                    <Grid item lg={1} xl={1} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
                        <FontAwesomeIcon
                            icon={faSnowflake}
                            size={'4x'}
                            color={
                                balance >= ( (parseInt(balance/6)) + 5 )
                                ? theme.palette.primary.main
                                : theme.palette.grey.main
                            }
                        />
                    </Grid>
                    <Grid item lg={1} xl={1} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
                        <FontAwesomeIcon
                            icon={faSnowflake}
                            size={'4x'}
                            color={
                                balance >= ( (parseInt(balance/6)) + 6 )
                                ? theme.palette.primary.main
                                : theme.palette.grey.main
                            }
                        />
                    </Grid>
                </Grid>

                {/**Free snowies */}
                <Grid container spacing={3} mt={2}>
                    <Grid item xs={12} lg={12}>
                        <Typography variant='h2' fontWeight={'bold'} textAlign={'center'} color='primary.main'>{parseInt(balance/6)}</Typography>
                        <Typography variant='body1' textAlign={'center'} fontWeight={'bold'} >Free Snowies Available</Typography>
                    </Grid>
                </Grid>

                {/**Actions */}
                <Grid container spacing={3} mt={1} justifyContent={'center'} pb={5}>
                    <Grid item xs={5} lg={2}>
                        <Button 
                            fullWidth
                            sx={{height:70, boxShadow:1}}
                            variant='outlined'
                            color='secondary'
                            onClick={()=>handleOpenQR()}
                        >
                            <Typography fontWeight={'bold'}>Redeem</Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={5} lg={2}>
                        <Button
                            sx={{height:70, boxShadow:1}}
                            fullWidth
                            variant='outlined'
                            color='secondary'
                            onClick={()=>handleOpenHistory()}
                        >
                            <Typography fontWeight={'bold'}>History</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Container>
            <UserQr
                open={openQR}
                provider={providerName}
                value={userData.user.token_user}
                onClose={()=>handleCloseQR()}
            />
            <History
                open={openHistory}
                provider={providerName}
                transactions={transactions}
                onClose={()=> handleOpenHistory()}
            />
            
            <Loader show={isLoading}/>
        </Box>
    )
}

/**
 * 
 * <Container>
            <SectionName title={'Loyalty Card'}/>
            <Typography textAlign={'center'}>{'HOOOLAAA'}</Typography>
            <Grid container spacing={3} justifyContent={'center'}>
                
                <Grid item lg={1} xl={1} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
                    <FontAwesomeIcon
                        icon={faSnowflake}
                        size={70}
                        color={
                            balance >= ( (parseInt(balance/6) * 6) + 1 )
                            ? theme.palette.primary.main
                            : theme.palette.grey.main
                        }
                    />
                    hola
                </Grid>
            </Grid>
        </Container>
 */