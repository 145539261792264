import { Backdrop, Box, CircularProgress } from "@mui/material";
import Lottie from "lottie-react";


import loaderAnimation from '../assets/lottie/Snowie.json';

export default function Loader(props)
{
    
    return(
        <Backdrop
            sx={{ bgcolor:'#00000040', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props.show}
        >
            <Box sx={{display:'flex', flexDirection:'column',alignItems:'center'}}>
                <Lottie
                    autoPlay
                    style={{
                        width: 340,
                        height: 340,
                    }}
                    animationData={loaderAnimation}
                />
            </Box>
        </Backdrop>
    )
}
