import { Container, Box, Typography, AppBar, Toolbar, } from '@mui/material';
import logo from '../assets/images/logos/logo2.png';
export default function SectionName(props)
{
    return(
        <Box sx={{ flexGrow: 1 }}>
            <AppBar
                position="fixed"
                sx={{
                    bgcolor:'#FFF',
                    borderRadius:0,
                    boxShadow:0,
                    padding:0,
                    pt:2,pb:2,
                    zIndex: (theme) => theme.zIndex.drawer + 1
                    }}
                >
                <Toolbar>
                
            
                        <img
                            style={{width:50,height:50,resizeMode:'stretch',borderRadius:7}}
                            src={logo}
                            alt='GetBites Rewards'
                        />
                        <Typography variant="h5" fontWeight={'bold'} color="primary.main">
                            &nbsp;
                            {props.title}
                        </Typography>
                </Toolbar>
            </AppBar>
        </Box>
        
    )
}