import AdminRoutes from '../apiRoutes/AdminRoutes';
import AuthRoutes from '../apiRoutes/AuthRoutes';
import { GET, POST } from './ConsumeApiController';

/**
 * Function to register a person user type
 * @param email 
 * @param username 
 * @param password
 */
export async function personSignUp(email,username,password)
{
    const params = [
        {name:'email',value:email},
        {name:'username',value:username},
        {name:'password',value:password},
    ];

    let response = await POST(AuthRoutes.personSignUp,params);
    if(response.success === true || response.success === false)
        return response;
    else
        return 0;
}

export async function registerVerify(code)
{
    const params = [
        {name:'code',value:code}
    ];

    let response = await GET(AuthRoutes.RegisterVerify+code);
    return response;
}


export async function login(email,password)
{
    const params = [
        {name:'email',value:email},
        {name:'password',value:password},
    ];

    let response = await POST(AuthRoutes.signIn,params);
    if(response.success === true || response.success === false)
        return response;

}

export async function login2(email,name,provider,uid)
{
    const params = [
        {name:'email',value:email},
        {name:'name',value:name},
        {name:'provider',value:provider},
        {name:'uid',value:uid},
    ];

    let response = await POST(AuthRoutes.signIn2,params);
    return response;
}


/************************************************************************
 |----------------------------------------------------------------------|
 | Functions to reset password flow                                     |
 |----------------------------------------------------------------------| 
 */

/**
  * Function to change password
  * @param currentPassword is the current password,
  * @param password is the new password
  */
export async function changePasswordWithToken(currentPassword,password)
{
    let params = [
        {name:'currentPassword',value:currentPassword},
        {name:'password',value:password},
    ];

    let response = await POST(AuthRoutes.changePasswordWithToken,params);
    return response;
}



/**
 * Function to request code to reset the password
 * @param email the user email
 */
export async function requestCodeToResetPassword(email)
{
   const params = [
       {name:'email',value:email}
   ];

   let response = await POST(AuthRoutes.requestCodeToResetPassword,params);
   return response;
}

/**
* Function to verify email and the confirmation code to reset the password
* @param email
* @param confirmation_code
* 
* @return @true returns true if the combination of email and confirmation_code exist
* 
*/
export async function verifyRequestedCode(email,confirmation_code)
{
   const params = [
       {name:'email',value:email},
       {name:'token',value:confirmation_code}
   ];

   let response = await POST(AuthRoutes.verifyRequestedCode,params);
   return response;
}


export async function changePassword(email,password,confirmation_code)
{
   const params = [
       {name:'email',value:email},
       {name:'password',value:password},
       {name:'confirm_password',value:password},
       {name:'token',value:confirmation_code}
   ];

   let response = await POST(AuthRoutes.changePasswordWithToken,params);
   return response
}




export async function changePasswordLogin(password,confirm_password)
{
    const params = [
        {name:'password',value:password},
        {name:'confirm_password',value:confirm_password}
    ];

    let response = await POST(AdminRoutes.Auth.ChangePassword,params);
    return response;
}


export async function vendorSignUp(name,firstName,lastName,birthdate,sex,postalCode,phoneNumber,email)
{
    const params = [
        {name:'name',value:name},
        {name:'amount_sum',value:1},
        {name:'amount_subtraction',value:6},
        
        {name:'first_name',value:firstName},
        {name:'last_name',value:lastName},
        {name:'birthdate',value:birthdate},
        {name:'sex',value:sex},
        {name:'postal_code',value:postalCode},
        {name:'phone_number',value:phoneNumber},
        {name:'email',value:email}
    ];

    let response = await POST(AuthRoutes.vendorSignUp,params);
    return response;
}