import React, { useContext, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import menuIconSnowie from '../../assets/images/logos/MenuSnowie.webp';
import logo from '../../assets/images/logos/GetBitesRewards_logo.png';
import { Avatar, Button, Menu, MenuItem } from '@mui/material';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import AuthContext from '../../navigation/context/authContext';
import { imagesURL } from '../../apiRoutes/apiBase';
import UserContext from '../../navigation/context/userContext';
import MainListItems from './mainList';
import Copyright from '../../components/copyRight';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';


const drawerWidth = 240;
const tamLogo = 37;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);




export default function AdminLayout(props) {
  const theme = useTheme();
  const {userData,setUserData} = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const { signOut } = useContext(AuthContext);

  const history = useHistory();

  useEffect(()=>{ console.log(imagesURL+'users/'+ userData.user.imagen) },[])

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
      signOut();
      history.push('/');
  }

  return (
    <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}
            sx={{
                borderRadius:0,
                padding:0,
                zIndex: (theme) => theme.zIndex.drawer + 1,
                bgcolor:'#FFF'
            }}
        >
          <Toolbar>
            
            {
                open === false &&
                <Button onClick={handleDrawerOpen} startIcon={<FontAwesomeIcon icon={faBars}/>}>
                    <img src={logo} style={{height:tamLogo}} alt="GetBitesRewards"/>
                </Button>
            }
            <Box
                sx={{
                  display:'flex',
                  width:'100%',
                  flexDirection:'row',
                  justifyContent:'end',
                  display:{xs:'none',sm:'flex'}
                }}
            >
            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                    <Typography color='primary.main' fontWeight='bold'>{userData.user.name}</Typography>
                    <Typography color='primary.main' variant='caption'>(Admin)</Typography>
                </div>
              
              <Button onClick={handleMenu} aria-controls="menu-appbar">
                <Avatar src={imagesURL+'user/'+userData.user.imagen}/>
              </Button>
              
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                  {/**
                  <MenuItem>
                      <Avatar /> My Profile
                  </MenuItem>
                  
                  <Divider />
                  <MenuItem>
                      <ListItemIcon>
                        <SettingsIcon fontSize="small" />
                      </ListItemIcon>
                      Settings
                  </MenuItem>  */}
                  <MenuItem onClick={()=>handleSignOut()}>
                      <ListItemIcon>
                        <ExitToAppIcon fontSize="small" />
                      </ListItemIcon>
                      Logout
                  </MenuItem>
              </Menu>
            </div>
            </Box>
          </Toolbar>
        </AppBar>


        <Drawer variant="permanent" open={open} sx={{position:'relative',whiteSpace:'nowrap','& .MuiPaper-root':{padding:0,borderRadius:0}}}>
            <Toolbar>
                <img src={logo} style={{height:tamLogo}} alt="QUDLogo"/>
                <IconButton style={{marginLeft:25}} onClick={handleDrawerClose}>
                  {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </Toolbar>
            <Divider/>
            <List>
                <MainListItems/>
            </List>
            <Divider />
        </Drawer>

        <Box component="main" sx={{ flexGrow: 1, p: 3, bgcolor:'#FFF' }}>
            <DrawerHeader />
            <Box sx={{minHeight:'80vh'}}>
              {props.children}
            </Box>
            <br/>
            <Copyright/>
        </Box>
    </Box>
  );
}