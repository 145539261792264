import AdminRoutes from "../../apiRoutes/AdminRoutes";
import { GET, POST } from "../ConsumeApiController";


export async function GetAll()
{
    let response = await GET(AdminRoutes.Users.GetAllAdmin);
    return response;
}

export async function Create(name,last_name,phone_number,birthdate,sex,postal_code,email,type_user)
{
    const params = [
        {name:'name',value:name},
        {name:'last_name',value:last_name},
        {name:'phone_number',value:phone_number},
        {name:'birthdate',value:birthdate},
        {name:'sex',value:sex},
        {name:'postal_code',value:postal_code},
        {name:'email',value:email},
        {name:'type_user',value:type_user}
    ];
    let response = await POST(AdminRoutes.Users.Create,params);
    return response;
}

export async function Update(id,name,last_name,phone_number,birthdate,sex,postal_code,email,type_user)
{
    const params = [
        {name:'id',value:id},
        {name:'name',value:name},
        {name:'last_name',value:last_name},
        {name:'phone_number',value:phone_number},
        {name:'birthdate',value:birthdate},
        {name:'sex',value:sex},
        {name:'postal_code',value:postal_code},
        {name:'email',value:email},
        {name:'type_user',value:type_user}
    ];
    let response = await POST(AdminRoutes.Users.Update,params);
    return response;
}


export async function ChangeStatus(id)
{
    let response = await GET(AdminRoutes.Users.ChangeStatus+id);
    return response;
}