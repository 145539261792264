import { useContext, useEffect, useState } from "react";
import { Box, CircularProgress, Container, Grid, Typography } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCashRegister, faChartLine, faHouseUser, faMapPin, faUsers } from "@fortawesome/free-solid-svg-icons";

import AddButton from "../../../components/addButton";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import CardInfo from "../../../components/cardInfo";
import SectionTitle from "../../../components/sectionTitle";
import { GetAll } from "../../../controllers/vendor/LocationsController";
import UserContext from "../../../navigation/context/userContext";
import LocationsDataTable from "./dataTable";

export default function Locations()
{
    const {userData} = useContext(UserContext);
    const [statistics,setStatistics] = useState([]);
    const [locations,setLocations] = useState([]);

    const [isLoading,setIsLoading] = useState(false);

    useEffect(()=>{
        handleGetData();
    },[]);

    const handleGetData = async () => {
        setIsLoading(true);
        let response = await GetAll(userData.user.commerceid);
        if(response.success === true)
        {
            setLocations(response.data);
        }
        setIsLoading(false);
    }

    return(
        <Container maxWidth='xl'>
            <CustomBreadcrumbs  crumbs={[{name:'Locations',link:'/locations'}]} />
            <SectionTitle title='Locations'/>

            <AddButton route='/locations/add'/>
            
            {
                isLoading === true &&
                <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                    <CircularProgress color='primary'/>
                </Box>
            }

            {
                locations.length !== 0 &&
                <LocationsDataTable data={locations}/>
            }
            
        </Container>
    )
}