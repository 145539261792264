import { useContext } from "react";
import { Avatar, Box, Button, Card, CardContent, CardHeader, CardMedia, Container, Divider, Grid, IconButton, LinearProgress, Paper, Typography } from "@mui/material";
import UserContext from "../../../navigation/context/userContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightDots, faArrowUpRightFromSquare, faEllipsisV } from "@fortawesome/free-solid-svg-icons";

import snowie_bg from '../../../assets/images/snowie_bg.png';

import course1 from '../../../assets/images/course1.png';
import course2 from '../../../assets/images/course2.png';
import course3 from '../../../assets/images/course3.png';
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import SectionTitle from "../../../components/sectionTitle";




export default function Courses()
{
    const { userData } = useContext(UserContext);

    const courses = [
        {title:'Course name',image:course1, description:'Course name',lessons:17, taken:12},
        {title:'Course 2',image:course2, description:'Course number 2',lessons:10, taken:0},
        {title:'Course 3',image:course3, description:'Course number 3',lessons:8, taken:0},
    ];

    return(
        <Container maxWidth='lg'>
            <CustomBreadcrumbs crumbs={[{name:'Courses',link:'/courses'}]} />
            <SectionTitle title='Courses'/>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Paper elevation={4}>
                        <Typography>Hi, {userData.user.name}</Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} display={'flex'} flexDirection={'column'} justifyContent={'space-around'}>
                                <Typography variant="h4">
                                    This is the recommended course of the week!
                                </Typography>
                                <Box>
                                    <Button
                                        variant="outlined"
                                        endIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare}/>}
                                    >
                                        Start now
                                    </Button>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Card sx={{width:'100%',boxShadow:'none',bgcolor:'primary.main', backgroundImage: `url(${snowie_bg})`}}>
                                    <CardHeader
                                        avatar={
                                            <Avatar sx={{bgcolor:'transparent'}}><FontAwesomeIcon icon={faArrowUpRightDots}/></Avatar>
                                        }
                                        action={
                                            <IconButton aria-label="settings">
                                              <FontAwesomeIcon color="#FFF" icon={faEllipsisV}/>
                                            </IconButton>
                                          }
                                    />
                                    <CardContent>
                                        <Typography color={'primary.contrastText'} variant="h6" fontWeight={'bold'}>Course name</Typography>
                                        <Box sx={{display:'flex',flexDirection:'row',mb:2}}>
                                            <Typography color={'primary.contrastText'} variant="caption" fontWeight={'bold'}>17 Lessons</Typography>
                                            <Divider sx={{background:'#FFF',ml:2,mr:2}} orientation="vertical" flexItem/>
                                            <Typography color={'primary.contrastText'} variant="caption" fontWeight={'bold'}>70%</Typography>
                                        </Box>
                                        <LinearProgress color="secondary" variant="determinate" value={70} />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item lg={12} mt={3}>
                    <Typography variant="h5">Most popular courses</Typography>
                </Grid>

                {
                    courses &&
                    courses.map(item=>
                        <Grid item lg={4} xl={4}>
                            <Card sx={{width:'100%',p:1.5}}>
                                <CardMedia
                                    sx={{ height: 180, borderRadius:2 }}
                                    image={item.image}
                                    title={item.title}
                                />
                                <Box>
                                    <Typography variant="h6" fontWeight={'bold'}>
                                        {item.title}
                                    </Typography>
                                    <Typography variant="body1">
                                        {item.description}
                                    </Typography>

                                    <Box sx={{display:'flex',flexDirection:'row',mt:5}}>
                                        <Typography variant="caption" fontWeight={'bold'}>{item.lessons} Lessons</Typography>
                                        <Divider sx={{ml:2,mr:2}} orientation="vertical" flexItem/>
                                        <Typography variant="caption" fontWeight={'bold'}>{parseInt(item.taken*100/item.lessons)}% completed</Typography>
                                    </Box>
                                </Box>
                            </Card>
                        </Grid>
                    )
                }
            </Grid>
        </Container>
    )
}