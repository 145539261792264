import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AppRoute from './AppRoute';
import { WebSiteLayout } from '../layouts/Website/WebSiteLayout';
import Home from '../views/website/home';

//AUTH
import SelectUserType from '../views/auth/selectUserType';
import PersonSignUp from '../views/auth/signUp_person';
import SignIn from '../views/auth/signIn';
import ForgotPassword from '../views/auth/forgotPassword';
import EmailVerification from '../views/auth/verify';
import PrivacyPolicy from '../views/website/politics/privacyPolicy';
import ToDeleteAnAccount from '../views/website/politics/ToDeleteAnAccount';
import VendorSignUp from '../views/auth/signUp_company';
import { AuthLayout } from '../layouts/Website/AuthLayout';




export default function WebSiteNavigation()
{
 
    
    return(
        <Switch>
            <AppRoute exact path='/' layout={WebSiteLayout} component={Home} />

            {/**AUTH */}
            <AppRoute exact path='/auth/userType' layout={WebSiteLayout} component={SelectUserType} />
            <AppRoute path='/auth/personSignUp' layout={WebSiteLayout} component={PersonSignUp} />
            <AppRoute path='/auth/commerceSignUp' layout={WebSiteLayout} component={SelectUserType} />
            <AppRoute path='/auth/vendorSignUp' layout={WebSiteLayout} component={VendorSignUp} />
            <AppRoute path='/auth/signIn' layout={AuthLayout} component={SignIn} />
            <AppRoute path='/auth/forgotPassword' layout={WebSiteLayout} component={ForgotPassword} />
            <AppRoute path='/auth/verify/:code' layout={WebSiteLayout} component={EmailVerification} />

            <AppRoute path='/privacypolicy' layout={WebSiteLayout} component={PrivacyPolicy} />
            <AppRoute path='/to-delete-the-user-account' layout={WebSiteLayout} component={ToDeleteAnAccount} />

            {/**Para las rutas no encontradas */}
            <Route path="*">
                <Redirect  to="/"/>
            </Route>
        </Switch>
    )
}
