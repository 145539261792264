import { useState, useEffect } from "react";
import { Avatar, Box, CircularProgress, Container, Grid, Paper, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import { GetAll, GetTransactionsStatitics } from "../../../controllers/admin/TransactionsController";
import SectionTitle from "../../../components/sectionTitle";
import TransactionsDataTable from "./dataTable";
import CardInfo from "../../../components/cardInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faInfoCircle, faQrcode, faSnowflake, faTrophy, faUsers, faWind } from "@fortawesome/free-solid-svg-icons";
import CustomTooltip from "../../../components/tooltip";

export default function Transactions()
{
    const [transactions,setTransactions] = useState([]);
    const [statistics,setStatistics] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    const [points,setPoints] = useState('');
    const [redeemed,setRedeemed] = useState('');
    const [unredeemed,setUnredeemed] = useState('');
    const [scans,setScans] = useState([]);

    useEffect(() => {
        handleGetAll();
        handleTransactionsStatitics();
    },[])

    const handleGetAll = async () => {
        setIsLoading(true);
        let response = await GetAll();
        if(response.success === true)
        {
            setTransactions(response.data.transactions);
            setStatistics(response.data.statistics);
        }
        setIsLoading(false);
    }

    const handleTransactionsStatitics = async () => {
        setIsLoading(true);
        let response = await GetTransactionsStatitics()
        if(response.success === true)
        {
            setPoints(response.data.points);
            setScans(response.data.scans);
            setRedeemed(response.data.redeemed);
            setUnredeemed(response.data.unredeemed);
        }
        setIsLoading(false);
    }

    return(
        <Container maxWidth='xl'>
            <CustomBreadcrumbs crumbs={[{name:'Transactions',link:'/transactions'}]} />
            <SectionTitle title='Transactions'/>
            
            {
                isLoading === true
                ?
                <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                    <CircularProgress color='primary'/>
                </Box>
                :
            
                <>
                    <Grid container spacing={3} mb={3}>
                        {/**Points/Snowflakes */}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                            <Paper elevation={4} sx={{height:'100%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                <Grid container spacing={3} alignItems='center'>
                                    <Grid item lg={2}>
                                        <Avatar sx={{ width: 70, height: 70, bgcolor:'primary.main' }}>
                                            <FontAwesomeIcon size='2x' icon={faSnowflake}/>
                                        </Avatar>
                                    </Grid>
                                    <Grid item lg={10}>
                                        <Grid container spacing={3} >
                                            <Grid item lg={12} xl={12}>
                                                <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                    <Typography ml={1} fontSize={20}>Points/Snowflakes</Typography>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <CustomTooltip
                                                        title={'Traditionally known as punches on a punch card, these are every punch/point that a customer receives for each Bite they purchase. For example, if they buy three Bites they can receive three points. When they qualify for a free Bite (have 6 points) and they receive the free Bite, they lose or rather redeem the 6 points in for the Free Bite.'}
                                                        icon={<FontAwesomeIcon color="#b3b3b3" icon={faInfoCircle}/>}
                                                    />
                                                    
                                                </Box>
                                                <Typography ml={1} fontSize={20}>{points}</Typography>
                                            </Grid>
                                            
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        {/**Scans */}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Paper elevation={4}>
                                <Grid container spacing={3} alignItems='center'>
                                    <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
                                        <Avatar sx={{ width: 70, height: 70, bgcolor:'#000' }}>
                                            <FontAwesomeIcon size='2x' icon={faQrcode}/>
                                        </Avatar>
                                    </Grid>
                                    <Grid item xs={8} sm={8} md={10} lg={10} xl={10}>
                                        <Grid container spacing={3} >
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                    <Typography ml={1} fontSize={20}>Scans</Typography>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <CustomTooltip
                                                        title='These are everytime you use your scanner in the GetBites Rewards app. This could be for giving points or giving free bites (removing 6 points).  Each scan can be a different amount of Bites or Rewards. For example, 1 scan can be for five points (if a customer bought 5 Bites). Likewise, 1 scan could be for multiple rewards.'
                                                        icon={<FontAwesomeIcon color="#b3b3b3" icon={faInfoCircle}/>}
                                                    />
                                                </Box>
                                            </Grid>
                                            {
                                                scans.length !== 0 &&
                                                scans.map((item)=>
                                                    <Grid item lg={6} xl={6} sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                        <Typography ml={1} mt={-3} fontSize={15}>{item.type}:</Typography>
                                                        <Typography ml={1} mt={-3} fontSize={20}>{item.scans}</Typography>
                                                    </Grid>
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>


                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Paper elevation={4}>
                                <Grid container spacing={3} alignItems='center'>
                                    <Grid item lg={2}>
                                        <Avatar sx={{ width: 70, height: 70, bgcolor:'success.main' }}>
                                            <FontAwesomeIcon size='2x' icon={faTrophy}/>
                                        </Avatar>
                                    </Grid>
                                    <Grid item lg={10}>
                                        <Grid container spacing={3} >
                                            <Grid item lg={12} xl={12}>
                                                <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                    <Typography ml={1} fontSize={20}>Redeemed Rewards</Typography>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <CustomTooltip
                                                        title='These are the number of rewards that all your customers have redeemed / received free bites.'
                                                        icon={<FontAwesomeIcon color="#b3b3b3" icon={faInfoCircle}/>}
                                                    />
                                                </Box>
                                                <Typography ml={1} fontSize={20}>{redeemed}</Typography>
                                            </Grid>
                                            
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>


                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Paper elevation={4}>
                                <Grid container spacing={3} alignItems='center'>
                                    <Grid item lg={2}>
                                        <Avatar sx={{ width: 70, height: 70, bgcolor:'secondary.main' }}>
                                            <FontAwesomeIcon size='2x' icon={faWind}/>
                                        </Avatar>
                                    </Grid>
                                    <Grid item lg={10}>
                                        <Grid container spacing={3} >
                                            <Grid item lg={12} xl={12}>
                                                <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                    <Typography ml={1} fontSize={20}>Unredeemed Rewards</Typography>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <CustomTooltip
                                                        title='These are the number of rewards that all your customers have saved in their GetBites Rewards App and still can redeem for a free bite. (1 Unredeemed Reward = 6 saved points).'
                                                        icon={<FontAwesomeIcon color="#b3b3b3" icon={faInfoCircle}/>}
                                                    />
                                                </Box>
                                                <Typography ml={1} fontSize={20}>{unredeemed}</Typography>
                                            </Grid>
                                            
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>


                    {/*<Grid container spacing={3} mb={5} justifyContent='center'>
                        <CardInfo
                            bgcolor='success.main'
                            icon={<FontAwesomeIcon icon={faChartLine}/>}
                            title='transactions'
                            value={transactions.length}
                        />
                        {
                            statistics.length !== 0 &&
                            statistics.map(item => 
                                <CardInfo
                                    bgcolor={item.type === 'rewards' ? 'primary.main' : 'secondary.main'}
                                    icon={<FontAwesomeIcon icon={item.type === 'rewards' ? faSnowflake : faWind}/>}
                                    title={item.type}
                                    value={item.total}
                                />
                            )
                        }
                    </Grid>*/}
                    

                    {
                        transactions.length !== 0 &&
                        <TransactionsDataTable data={transactions}/>
                    }
                </>
            }
        </Container>
    )

    return(
        <Container>
            <CustomBreadcrumbs returnTo='/' crumbs={[{name:'Transactions',link:'/transactions'}]} />
            <SectionTitle title='Transactions'/>
            {/**Cards - Info */}
            <Grid container spacing={3} mb={5} justifyContent='center'>
                <CardInfo
                    bgcolor='success.main'
                    icon={<FontAwesomeIcon icon={faChartLine}/>}
                    title='transactions'
                    value={transactions.length}
                />
                {
                    statistics.length !== 0 &&
                    statistics.map(item => 
                        <CardInfo
                            bgcolor={item.type === 'rewards' ? 'primary.main' : 'secondary.main'}
                            icon={<FontAwesomeIcon icon={item.type === 'rewards' ? faSnowflake : faWind}/>}
                            title={item.type}
                            value={item.total}
                        />
                    )
                }
            </Grid>
            {
                isLoading === true &&
                <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                    <CircularProgress color='primary'/>
                </Box>
            }

            {
                transactions.length !== 0 &&
                <TransactionsDataTable data={transactions}/>
            }

        </Container>
    )
}