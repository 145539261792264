import { useState } from "react";
import { Chip, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faPlay, faTrash } from "@fortawesome/free-solid-svg-icons";
import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";

export default function TransactionsDataTable(props)
{
    const [transactions,setTransactions] = useState(props.data);

    const columns = [
        {
            name : 'id',
            label : '#',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'created_at',
            label : 'date',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'vendor',
            label : 'Vendor',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'customer',
            label : 'Customer',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'transaction_type',
            label : 'Type',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'amount',
            label : 'Points',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
    ];

    

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
    };

    return(
        <MUIDataTable
            title='Transactions List'
            data={transactions}
            columns={columns}
            options={options}
        />
    )
}