import { useEffect, useState } from "react";
import { Box, Container, Divider, Grid, Stack, Switch, Tab, Tabs, TextField, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import SectionTitle from "../../../components/sectionTitle";

import {  faChartLine, faHouseUser, faMapPin, faSearch, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CardInfo from "../../../components/cardInfo";

import LocationCard from "./details/locationCard";
import { LoadingButton } from "@mui/lab";
import LocationsDataTable from "./details/locationsDataTable";
import { Search } from "../../../controllers/admin/VendorsController";
import CustomersDataTable from "./details/customersDataTable";
import TransactionsDataTable from "./details/transactionsDataTable";
import { useParams } from "react-router-dom";
import { GetAllCustomersByVendor } from "../../../controllers/vendor/TransactionsController";


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
            <Box sx={{ p: 3 }}>
                {children}
            </Box>
            )}
        </div>
    );
}



export default function VendorDetails()
{
    const [tabValue,setTabValue] = useState(0);
    const [showDataTable,setShowDatatable] = useState(false);

    const [vendor,setVendor] = useState([]);
    const [kiosks,setKiosks] = useState([]);
    const [customers,setCustomers] = useState([]);
    const [transactions,setTransactions] = useState([]);

    const {id} = useParams();

    useEffect(() => {
        handleSearch();
        //handleGetCustomersByVendor();
    },[]);

    const handleSearch = async () => {
        let response = await Search(id);
        if(response.success === true)
        {
            setKiosks(response.data.kiosks);
            //setCustomers(response.data.customers);
            setTransactions(response.data.transactions);
            setVendor(response.data.vendor);
            await handleGetCustomersByVendor(response.data.vendor.commerceid);

        }
    }

    const handleGetCustomersByVendor = async (vendor_id) => {
        let response = await GetAllCustomersByVendor(vendor_id);
        if(response.success === true)
        {
            setCustomers(response.data.customers);
        }
    }



    const handleTabValueChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleSwitchDataTableShow = () => {
        setShowDatatable(!showDataTable);
    }

    return(
        <Container>
            <CustomBreadcrumbs
                returnTo='/vendor'
                crumbs={[
                    {name:'Vendor',link:'/vendor'},
                    {name:'Details',link:'/vendor/details'}
                ]}
            />
            <SectionTitle title='Vendor Details' subtitle={vendor.commerce}/>
            
            {/** for search filter */}
            {/*
            <Grid container spacing={3} sx={{mb:5}}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <TextField
                        fullWidth
                        label='Location'
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <TextField
                        fullWidth
                        label='Customer'
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <LoadingButton
                        sx={{height:'100%'}}
                        fullWidth
                        variant="outlined"
                        endIcon={<FontAwesomeIcon icon={faSearch} rotation={90} />}
                    >
                        <Typography>Search</Typography>
                    </LoadingButton>
                </Grid>
            </Grid>
            */}

            {/**Cards - Info */}
            <Grid container spacing={3} justifyContent='center'>
                <CardInfo
                    bgcolor='primary.main'
                    icon={<FontAwesomeIcon icon={faMapPin}/>}
                    title='locations'
                    value={kiosks.length}
                />

                <CardInfo
                    bgcolor='secondary.main'
                    icon={<FontAwesomeIcon icon={faUsers}/>}
                    title='Customers'
                    value={customers.length}
                />

                <CardInfo
                    bgcolor='accent.main'
                    icon={<FontAwesomeIcon icon={faChartLine} />}
                    title='Transactions'
                    value={transactions.length}
                />
            </Grid>

            <Grid container spacing={3} sx={{mt:5}}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={tabValue} onChange={handleTabValueChange}  aria-label="basic tabs example" centered>
                                <Tab label="Info"/>
                                <Tab label="Locations"/>
                                <Tab label="Customers" />
                                <Tab label="Transactions" />
                            </Tabs>
                        </Box>

                        {/** INFO */}
                        <TabPanel value={tabValue} index={0}>
                            <Grid container spacing={3} sx={{p:2}}>
                                <Grid item xs={12} sm={6} md={6} lg={5} xl={5}>
                                    <Typography variant='h6' sx={{fontWeight:'bold'}}>Personal</Typography>
                                    <br/>
                                    {
                                        vendor.length !== 0 &&
                                        <>
                                            <Typography><span style={{fontWeight:'bold'}}>Name:</span> {vendor.name}</Typography>
                                            <Typography><span style={{fontWeight:'bold'}}>Last name:</span> {vendor.last_name}</Typography>
                                            <Typography><span style={{fontWeight:'bold'}}>Phone number:</span> {vendor.phone_number}</Typography>
                                            {/*<Typography><span style={{fontWeight:'bold'}}>Birthdate:</span> 1991/09/27</Typography>
                                            <Typography><span style={{fontWeight:'bold'}}>Sex:</span> Male</Typography>*/}
                                        </>
                                    }
                                </Grid>
                                
                                <Divider sx={{display:{xs:'none',sm:'none',md:'none',lg:'flex',xl:'flex'}}} orientation="vertical" flexItem />
                                <Divider sx={{display:{xs:'flex',sm:'flex',md:'flex'}}} orientation="horizontal" flexItem />
                                
                                <Grid item xs={12} sm={6} md={6} lg={5} xl={5}>
                                    <Typography variant='h6' sx={{fontWeight:'bold'}}>User</Typography>
                                    <br/>
                                    {
                                        vendor.length !== 0 &&
                                        <>
                                            <Typography><span style={{fontWeight:'bold'}}>Email:</span> {vendor.email}</Typography>
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        </TabPanel>

                        {/** KIOSKS */}
                        <TabPanel value={tabValue} index={1}>
                            {/*<Grid container spacing={3} justifyContent='center' mt={2} mb={2}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography>See cards</Typography>
                                    <Switch name="switch" value={showDataTable} onChange={()=>handleSwitchDataTableShow()}/>
                                    <Typography>See data table</Typography>
                                </Stack>
                            </Grid>
                            {
                                showDataTable === false
                                ?
                                <Grid container spacing={3}>
                                    {
                                        kiosks.length !== 0 &&
                                        kiosks.map(item => 
                                            <LocationCard
                                                src={'https://snowie.com/images/kiosk_123-360x360.jpg'}
                                                name={item.name}
                                                description={item.description}
                                                customers={30}
                                                transactions={127}
                                                address='1600 W Beardsley Place, Salt Lake City, UT 84119'
                                            />
                                        )
                                    }
                                </Grid>
                                :
                                <LocationsDataTable
                                    data={locations}
                                />

                                */}
                             <LocationsDataTable
                                    data={kiosks}
                                />
                        </TabPanel>


                        {/** CUSTOMERS */}
                        <TabPanel value={tabValue} index={2}>
                            {
                                customers.length !== 0 &&
                                <CustomersDataTable data={customers}/>
                            }
                        </TabPanel>


                        {/** TRANSACTIONS */}
                        <TabPanel value={tabValue} index={3}>
                            {
                                transactions.length !== 0 &&
                                <TransactionsDataTable data={transactions}/>
                            }
                        </TabPanel>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}