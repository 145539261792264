import { url } from "./apiBase";

const AdminRoutes = {
    Dashboard:
    {
        CountCustomers : url + 'v1/dashboard/customer',
        CountTransactions : url + 'v1/dashboard/transactions',
        GetTop10 : url + 'v1/dashboard/transactions/top10',
        GetTransactionsStatitics : url + 'v1/dashboard/transactions/statitics'
    },
    Vendors:
    {
        GetAll : url + 'v1/commerce/all',
        Search : url + 'v1/commerce/search/', //complete with userID (Vendor)
        Create : url + 'v1/commerce/create',
        Update : url + 'v1/commerce/edit/', //complete with userID (Vendor)
        ChangeStatus: url + 'v1/users/destroy/'
    },
    Customers:
    {
        GetAll : url + 'v1/customers',
        ChangeStatus: url + 'v1/users/destroy/',
        GetCustomersStatitics: url + 'v1/dashboard/customers/', // complete with {initialDate}/{finalDate}
        Remove: url + 'v1/customers/delete/', //complete with {id}
    },
    Transactions:
    {
        GetAll : url + 'v1/transaction/getAllTransactions',
    },
    Users:
    {
        GetAllAdmin : url + 'v1/users/type/3',
        Create : url + 'v1/users/create',
        Update : url + 'v1/users/edit',
        ChangeStatus: url + 'v1/users/destroy/'
    },
    Auth:
    {
        ChangePassword : url + 'auth/password/reset',
    }
};


export default AdminRoutes;