import React from 'react';
import { Avatar, Box, Button, Container, Divider, Grid, IconButton, Paper, Typography, useTheme} from '@mui/material';

import { motion } from "framer-motion"

import bitten_cookies from '../../assets/images/website/bitten_cookie.png';
import phone from '../../assets/images/website/phone2.png';
import wavy_lines from '../../assets/images/website/woman2.png';
import gbr from '../../assets/images/getbitesapp.png';
import googlePlay from '../../assets/images/stores/GooglePlay.png';
import appleStore from '../../assets/images/stores/AppleStore.png';
import { useHistory } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGooglePlay, faAppStore } from '@fortawesome/free-brands-svg-icons';
import QRCode from 'react-qr-code';


export default function Home()
{
    const history = useHistory();
    const theme = useTheme();

    const redireccionar = (route) => {
        history.push(route);
    }


    const container = {
        hidden: { opacity: 1, scale: 0 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                delayChildren: 0.3,
                staggerChildren: 0.2
          }
        }
    };
      
    const item = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1
        }
    };


    return(
        <Box mt={10} mb={10} sx={{overFlowX:'hidden'}}>
            <Box sx={{overFlowX:'hidden',overflowY:'hidden',position:'relative',minHeight:'60vh'}}>
                <Box sx={{position:'absolute',top:{xs:240,sm:200,md:50,lg:0,xl:0},width:{xs:300,sm:300,md:350,lg:500}}}>
                    <img
                        src={bitten_cookies}
                        alt='GetBites Rewards'
                        style={{width:'100%'}}
                    />
                </Box>
                <Container>
                    <Grid container spacing={3} justifyContent={'center'}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography variant='h2' fontWeight={'bold'} textAlign={'center'} sx={{display:{xs:'none',sm:'none',md:'block',lg:'block',xl:'block'}}}>
                                Free rewards are just
                                <br/>
                                a bites away.
                            </Typography>
                            <Typography variant='h3' fontWeight={'bold'} textAlign={'center'} sx={{display:{xs:'block',sm:'block',md:'none',lg:'none',xl:'none'}}}>
                                Free rewards are just
                                <br/>
                                a bites away.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={1}>
                            <Typography variant='h6' fontWeight={'bold'} textAlign={'center'}>
                                Join now to start earning Rewards
                            </Typography>
                        </Grid>

                        <Grid item xs={8} sm={4} md={4} lg={2} xl={2}>
                            <Button fullWidth variant='contained' sx={{borderRadius:10}} onClick={()=>redireccionar('/auth/signIn')}>
                                <Typography variant='h6'>Join now</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Box>


            <Container maxWidth='md'>
                <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                    <img
                        src={phone}
                        alt='GetBites Rewards'
                        style={{width:'100%',zIndex:-100}}
                    />
                </Box>
                <Paper elevation={4} sx={{pt:5,pb:5,bgcolor:'primary.main',color:'primary.contrastText'}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                            
                            <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                                <IconButton
                                    color='secondary'
                                    href='https://play.google.com/store/apps/details?id=com.technologicalsharks.getbitesrewards&hl=en_419&gl=US' target='_blank'
                                >
                                    <FontAwesomeIcon size='2x' icon={faGooglePlay}/>
                                </IconButton>
                                <IconButton
                                    color='secondary'
                                    sx={{ml:1}}
                                    href='https://apps.apple.com/us/app/getbites/id6479451401' target='_blank'
                                >
                                    <FontAwesomeIcon size='2x' icon={faAppStore}/>
                                </IconButton>
                            </Box>
                            <Typography textAlign={'center'} variant='body1' mt={2}>Download the app</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Typography variant='h4' fontWeight={'bold'}>
                                Join in the app for the
                            </Typography>
                            <Typography variant='h4' fontWeight={'bold'}>
                                best experience
                            </Typography>
                            <Typography variant='body1' mt={3}>
                                Get the best of GetBites Rewards right at
                            </Typography>
                            <Typography variant='body1'>
                                your fringerprints
                            </Typography>

                            <Typography variant='body1' mt={3}>
                                Find stores, redeem rewards and so much more
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>

            <br/><br/><br/><br/>
            <Container maxWidth='lg'>
                <Grid container spacing={3} justifyContent={'center'}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant='h3' fontWeight={'bold'} textAlign={'center'}>
                            Getting started is easy
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                        <Typography variant='h6' fontWeight={'bold'} textAlign={'center'}>
                            Earn points and get rewarded in a few
                            <br/>easy steps
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <Paper elevation={4}>
                            <Grid container spacing={3}>
                                <Grid item sx={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid>
                                        <Avatar
                                            sx={{
                                                bgcolor:'secondary.main',
                                                border:3,
                                                borderColor:'secondary.main'
                                            }}
                                        >
                                            <Typography variant='h6' fontWeight={'bold'} color={'primary.contrasText'}>1</Typography>
                                        </Avatar>
                                    </Grid>
                                </Grid>
                                <Grid item sx={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography variant='h6' fontWeight={'bold'}>
                                        Create an account
                                    </Typography>
                                    <Typography variant='h6'>
                                    To get started, join now. You can also join in the app to get access to the full range of GetBites Rewards benefits
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <Paper elevation={4}>
                            <Grid container spacing={3}>
                                <Grid item sx={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid>
                                        <Avatar
                                            sx={{
                                                bgcolor:'primary.main',
                                                border:3,
                                                borderColor:'primary.main'
                                            }}
                                        >
                                            <Typography variant='h6' fontWeight={'bold'} color={'primary.contrastText'}>
                                                2
                                            </Typography>
                                        </Avatar>
                                    </Grid>
                                </Grid>
                                <Grid item sx={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography variant='h6' fontWeight={'bold'}>
                                        Visit the store
                                    </Typography>
                                    <Typography variant='h6'>
                                        Visit the business of your choice and do what you always do, make a purchase of their products/services
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4} position={'relative'} sx={{mt:{xs:25,sm:25,md:25,lg:0,xl:0}}}>
                        <Box sx={{position:'absolute',zIndex:-10,top:-200,right:0,width:250,height:250}}>
                            <img
                                src={wavy_lines}
                                alt='GetBites Rewards'
                                style={{width:'100%'}}
                            />
                        </Box>
                        <Paper elevation={4}>
                            <Grid container spacing={3}>
                                <Grid item sx={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid>
                                        <Avatar
                                            sx={{
                                                bgcolor:'error.main',
                                                border:3,
                                                borderColor:'error.main'
                                            }}
                                        >
                                            <Typography variant='h6' fontWeight={'bold'} color={'error.contrastText'}>
                                                3
                                            </Typography>
                                        </Avatar>
                                    </Grid>
                                </Grid>
                                <Grid item sx={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography variant='h6' fontWeight={'bold'}>
                                        Earns Points, get Rewards
                                    </Typography>
                                    <Typography variant='h6'>
                                        As you earn Points, you can redeem them for Rewards like free food, drinks, and more.
                                        
                                    </Typography>
                                </Grid>
                                
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>

            
            

            {/*<Container maxWidth='lg'>
                <Grid container spacing={3} justifyContent={'center'}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant='h3' fontWeight={'bold'} textAlign={'center'}>
                            The best way<br/>to get rewards
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                        <Typography variant='h6' fontWeight={'bold'} textAlign={'center'}>
                            With our app, you can earn rewards for doing the things you already do.
                        </Typography>
                    </Grid>

                   
                </Grid>
                </Container>*/}
        </Box>
    )
}


const styles={
    colCenter:
    {
        display:'flex',
        flexDirection:'column',
        alignItems:'center'
    }
}