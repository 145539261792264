import { url, v1 } from "./apiBase";

const CustomerRoutes = {
    AUTH :
    {
        login : url + 'auth/login',
        signUp : url + 'auth/signup',
        forgotPassword : 
        {
            requestCode : url + 'auth/password/email',
            verifyCode : url + 'auth/password/verify',
            resetPassword : url + 'auth/password/forgot/reset'
        }
    },
    PROFILE :
    {
        changePassword : url + '',
        setLocation : url + v1 + 'cashiers/setLocation',
        show : url + v1 + 'profile/show',
        photo64 : url + v1 + 'profile/photo64',
        update : url + v1 +'profile/profile',
        delete: url + v1 + 'profile/delete',
    },
    PROVIDERS :
    {
        show_total_balance : url + v1 + 'transaction/showtotal',
        show_detail_amount : url + v1 + 'transaction/showdetail/', //completar con el ID
        kiosk_listing_by_vendor : url + v1 + 'branch/kiosk_listing/',//completar con el ID
        kiosk_listing_by_cashier : url + v1 + 'branch/kiosk_listing_by_cashier',//Auth user (cashier)
        show_supplier_by_kiosko : url + v1 + 'commerce/show/supplier/', //completar con {branchid}
    },
    TRANSACTIONS :
    {
        setTransaction : url + v1 + 'transaction/setTransaction',
        show_detail_kiosko : url + v1 + 'transaction/GetAllTransactionsByBranch/' //completar con {branchid}
    }
};


export default CustomerRoutes;