import { useState } from "react";
import { Chip, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faPlay, faStop, faTrash } from "@fortawesome/free-solid-svg-icons";
import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";
import { ChangeStatus, GetAll } from "../../../controllers/admin/CustomersController";
import { PatternFormat } from "react-number-format";

export default function CustomersDataTable(props)
{
    const [customer,setCustomer] = useState(props.data);

    const handleGetData = async () => {
        let response = await GetAll();

        if(response.success === true)
        {
            setCustomer(response.data);
        }
    }

    const columns = [
        {
            name : 'name',
            label : 'Name',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'phone_number',
            label : 'Phone Number',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <PatternFormat format="(###) ###-####" displayType="text"  value={customer[dataIndex].phone_number} />
                    );
                },
                filter:false,
                searchable:true
            }
        },
        {
            name : 'email',
            label : 'Email',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'status',
            label : 'status',
            options : 
            {
                customBodyRender : (value, tableMeta, updateValue) => (
                    value === 1
                    ? <Chip label='active' color='success'/>
                    : <Chip label='inactive' color='error' />
                )            
            }
        },
        
    ];


    const handleChangeStatus = async (id) => {
        let response = await ChangeStatus(id);
        if(response.success === true)
        {
            handleGetData();
        }
    }

    

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
    };

    return(
        <MUIDataTable
            title='Customers List'
            data={customer}
            columns={columns}
            options={options}
        />
    )
}