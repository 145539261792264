import { useContext, useEffect, useState } from "react";
import { Container, Grid, Typography, Divider, TextField, Box, Button } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import SectionTitle from "../../../components/sectionTitle";

import { Avatar } from "@files-ui/react";
import { getSettings, updateLogo, updateSettings } from "../../../controllers/vendor/SettingStoreController";
import { imagesURL } from "../../../apiRoutes/apiBase";
import ResponseComponent from "../../../components/ResponseComponent";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function StoreProfile()
{
    const [isLoading,setIsLoading] = useState(false);
    const [files, setFiles] = useState([]);

    const [name,setName] = useState('');
    const [description,setDescription] = useState('');
    const [biteAdds,setBiteAdds] = useState(0);
    const [rewardSubstracts,setRewardSubtracts] = useState(0);
    const [logo,setLogo] = useState();

    const [show,setShow] = useState(false);
    const [success,setSuccess] = useState(false);
    const [title,setTitle] = useState('');
    const [message,setMessage] = useState('');
    const [actionText,setActionText] = useState('Finalizar');
    const [secondaryText,setSecondaryText] = useState('SI');

    const history = useHistory();


    useEffect(()=>{
        handleGetSettingStore();
    },[]);

    const redirect = (route) => {
        history.push(route)
    }


    const handleGetSettingStore = async () => {
        setIsLoading(true);
        let response = await getSettings();
        if(response.success === true)
        {
            setName(response.data.store.name);
            setDescription(response.data.store.description);
            setBiteAdds(response.data.store.amount_sum);
            setRewardSubtracts(response.data.store.amount_subtraction);
            setLogo(imagesURL+'stores/'+response.data.store.image_path);
        }
        setIsLoading(false);
    }


    const handleChangeLogo = async (incommingFiles) => {
        console.log('SELECTED FILE ===> ',incommingFiles);
        setLogo(incommingFiles);
        //subir la foto al servidor
        setIsLoading(true);
        let response = await updateLogo(incommingFiles);
        if(response.success === true)
        {
            setSuccess(true);
            setTitle('Success');
            setMessage(response.message);
            setShow(true);
        }
        else
        {
            setSuccess(false);
            setTitle('Error');
            setMessage(response.message);
            setShow(true);
        }
        setIsLoading(false);
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        let response = await updateSettings(name,description);
        if(response.success === true)
        {
            setSuccess(true);
            setTitle('Success');
            setMessage(response.message);
            setShow(true);
        }
        else
        {
            setSuccess(false);
            setTitle('Error');
            setMessage(response.message);
            setShow(true);
        }
        setIsLoading(false);
    }

    const handleClose = () => {
        setShow(false);
    }

    return(
        <Container maxWidth='xl'>
            <CustomBreadcrumbs crumbs={[{name:'Store Profile',link:'/store/profile'}]} />
            <SectionTitle title='Store Setup'/>

            <Grid container spacing={3} justifyContent={'center'}>
                <Grid item xs={8} sm={4} md={4} lg={3} xl={3}>
                    <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                        <Avatar
                            style={{width:256,height:256}}
                            src={logo}
                            
                            alt="Avatar"
                            onChange={handleChangeLogo}
                            //value={imageProfile}
                        />
                    </Box>
                    <Typography textAlign={'center'}>Store Logo</Typography>
                </Grid>
                

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={3} justifyContent={'center'}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography variant='h6'>Settings</Typography>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField
                                        fullWidth
                                        id="name"
                                        name="name"
                                        label="Store's name"
                                        value={name}
                                        onChange={(e)=>setName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={6}
                                        id="description"
                                        name="description"
                                        label="About store"
                                        value={description}
                                        onChange={(e)=>setDescription(e.target.value)}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextField
                                        fullWidth
                                        disabled
                                        id="biteAdds"
                                        name="biteAdds"
                                        label="Each bite adds up (points)"
                                        value={biteAdds}
                                        onChange={(e)=>setName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextField
                                        fullWidth
                                        disabled
                                        id="rewardSubstracts"
                                        name="rewardSubstracts"
                                        label="Each reward subtracts (points)"
                                        value={rewardSubstracts}
                                        onChange={(e)=>setDescription(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                                    <LoadingButton
                                        fullWidth
                                        loading={isLoading}
                                        variant="contained"
                                        onClick={()=>handleSubmit()}
                                    >
                                        <Typography>Update</Typography>
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        
                    </Grid>
                </Grid>
            </Grid>

            <ResponseComponent 
                show={show}
                success={success}
                title={title}
                message={message}
                actionText='Done'
                onClick={()=>handleClose()}
            />
        </Container>
    )
}