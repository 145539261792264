import { useContext, useReducer, useState } from "react";
import { Container, Grid, MenuItem, TextField, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import SectionTitle from "../../../components/sectionTitle";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { NumberFormatBase, PatternFormat } from "react-number-format";
import { LoadingButton } from "@mui/lab";
import { Create } from "../../../controllers/admin/VendorsController";
import ModalDialog from "../../../components/modalDialog";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Add } from "../../../controllers/vendor/LocationsController";
import UserContext from "../../../navigation/context/userContext";

//Initial State
const initialState = {
    name : '',          errorName : false,
    description:'',     errorDescription: false,
    street:'',          errorStreet: false,
    city:'',            errorCity: false,
    estado:'',           errorEstado: false,
    postalCode:'',      errorPostalCode: false,
    status : '',        errorStatus : false,
    
    isLoading : false,

    open: false,
    title: '',
    message: '',
    success: false
}

function reducer( state, { field, value })
{
    return{
        ...state,
        [field] : value
    }
}


export default function LocationsAdd()
{
    const {userData} = useContext(UserContext)
    const [state,setState] = useReducer(reducer,initialState);
    const [date,setDate] = useState(new Date());

    const {
        name, errorName, description, errorDescription,
        street, errorStreet, city, errorCity, estado, errorEstado,
        postalCode, errorPostalCode,
        status, errorStatus,
        isLoading,
        open, title, message, success

    } = state;

    const history = useHistory();

    const onChange = (e) => {
        setState({field : e.target.name, value : e.target.value});
    }


    const formVerification = () => {
        let ban = 0;

        if(name === '')
        {
            setState({field:'errorName',value:true});
            ban = 1;
        }

        if(description === '')
        {
            setState({field:'errorDescription',value:true});
            ban = 1;
        }

        if(street === '')
        {
            setState({field:'errorStreet',value:true});
            ban = 1;
        }

        if(city === '')
        {
            setState({field:'errorCity',value:true});
            ban = 1;
        }

        if(estado === '')
        {
            setState({field:'errorEstado',value:true});
            ban = 1;
        }
        
        if(postalCode === '')
        {
            setState({field:'errorPostalCode',value:true});
            ban = 1;
        }

        return ban;
    }


    const handleSubmit = async () => {
        //console.log('formVerification === ',formVerification())
        if(formVerification() === 0)
        {
            setState({field:'isLoading',value:true});
            let response = await Add(name,description,street,city,estado,postalCode,1,userData.user.commerceid);
            if(response.success === true)
            {
                setState({field:'title',value:'Success!'});
                setState({field:'message',value:'Location added successfully'});
                setState({field:'success',value:true});
                setState({field:'open',value:true});
            }
            else
            {
                setState({field:'title',value:'Error!'});
                setState({field:'message',value:'Could not add location at this time. try again later.'});
                setState({field:'success',value:false});
                setState({field:'open',value:true});
            }
            setState({field:'isLoading',value:false});
        }
    }

    return(
        <Container>
            <CustomBreadcrumbs
                returnTo='/locations'
                crumbs={[
                    {name:'Locations',link:'/locations'},
                    {name:'Add',link:'/locations/add'}
                ]}
            />
            <SectionTitle title='Add Location'/>

            <Grid container spacing={3} justifyContent='center'>
                
                <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Grid container spacing={3}>
                        

                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <Typography variant="h6">Location info</Typography>
                        </Grid>

                        {/** Name */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                label='Name'
                                name='name'
                                id='name'
                                required
                                value={name}
                                onChange = { onChange }
                                onBlur={ () => (name === '' ? setState({field:'errorName',value:true}) : setState({field:'errorName',value:false}) ) }
                                error = {errorName}
                                helperText={errorName && 'Enter the name of Vendor'}
                            />
                        </Grid>

                        {/** Description */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                label='Description'
                                name='description'
                                id='description'
                                required
                                value={description}
                                onChange = { onChange }
                                onBlur={ () => (description === '' ? setState({field:'errorDescription',value:true}) : setState({field:'errorDescription',value:false}) ) }
                                error = {errorDescription}
                                helperText={errorDescription && 'Enter the description of Location'}
                            />
                        </Grid>

                        
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <Typography variant="h6">Address</Typography>
                        </Grid>

                        {/** Street */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                label='Street'
                                name='street'
                                id='street'
                                required
                                value={street}
                                onChange = { onChange }
                                onBlur={ () => (street === '' ? setState({field:'errorStreet',value:true}) : setState({field:'errorStreet',value:false}) ) }
                                error = {errorStreet}
                                helperText={errorStreet && 'Enter the street of Location'}
                            />
                        </Grid>

                        {/** Street */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                label='City'
                                name='city'
                                id='city'
                                required
                                value={city}
                                onChange = { onChange }
                                onBlur={ () => (city === '' ? setState({field:'errorCity',value:true}) : setState({field:'errorCity',value:false}) ) }
                                error = {errorCity}
                                helperText={errorCity && 'Enter the city of Location'}
                            />
                        </Grid>

                        {/** Street */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                label='State'
                                name='estado'
                                id='estado'
                                required
                                value={estado}
                                onChange = { onChange }
                                onBlur={ () => (estado === '' ? setState({field:'errorEstado',value:true}) : setState({field:'errorEstado',value:false}) ) }
                                error = {errorEstado}
                                helperText={errorEstado && 'Enter the street of Location'}
                            />
                        </Grid>

                        {/**Postal code */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                label='Postal code'
                                name='postalCode'
                                id='postalCode'
                                required
                                value={postalCode}
                                onChange = { onChange }
                                onBlur={ () => (postalCode === '' ? setState({field:'errorPostalCode',value:true}) : setState({field:'errorPostalCode',value:false}) ) }
                                error = {errorPostalCode}
                                helperText={errorPostalCode && 'Enter the postal code of Vendor'}
                            />
                        </Grid>

                        

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <LoadingButton
                                loading={isLoading}
                                fullWidth
                                variant="contained"
                                onClick={()=>handleSubmit()}
                            >
                                <Typography>
                                    Add Location
                                </Typography>
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            
            <ModalDialog
                open={open}
                title={title}
                msg={message}
                action={success === true ? 'Close' : 'Try again'}
                handleAction={()=> success === true ? history.push('/locations') : setState({field:'open',value:false}) }
            />
        </Container>
    )
}