import { useState } from "react";
import { Chip, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faPlay, faStop, faTrash } from "@fortawesome/free-solid-svg-icons";
import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";
import { ChangeStatus, GetAll } from "../../../controllers/admin/UsersController";
import { PatternFormat } from "react-number-format";

export default function UsersDataTable(props)
{
    const [users,setUsers] = useState(props.data);

    const handleGetData = async () => {
        let response = await GetAll();

        if(response.success === true)
        {
            setUsers(response.data.users);
        }
    }

    const columns = [
        {
            name : 'name',
            label : 'Name',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'last_name',
            label : 'Last Name',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        /*{
            name : 'birthdate',
            label : 'Birthdate',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'sex',
            label : 'Sex',
            options : 
            {
                filter:true,
                searchable:true
            }
        },*/
        {
            name : 'phone_number',
            label : 'Phone Number',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <PatternFormat format="(###) ####-###" displayType="text"  value={users[dataIndex].phone_number} />
                    );
                },
                filter:false,
                searchable:true
            }
        },
        {
            name : 'email',
            label : 'Email',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        /*{
            name : 'postal_code',
            label : 'Zip code',
            options : 
            {
                filter:false,
                searchable:true
            }
        },*/
        {
            name : 'status',
            label : 'status',
            options : 
            {
                customBodyRender : (value, tableMeta, updateValue) => (
                    value === 1
                    ? <Chip label='active' color='success'/>
                    : <Chip label='inactive' color='error' />
                )            
            }
        },
        {
            name : '',
            label : 'Actions',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                            <Link
                                //to='/cashiers' 
                                onClick = { () => handleChangeStatus(users[dataIndex].id)  }  
                            >
                                <IconButton>
                                {
                                    users[dataIndex].status === 1
                                    ? <FontAwesomeIcon icon={faStop}/>
                                    : <FontAwesomeIcon icon={faPlay}/>
                                }
                                </IconButton>
                            </Link>

                            
                            <Link
                                to='/users/update' 
                                onClick={ ()=> localStorage.setItem('SNOWIE_USER',JSON.stringify(users[dataIndex])) }
                                replace
                            >
                                <IconButton>
                                    <FontAwesomeIcon icon={faEdit}/>
                                </IconButton>
                            </Link>
                        </div>
                    );
                },

                filter:false,
                print:false
            }
        }
    ];

    const handleChangeStatus = async (id) => {
        let response = await ChangeStatus(id);
        if(response.success === true)
        {
            handleGetData();
        }
    }

    

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
    };

    return(
        <MUIDataTable
            title='Users List'
            data={users}
            columns={columns}
            options={options}
        />
    )
}