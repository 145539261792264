import { useContext, useEffect, useState } from 'react';import { Container, Box, Grid, Typography } from '@mui/material';
import SectionName from '../../../components/SectionName';
import QRCode from "react-qr-code";
import UserContext from '../../../navigation/context/userContext';
import { show_total_amount } from '../../../controllers/customer/ProvidersController';
import Loader from '../../../components/Loader';
import Lottie from "lottie-react";

import no_transactions from '../../../assets/lottie/no_transactions.json';
import ProviderCard from './ProviderCard';



export default function Rewards()
{
    const { userData } = useContext(UserContext);
    const [balances,setBalances] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    useEffect(()=>{
        Get_total_balance();
        window.scrollTo(0,0);
    },[])

    const Get_total_balance = async () => {
        setIsLoading(true);
        let _balances = await show_total_amount();
        setBalances(_balances);
        setIsLoading(false);
    }

    return(
        <Box sx={{minHeight:'95vh'}}>
            <SectionName title={'Rewards'}/>
            <Container sx={{mt:17}}>
                <Grid container spacing={3} justifyContent={'center'}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant='h4' fontWeight={'bold'} textAlign={'center'}>Points Earned</Typography>
                        <Typography variant='body1' textAlign={'center'} color={'#757575'}>for Purchases</Typography>
                    </Grid>

                    {
                        (balances.length === 0 && isLoading === false) &&
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                            <Lottie
                                //autoPlay
                                loop={false}
                                style={{
                                    width: 240,
                                    height: 240,
                                }}
                                animationData={no_transactions}
                            />
                            <Typography variant='h6' textAlign={'center'}>You currently do not have reward points</Typography>
                        </Grid>
                    }

                    <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
                        <Grid container spacing={3} >
                        {
                            balances.length !== 0 &&
                            balances.map(item=>
                                <ProviderCard data={item}/>
                            )
                        }
                        </Grid>
                    </Grid>

                </Grid>
            </Container>
            <Loader show={isLoading}/>
        </Box>
    )
}