import { useState, useEffect } from "react";
import { Box, CircularProgress, Container, Grid } from "@mui/material";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import SectionTitle from "../../../components/sectionTitle";
import CardInfo from "../../../components/cardInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faSnowflake, faUsers, faWind } from "@fortawesome/free-solid-svg-icons";
import { GetAll } from "../../../controllers/admin/UsersController";
import UsersDataTable from "./dataTable";
import AddButton from "../../../components/addButton";

export default function Users()
{
    const [users,setUsers] = useState([]);
    const [statistics,setStatistics] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    useEffect(() => {
        handleGetAll();
    },[])

    const handleGetAll = async () => {
        setIsLoading(true);
        let response = await GetAll();
        if(response.success === true)
        {
            setUsers(response.data.users);
            setStatistics(response.data.statistics);
        }
        setIsLoading(false);
    }

    return(
        <Container maxWidth='xl'>
            <CustomBreadcrumbs crumbs={[{name:'Users',link:'/users'}]} />
            <SectionTitle title='Users'/>
            
            {
                isLoading === true &&
                <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                    <CircularProgress color='primary'/>
                </Box>
            }

            <AddButton route='/users/add'/>

            {
                users.length !== 0 &&
                <UsersDataTable data={users}/>
            }

        </Container>
    )
}