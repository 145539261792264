import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { useHistory } from "react-router-dom";

export default function SelectUserType()
{
    const history = useHistory();

    const redirect = (route) => {
        history.push(route);
    }
    return(
        <Container maxWidth='xl'>
            <Box sx={{minHeight:'85vh',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                <Grid container spacing={3} justifyContent='center'>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h6" component='h6' sx={{textAlign:'center'}}>
                            Selecciona el tipo de usuario que deseas registrar
                        </Typography>
                    </Grid>
                    <Grid item lg={2} sx={{display:'flex',justifyContent:'center'}}>
                        <Button onClick={()=>redirect('/auth/personSignUp')}>
                            <Paper elevation={6}>
                                <Box component='div'>
                                    <PersonAddIcon color="primary" fontSize="large"/>
                                    <Typography>Persona</Typography>
                                </Box>
                            </Paper>
                        </Button>
                    </Grid>
                    <Grid item lg={2} sx={{display:'flex',justifyContent:'center'}}>
                        <Button>
                            <Paper elevation={6}>
                                <Box component='div'>
                                    <AddBusinessIcon color="primary" fontSize="large"/>
                                    <Typography>Empresa</Typography>
                                </Box>
                            </Paper>
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}