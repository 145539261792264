import { useState } from "react";
import { Chip, IconButton, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faPlay, faTrash } from "@fortawesome/free-solid-svg-icons";
import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";
import { PatternFormat } from "react-number-format";

export default function CustomersDataTable(props)
{
    const [customer,setCustomer] = useState(props.data);

    const columns = [
        {
            name : 'name',
            label : 'Name',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'phone_number',
            label : 'Phone Number',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <PatternFormat format="(###) ###-####" displayType="text"  value={customer[dataIndex].phone_number} />
                    );
                },
                //<PatternFormat format="+1 (###) #### ###" allowEmptyFormatting mask="_" />;
                filter:false,
                searchable:true
            }
        },
        {
            name : 'email',
            label : 'Email',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
    ];

    

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
    };

    return(
        <MUIDataTable
            title='customers List'
            data={customer}
            columns={columns}
            options={options}
        />
    )
}