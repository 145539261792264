import React from "react";
import { Box, Button, Dialog, DialogContent, Divider, Grid, IconButton, Slide, Typography, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function History(props)
{
    const theme = useTheme();

    return(
        <Dialog
            fullScreen
            open={props.open}
            onClose={props.onClose}
            TransitionComponent={Transition}
            maxWidth='lg'
        >
            <DialogContent>
                <Grid container spacing={3} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <IconButton sx={{borderRadius:10}} onClick={props.onClose}>
                            <FontAwesomeIcon color={theme.palette.error.main} icon={faClose}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h6" textAlign={'center'}>Transactions History</Typography>
                        <Typography variant="h6" fontWeight={'bold'} textAlign={'center'}>{props.provider}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={8} md={6} lg={10} xl={4}  mt={5}>
                        <Grid container spacing={3}>
                        {
                            props.transactions.length !== 0 &&
                            props.transactions.map(item=>{
                                let color = ''; let symbol = ''; let type = '';
                                if(item.transaction_type === 'add')
                                {
                                    color = theme.palette.success.main;
                                    symbol = '+';
                                    type='Awarded';
                                }
                                else
                                {
                                    color = theme.palette.error.main;
                                    symbol = '-';
                                    type='Redeemed';
                                }

                                return(
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Box sx={{width:'100%'}}>
                                        <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'100%'}}>
                                            <Box>
                                                <Typography variant="h6">{item.branch.name}</Typography>
                                                <Typography variant="subtitle2">{item.created}</Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="h6" color={color} textAlign='right'>{symbol +' '+ item.amount}</Typography>
                                                <Typography variant="subtitle2">{type}</Typography>
                                            </Box>
                                        </Box>
                                        <Divider/>
                                    </Box>
                                    </Grid>
                                )
                            })
                        }
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={8} md={4} lg={4} xl={4} mt={5}>
                        <Button onClick={props.onClose} fullWidth variant="contained" color="primary">Done</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}


/**
 * <List>
                    {
                        transactions.length !== 0 &&
                        transactions.map((transaction,index) => {
                            let color = ''; let symbol = ''; let type = '';
                            if(transaction.transaction_type === 'add')
                            {
                                color = lightTheme.colors.success.main;
                                symbol = '+';
                                type='Awarded';
                            }
                            else
                            {
                                color = lightTheme.colors.error.main;
                                symbol = '-';
                                type='Redeemed';
                            }

                            return(
                                <ListItem key={transaction.id+"-"+index}>
                                    <Left>
                                        <View>
                                            <TextRegular text={transaction.branch.name} fontSize={17}/>
                                            <TextRegular text={transaction.created} fontSize={12}/>
                                        </View>
                                    </Left>
                                    <Body style={{display:'flex',flexDirection:'row'}}>
                                        <TextRegular text={type} />
                                    </Body>
                                    <Right style={{display:'flex',flexDirection:'column',alignItems:'flex-end'}}>
                                        <TextRegular text={symbol +' '+ transaction.amount} fontSize={20} color={color} textAlign='right'/>
                                    </Right>
                                </ListItem>
                            )
                        })
                    }
                </List>
 */