
const server = 'https://api.getbitesrewards.com/';
//const server = 'http://localhost/snowie/';
//const server = 'http://192.168.1.115:8000/';

const api = 'api/';

const imagesURL = server+'api/public/storage/img/';

const url = server + api;
const v1 = 'v1/';

export {url,v1,imagesURL}

