import { Grid, Typography } from "@mui/material";

export default function SectionTitle(props)
{
    return(
        <Grid container spacing={3} sx={{mt:3,mb:7}}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                <Typography variant='h4' component='h4' sx={{textAlign:'center'}}>
                    {props.title}
                </Typography>
                <Typography variant='h6' component='h6' sx={{textAlign:'center'}}>
                    {props.subtitle}
                </Typography>
            </Grid>
        </Grid>
    )
}