import { useState } from "react";
import { Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faPlay, faStop, faTrash } from "@fortawesome/free-solid-svg-icons";
import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";
import { ChangeStatus, GetAll, RemoveCustomer } from "../../../controllers/admin/CustomersController";
import { PatternFormat } from "react-number-format";
import ModalDialog from "../../../components/modalDialog";

export default function CustomersDataTable(props)
{
    const [customer,setCustomer] = useState(props.data);

    const [isLoading,setIsLoading] = useState(false);

    const [open,setOpen] = useState(false);
    const [title,setTitle] = useState('');
    const [message,setMessage] = useState('');
    const [success,setSuccess] = useState(false);
    const [action,setAction] = useState('');
    const [id,setId] = useState('');

    const [selectedCustomer,setSelectedCustomer] = useState(null);
    const [openRemoveModal,setOpenRemoveModal] = useState(false);
    const [removeWord,setRemoveWord] = useState('');
    const [removeWordError,setRemoveWordError] = useState(false);


    const handleGetData = async () => {
        let response = await GetAll();

        if(response.success === true)
        {
            setCustomer(response.data);
        }
    }

    const handleGetCustomerToRemove = (item) => {
        setSelectedCustomer(item);
        setOpenRemoveModal(true);
    }

    const columns = [
        {
            name : 'name',
            label : 'Name',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'phone_number',
            label : 'Phone Number',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <PatternFormat format="(###) ###-####" displayType="text"  value={customer[dataIndex].phone_number} />
                    );
                },
                filter:false,
                searchable:true
            }
        },
        {
            name : 'email',
            label : 'Email',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'status',
            label : 'status',
            options : 
            {
                customBodyRender : (value, tableMeta, updateValue) => (
                    value === 1
                    ? <Chip label='active' color='success'/>
                    : <Chip label='inactive' color='error' />
                )            
            }
        },
        {
            name : '',
            label : 'Actions',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                            <Link
                                //to='/cashiers' 
                                onClick = { () => handleChangeStatus(customer[dataIndex].id)  }  
                            >
                                <IconButton>
                                {
                                    customer[dataIndex].status === 1
                                    ? 
                                    <Tooltip title='Inactivate' placement="top">
                                        <FontAwesomeIcon icon={faStop}/>
                                    </Tooltip>
                                    :
                                    <Tooltip title='Inactivate' placement="top">
                                        <FontAwesomeIcon icon={faPlay}/>
                                    </Tooltip>
                                }
                                </IconButton>
                            </Link>

                            <Link
                                onClick={ ()=> handleGetCustomerToRemove(customer[dataIndex]) }
                                
                            >
                                <Tooltip title='Remove' placement="top">
                                    <IconButton>
                                        <FontAwesomeIcon icon={faTrash}/>
                                    </IconButton>
                                </Tooltip>
                            </Link>
                        </div>
                    );
                },

                filter:false,
                print:false
            }
        }
    ];


    const handleChangeStatus = async (id) => {
        let response = await ChangeStatus(id);
        if(response.success === true)
        {
            handleGetData();
        }
    }

    const handleRemoveCustomer = async () => {
        if(formVerification() === 0)
        {   
            setIsLoading(true);
            let response = await RemoveCustomer(selectedCustomer.id);
            if(response.success === true)
            {
                setOpenRemoveModal(false);
                handleGetData();
            }
            setIsLoading(false);
        }
    }

    const handleClose = () => {
        setSelectedCustomer(null);
        setRemoveWord('');
    }

    const formVerification = () => {
        let ban = 0;
        if(removeWord !== 'remove')
        {
            setRemoveWordError(true);
            ban = 1;
        }

        return ban;
    }

    //const handle

    

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
    };

    return(
        <>
            <MUIDataTable
                title='Customers List'
                data={customer}
                columns={columns}
                options={options}
            />
            <ModalDialog
                open={open}
                title={title}
                msg={message}
                action={action}
                handleAction={()=>console.log('hola')}
            />

            <Dialog open={openRemoveModal} onClose={()=>handleClose()}>
                <DialogTitle>Remove Customer</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Are you sure you want to remove the customer {selectedCustomer && <span style={{fontWeight:'bold'}}>{selectedCustomer.name}</span>}?
                    to confirm type the word 'remove' and then press the remove button
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="removeWord"
                    label="Type 'remove' "
                    fullWidth
                    variant="standard"
                    value={removeWord}
                    onChange={(e)=>setRemoveWord(e.target.value)}
                    onBlur={ () => (removeWord === '' ? setRemoveWordError(true) : setRemoveWordError(false) ) }
                    error = {removeWordError}
                    helperText={removeWordError && 'You must write the word "remove"'}
                />
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>handleClose()} disabled={isLoading}>Cancel</Button>
                    <Button onClick={()=>handleRemoveCustomer()} disabled={isLoading}>
                        {
                            isLoading === true
                            ?
                            <CircularProgress/>
                            :
                            'Remove'
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}