import AdminRoutes from "../../apiRoutes/AdminRoutes";
import { GET, POST } from "../ConsumeApiController";

export async function GetAll()
{
    let response = await GET(AdminRoutes.Transactions.GetAll);
    console.log(response);
    return response;
}


export async function GetCountTransactions(starDate,endDate)
{
    const params = [
        {name:'Fecha_inicio',value:starDate},
        {name:'Fecha_Final',value:endDate},
    ];

    let response = await POST(AdminRoutes.Dashboard.CountTransactions,params);
    return response;
}


export async function GetTop10()
{
    let response = await GET(AdminRoutes.Dashboard.GetTop10);
    return response;
}


export async function GetTransactionsStatitics()
{
    let response = await GET(AdminRoutes.Dashboard.GetTransactionsStatitics);
    return response;
}