import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PersonIcon from '@mui/icons-material/Person';
import ReceiptIcon from '@mui/icons-material/Receipt';
import LockIcon from '@mui/icons-material/Lock';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupsIcon from '@mui/icons-material/Groups';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import AuthContext from '../../navigation/context/authContext';





export default function MainListItems(){

    const { signOut } = useContext(AuthContext);
    let history = useHistory();
    
    const redireccionar = (ruta) => {
        history.replace(ruta);
    }

    const handleSignOut = () => {
        signOut();
        history.push('/');
    }

    return(
        <div>            
            <ListItem button onClick={() => redireccionar('/') }>
                <Tooltip title='Dashboard' placement="right-start">
                    <ListItemIcon>                
                        <DashboardIcon />
                    </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Dashboard" />
            </ListItem>
            

            <ListItem button onClick = { () => redireccionar('/vendor') } >
                <Tooltip title='Vendor' placement="right-start">
                    <ListItemIcon>                
                        <AccountTreeIcon />
                    </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Vendor" />
            </ListItem>

            <ListItem button onClick = { () => redireccionar('/customers') } >
                <Tooltip title='Customers' placement="right-start">
                    <ListItemIcon>                
                        <GroupsIcon />
                    </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Customers" />
            </ListItem>

            

            <ListItem button onClick = { () => redireccionar('/transactions') } >
                <Tooltip title='Transactions' placement="right-start">
                    <ListItemIcon>                
                        <ReceiptIcon />
                    </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Transactions" />
            </ListItem>

            <ListItem button onClick = { () => redireccionar('/users') } >
                <Tooltip title='Users' placement="right-start">
                    <ListItemIcon>                
                        <PersonIcon />
                    </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Users" />
            </ListItem>

            <ListItem button onClick = { () => redireccionar('/password') } >
                <Tooltip title='Password' placement="right-start">
                    <ListItemIcon>                
                        <LockIcon />
                    </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Password" />
            </ListItem>

            <ListItem button onClick={()=>handleSignOut()}>
                <Tooltip title='Logout' placement="right-start">
                    <ListItemIcon>                
                        <ExitToAppIcon />
                    </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Logout" />
            </ListItem>
            
        </div>
    )    
}
    