import { useContext, useEffect } from 'react';import { Container, Box, Grid, Typography } from '@mui/material';
import SectionName from "../../components/SectionName";
import QRCode from "react-qr-code";

import UserContext from '../../navigation/context/userContext';


export default function ClientHome()
{
    const { userData } = useContext(UserContext);

    useEffect(()=>{
        window.scrollTo(0,0);
    },[]);

    return(
        <Box sx={{minHeight:'95vh'}}>
            <SectionName title={'Purchase ID'}/>
            <Container sx={{mt:17,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',minHeight:'60vh'}}>
                <Grid container spacing={3} justifyContent={'center'}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant='h6' textAlign={'center'}>Present this QR code</Typography>
                        <Typography variant='h6' textAlign={'center'}>to the Snowie Employee</Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} md={4} lg={4} xl={4} display={'flex'} justifyContent={'center'} mt={5}>
                        <QRCode
                            style={{ width:'100%'}}
                            value={userData.user.token_user}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}