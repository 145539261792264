import { Container, Grid, Typography } from "@mui/material";
import SectionTitle from "../../../components/sectionTitle";
import moment from "moment";

export default function PrivacyPolicy()
{
    return(
        <Container maxWidth='md' >
            <SectionTitle title='PRIVACY POLICY' subtitle='SNOWIE MARKETING, LLC'/>
            <Grid container spacing={3} paddingBottom={20}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography>Date Last Update: <span style={{fontWeight:'bold'}}>{moment().format('MMMM Do YYYY')}</span></Typography>
                    <Typography>
                        Snowie Marketing, LLC respects all user’s privacy and is committed to protecting it
                        through this Privacy Policy. To make this Privacy Policy (the “Policy”) easier to read,
                        we will refer to Snowie Marketing, LLC (including our directors, officers, members,
                        managers, employees, service providers, affiliates, successors and assignees) as
                        “our”, “we”, or “us”, and we will refer to individuals who use the application (including
                        you) as “you” or “User(s).”
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" sx={styles.subtitle}>Acceptance of Privacy Policy.</Typography>
                    <Typography sx={styles.text}>
                        This Privacy Policy applies to your access to and use of our mobile application (the
                        “Service”) and any content, links functionality, features, products, services, materials or other
                        items (including, but not limited to, all information, data, software, text, displays, images,
                        video, audio, and the design, selection and arrangement thereof) offered or obtained on our
                        through the Service (collectively, “Content”). This Privacy Policy does not apply to
                        information collected on any third-party site or by any third-party application that may link to
                        or be accessible from the Service. By downloading and accessing the Service and its Content,
                        you (“you”, “your” or “yourself”) consent to the data practices described in this Privacy
                        Policy. <br/>
                        Please read this entire Privacy Policy carefully to understand our policies and practices
                        regarding your personal information and how we treat it before you download and start using
                        the Service. If you do not agree with the data practices in this Privacy Policy, you must cease
                        your use and exit the Service.
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" sx={styles.subtitle}>Changes; Modifications to Policy.</Typography>
                    <Typography sx={styles.text}>
                        We reserve the right, in our sole discretion, to make changes or modifications to this
                        Policy at any time and for any reason. We will alert you about any changes by
                        updating the “Last updated” date of this Policy, and you waive any right to receive
                        specific notice of each such change. It is your responsibility to periodically review this
                        Policy to stay informed of updates. You will be subject to, and will be deemed to have
                        been made aware of and to have accepted, the changes in any revised Terms by
                        your continued use of the Service after the date such revised Terms are posted.
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" sx={styles.subtitle}>Scope of Privacy Policy.</Typography>
                    <Typography sx={styles.text}>
                        You understand and acknowledge that this Privacy Policy applies to information collected by
                        the Service and does not apply to information collected by third-party sites, even if a link to a
                        third-party site appears on the Service. We are not responsible for the data practices of
                        third-party sites.
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" sx={styles.subtitle}>Children’s Privacy.</Typography>
                    <Typography sx={styles.text}>
                        If you are under thirteen (13) years of age, you must not provide or send any information
                        about yourself to us or any third party through the Service (including, but not limited to, your
                        name, address, telephone number or email address). You understand and acknowledge that:
                        (i) the Service is not intended for children under thirteen (13) years of age; (ii) in order to
                        comply with the Children’s Online Privacy Protection Act, we cannot knowingly or
                        intentionally collect any personal information about children under thirteen (13) years of age;
                        and (iii) if we obtain actual knowledge that we have collected personal information about a
                        child under thirteen (13) years of age without verification of parental consent, that
                        information will be immediately deleted from the Service and our database. If you believe we
                        might have any information from or about a child under thirteen (13) years of age, please
                        contact us at <span style={{fontWeight:'bold'}}>info@getbitesrewards.com</span>.
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" sx={styles.subtitle}>Information Collected.</Typography>
                    <Typography sx={styles.text}>
                        We may request at times that you voluntarily supply us with personal information. Such
                        personal information includes any information that may be used to identify you including, but
                        not limited to: your first and last name, phone number, date of birth, and email address. As
                        you navigate through the Service, we may also collect statistical data, including, but not
                        limited to: (i) details of your visits to the Service (including, but not limited to, traffic data,
                        location data, logs and other communication data and the resources that you access); and (ii)
                        information about your mobile device and internet connection (including, but not limited to,
                        your IP address, operating system and browser type, for system administration). All personal
                        information and any other information you provide to us through your use of the Service will
                        be stored on our systems until such time as: (i) you request its removal from our systems by
                        notifying us of such request in writing; or (ii) the Service ceases to exist, whichever shall
                        occur first.
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" sx={styles.subtitle}>Use of Information.</Typography>
                    <Typography sx={styles.text}>
                        By entering personal information through the Service, you consent to our use of your
                        personal information for purposes related to the Service, its Content and any other services
                        offered and/or provided on, or in connection with the Service. Without limiting the foregoing,
                        by giving us personal information you are giving us, as well as our third-party service
                        providers, permission to contact you and/or send information about services and offerings.
                        The Service uses “cookies”, which is information that the Service server sends to your device
                        and/or computer when you access the Service. The cookies help us analyze how the Service
                        is used and improve the Service. The use of cookies enables us to: (i) estimate our audience
                        size and usage patterns; (ii) store information about your preferences, allowing us to
                        customize the Service according to your individual interests; (iii) speed up your searches; and
                        (iv) recognize you when you return to the Service. You may refuse the use of cookies by
                        activating the appropriate setting on your device. However, if you select this setting, you may
                        be unable to access certain parts of the Service. Unless you have adjusted your device setting
                        so that it will refuse cookies, our system may issue cookies when you direct your device to
                        the Service.
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" sx={styles.subtitle}>Disclosure of Information.</Typography>
                    <Typography sx={styles.text}>
                        (a) We may disclose personal information that you provide via the Service to the
                        following:
                        (i) Our subsidiaries and affiliates;
                        (ii) The contractors, employees, agents and service providers we use to
                        support our business; and
                        (iii) Any other entity to which we are legally permitted to share your
                        personal information.
                        (b) We may disclose your personal information to third parties under the
                        following circumstances:
                        (i) When we respond to subpoenas, court orders or legal process.
                        (ii) In exercising our legal rights or defending against legal claims.
                        (iii) In investigating, preventing or taking action regarding illegal activities,
                        suspected fraud, situations involving potential threats to the physical safety of any person,
                        violations of our terms and conditions, or as otherwise required or permitted by applicable
                        law.
                        (iv) When we are a party to a merger, divestiture, restructuring,
                        reorganization, dissolution or any other form of sale of some or all of the Company’s assets,
                        whether as a going concern or as part of bankruptcy, liquidation or similar proceeding, in
                        which personal information held by the Company about its participants is among the assets
                        transferred.
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" sx={styles.subtitle}>Disclosure of Aggregated, Non-Personal Information.</Typography>
                    <Typography sx={styles.text}>
                        We may share collected aggregated and non-personal information with third parties to
                        conduct research, enhance our services, or for any other legally permissible use.
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" sx={styles.subtitle}>User Contributions.</Typography>
                    <Typography sx={styles.text}>
                        You recognize and acknowledge that we cannot control the use of information that you
                        provide in public fora, including, but not limited to, profile pages, product reviews, chat
                        rooms and message boards. You must not hold us responsible if information you have
                        revealed in a public forum is used in a way which violates this Privacy Policy or applicable
                        law.
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" sx={styles.subtitle}>Accessing and Correcting Your Personal Information.</Typography>
                    <Typography sx={styles.text}>
                        You may send us an email at <span style={{fontWeight:'bold'}}>info@getbitesrewards.com</span> to request access to, correct or delete
                        any personal information that you have provided to us.
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" sx={styles.subtitle}>Information Protection.</Typography>
                    <Typography sx={styles.text}>
                        We take seriously the confidentiality, security and integrity of the personal
                        information collected from you through the Service. We employ standard security measures
                        designed to protect the security of all information submitted through the Service.
                        Unfortunately, perfect security does not exist. As a result, we cannot ensure or warrant the
                        security of any information transmitted to us through or in connection with the Service that is
                        stored in our systems.
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" sx={styles.subtitle}>Third Party Links.</Typography>
                    <Typography sx={styles.text}>
                        The Service may contain links to other sites. You must not hold us responsible for the content
                        on other sites or for the privacy policies or any other policies on those sites. This Privacy
                        Policy applies solely to information collected by the Service.
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" sx={styles.subtitle}>Visitors from Outside of the United States.</Typography>
                    <Typography sx={styles.text}>
                        The Company is headquartered in the United States. Information collected on the Service is
                        subject to the laws of the United States, which may not be equivalent to the laws in your
                        country.
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" sx={styles.subtitle}>Questions/Concerns Contact.</Typography>
                    <Typography sx={styles.text}>
                        If you have any questions, concerns or complaints regarding our Terms and Conditions or this
                        Privacy Policy, feel free to contact us at <span style={{fontWeight:'bold'}}>info@getbitesrewards.com</span>.
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    )
}

const styles = {
    subtitle:
    {
        fontWeight:'bold',
        textDecorationLine:'underline'
    },
    text:
    {
        textAlign:'justify',
    }
}