import { useContext, useEffect, useState } from "react";
import { Container, Grid, Typography } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCashRegister, faChartLine, faHouseUser, faMapPin, faUsers } from "@fortawesome/free-solid-svg-icons";

import AddButton from "../../../components/addButton";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import CardInfo from "../../../components/cardInfo";
import SectionTitle from "../../../components/sectionTitle";
import { GetAll } from "../../../controllers/vendor/CashiersController";
import UserContext from "../../../navigation/context/userContext";
import CashiersDataTable from "./dataTable";

export default function Cashiers()
{
    const {userData} = useContext(UserContext)
    const [statistics,setStatistics] = useState([]);
    const [cashiers,setCashiers] = useState([]);

    useEffect(()=>{
        handleGetData();
    },[]);

    const handleGetData = async () => {
        let response = await GetAll(userData.user.commerceid);
        if(response.success === true)
        {
            setCashiers(response.data.cashiers);
        }
    }

    return(
        <Container maxWidth='xl'>
            <CustomBreadcrumbs crumbs={[{name:'Employees',link:'/cashiers'}]} />
            <SectionTitle title='Employees'/>

            <AddButton route='/cashiers/add'/>
            
            {
                cashiers.length !== 0 &&
                <CashiersDataTable data={cashiers}/>
            }
        </Container>
    )
}