import { useState, useEffect } from "react";
import { faHouseUser, faMapPin, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress, Container, Grid } from "@mui/material";
import AddButton from "../../../components/addButton";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import CardInfo from "../../../components/cardInfo";
import SectionTitle from "../../../components/sectionTitle";
import { GetAll } from "../../../controllers/admin/CustomersController";
import CustomersDataTable from "./dataTable";


export default function Customers()
{
    const [customers,setCustomers] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    useEffect(()=>{
        handleGetAll();
    },[]);

    const handleGetAll = async () => {
        setIsLoading(true);
        let response = await GetAll();
        if(response.success === true)
        {
            setCustomers(response.data)
        }
        setIsLoading(false);
    }

    return(
        <Container maxWidth='xl'>
            <CustomBreadcrumbs crumbs={[{name:'Customers',link:'/customers'}]} />
            <SectionTitle title='Customers'/>
            {/**Cards - Info */}
            <Grid container spacing={3} mb={5} justifyContent='center'>
                <CardInfo
                    bgcolor='primary.main'
                    icon={<FontAwesomeIcon icon={faUsers}/>}
                    title='total'
                    value={customers.length}
                />
            </Grid>
            {
                isLoading === true &&
                <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                    <CircularProgress color='primary'/>
                </Box>
            }

            {
                customers.length !== 0 &&
                <CustomersDataTable data={customers}/>
            }

        </Container>
    )
}