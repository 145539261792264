import VendorRoutes from "../../apiRoutes/VendorRoutes";
import { GET, POST } from "../ConsumeApiController";


export async function GetAll(vendor_id)
{
    let response = await GET(VendorRoutes.Cashiers.GetAll+vendor_id);
    return response;
}


export async function Add(branchid,name,last_name,phone_number,birthdate,sex,postal_code,email,type_user)
{
    const params = [
        {name:'branchid',value:branchid},
        {name:'name',value:name},
        {name:'last_name',value:last_name},
        {name:'phone_number',value:phone_number},
        {name:'birthdate',value:birthdate},
        {name:'sex',value:sex},
        {name:'postal_code',value:postal_code},
        {name:'email',value:email},
        {name:'type_user',value:type_user}
    ];
    let response = await POST(VendorRoutes.Cashiers.Add,params);
    return response;
}


export async function Update(id,branchid,name,last_name,phone_number,birthdate,sex,postal_code,email,type_user,commerceid)
{
    const params = [
        {name:'id',value:id},
        {name:'branchid',value:branchid},
        {name:'name',value:name},
        {name:'last_name',value:last_name},
        {name:'phone_number',value:phone_number},
        {name:'birthdate',value:birthdate},
        {name:'sex',value:sex},
        {name:'postal_code',value:postal_code},
        {name:'email',value:email},
        {name:'type_user',value:type_user},
        {name:'commerceid',value:commerceid}
    ];

    let response = await POST(VendorRoutes.Cashiers.Update,params);
    return response;
}

export async function ChangeStatus(id)
{
    let response = await GET(VendorRoutes.Cashiers.ChangeStatus+id);
    return response;
}

export async function ResendEmail(email)
{
    let response = await GET(VendorRoutes.Cashiers.ResendEmail+email);
    return response;
}