import { Container, Box, Grid, Typography } from '@mui/material';
import SectionTitle from "../../../components/sectionTitle";

//images
import tdtua1 from '../../../assets/images/website/tdtua1.jpg';
import tdtua2 from '../../../assets/images/website/tdtua2.jpg';
import tdtua3 from '../../../assets/images/website/tdtua3.jpg';

export default function ToDeleteAnAccount()
{
    return(
        <Container>
            <SectionTitle title='HOW TO DELETE YOUR USER ACCOUNT'/>

            <Grid container spacing={3} paddingBottom={20}>
                <Typography variant='h6'></Typography>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant='h6'>Please note that all data associated with your user account will be deleted immediately, this includes reward points earned or redeemed and your personal data (name, email, phone)</Typography>
                
                    <Typography variant="h6">Follow the steps below if you want to delete your user account:</Typography>
                    <Typography variant='h6'>
                        Remember that this action is irreversible.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" sx={styles.subtitle}>Step 1.</Typography>
                    <Typography variant='h6'>
                        You must log in with your user account in the GetBites Rewards mobile application.
                        <br/>
                        To do this you must fill out the login form with your email and password, then you must press the login button.
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
                            <img style={{width:'80%'}} src={tdtua1} alt='snowie'/>
                        </Grid>
                    </Grid>
                    
                </Grid>
                

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" sx={styles.subtitle}>Step 2.</Typography>
                    <Typography variant='h6'>
                        Once you have logged in, you must navigate to your user's profile by pressing the "Profile" 
                        option in the Bottom navigation. 
                        Then you must select the option that says "Delete account" (the text of this option is red).
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
                            <img style={{width:'80%'}} src={tdtua2} alt='snowie'/>
                        </Grid>
                    </Grid>
                    
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" sx={styles.subtitle}>Step 3.</Typography>
                    <Typography variant='h6' >
                        After pressing the "Delete Account" button, a dialog box will open warning you that you will lose your 
                        GetBites Rewards user profile permanently.
                        so to delete your user account you must confirm that you want to delete your user account by selecting the "DELETE ACCOUNT" option.
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
                            <img style={{width:'80%'}} src={tdtua3} alt='snowie'/>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </Grid>
        </Container>
    )
}

const styles = {
    subtitle:
    {
        fontWeight:'bold',
        textDecorationLine:'underline'
    },
    text:
    {
        textAlign:'justify',
    }
}