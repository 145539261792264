import { useContext, useState } from "react";
import { Chip, IconButton, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faPlay, faStop, faTrash } from "@fortawesome/free-solid-svg-icons";
import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";
import UserContext from "../../../navigation/context/userContext";
import { GetAll, ResendEmail } from "../../../controllers/vendor/CashiersController";
import { ChangeStatus } from "../../../controllers/vendor/CashiersController";
import { PatternFormat } from "react-number-format";
import ForwardEmailToCashier from "./resendEmail";

export default function CashiersDataTable(props)
{
    const {userData} = useContext(UserContext);
    const [cashiers,setCashiers] = useState(props.data);
   

    const handleGetData = async () => {
        let response = await GetAll(userData.user.commerceid);
        if(response.success === true)
        {
            console.log(response)
            setCashiers(response.data.cashiers);
        }
    }

    const handleChangeStatus = async (id) => {
        let response = await ChangeStatus(id);
        if(response.success === true)
        {
            handleGetData();
        }
    }

   

    const columns = [
        /*{
            name : 'id',
            label : '#',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'location',
            label : 'Location',
            options : 
            {
                filter:true,
                searchable:true
            }
        },*/
        {
            name : 'last_name',
            label : 'Last name',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'name',
            label : 'First Name',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        
        /*{
            name : 'birthdate',
            label : 'Birthdate',
            options : 
            {
                filter:false,
                searchable:true
            }
        },*/
        {
            name : 'phone_number',
            label : 'Phone Number',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <PatternFormat format="(###) ###-####" displayType="text"  value={cashiers[dataIndex].phone_number} />
                    );
                },
                filter:false,
                searchable:true
            }
        },
        {
            name : 'email',
            label : 'Email',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'status',
            label : 'Status',
            options : 
            {
                customBodyRender : (value, tableMeta, updateValue) => (
                    value === 1
                    ? <Chip label='active' color='success'/>
                    : <Chip label='inactive' color='error' />
                ),
                filter:false,
                searchable:true            
            }
        },
        {
            name : '',
            label : 'Actions',
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                            <Link
                                //to='/cashiers' 
                                onClick = { () => handleChangeStatus(cashiers[dataIndex].id)  }  
                            >
                                <IconButton>
                                {
                                    cashiers[dataIndex].status === 1
                                    ?
                                    <Tooltip title='Inactivate' placement="top">
                                        <FontAwesomeIcon icon={faStop}/>
                                    </Tooltip> 
                                    :
                                    <Tooltip title='Activate' placement="top">
                                        <FontAwesomeIcon icon={faPlay}/>
                                    </Tooltip>
                                    
                                }
                                </IconButton>
                            </Link>

                            
                            <Link
                                to='/cashiers/update' 
                                onClick={ ()=> localStorage.setItem('SNOWIE_CASHIER',JSON.stringify(cashiers[dataIndex])) }
                                replace
                            >
                                <Tooltip title='Edit' placement="top">
                                    <IconButton>
                                        <FontAwesomeIcon icon={faEdit}/>
                                    </IconButton>
                                </Tooltip>
                            </Link>

                            <ForwardEmailToCashier email={cashiers[dataIndex].email}/>
                        </div>
                    );
                },

                filter:false,
                print:false
            }
        }
    ];

    

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
    };

    return(
        <MUIDataTable
            title='Employees List'
            data={cashiers}
            columns={columns}
            options={options}
        />
    )
}