import { useEffect, useState } from "react";
import { Container, Grid, Typography } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCashRegister, faChartLine, faHouseUser, faMapPin, faUsers } from "@fortawesome/free-solid-svg-icons";

import AddButton from "../../../components/addButton";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import CardInfo from "../../../components/cardInfo";
import SectionTitle from "../../../components/sectionTitle";
import VendorDataTable from "./dataTable";
import { GetAll } from "../../../controllers/admin/VendorsController";

export default function Vendor()
{
    const [statistics,setStatistics] = useState([]);
    const [vendors,setVendors] = useState([]);

    useEffect(()=>{
        handleGetData();
    },[]);

    const handleGetData = async () => {
        let response = await GetAll();

        if(response.success === true)
        {
            setStatistics(response.data.statistics);
            setVendors(response.data.vendors);
        }
    }

    return(
        <Container maxWidth='xl'>
            <CustomBreadcrumbs crumbs={[{name:'Vendor',link:'/vendor'}]} />
            <SectionTitle title='Vendor'/>
            
            <Grid container spacing={3}>
                {
                    statistics.length !== 0 &&
                    <>
                        <CardInfo
                            bgcolor='primary.main'
                            icon={<FontAwesomeIcon icon={faUsers}/>}
                            title={statistics[0].type_data}
                            value={statistics[0].quantity}
                        />

                        <CardInfo
                            bgcolor='primary.main'
                            icon={<FontAwesomeIcon icon={faCashRegister}/>}
                            title={statistics[1].type_data}
                            value={statistics[1].quantity}
                        />

                        <CardInfo
                            bgcolor='secondary.main'
                            icon={<FontAwesomeIcon icon={faMapPin}/>}
                            title={statistics[2].type_data}
                            value={statistics[2].quantity}
                        />

                        <CardInfo
                            bgcolor='accent.main'
                            icon={<FontAwesomeIcon icon={faChartLine}/>}
                            title={statistics[3].type_data}
                            value={statistics[3].quantity}
                        />
                    </>
                }
                
            </Grid>
            

            <AddButton route='/vendor/add'/>
            {
                vendors.length !== 0 &&
                <VendorDataTable data={vendors}/>
            }
            
        </Container>
    )
}