import { Redirect, Route, Switch } from "react-router-dom";
import VendorLayout from "../layouts/dashboardVendor/companyLayout";
import ChangePassword from "../views/auth/changePassword";
import CompanyHome from "../views/vendor";
import Cashiers from "../views/vendor/cashier";
import CashiersAdd from "../views/vendor/cashier/add";
import CashiersUpdate from "../views/vendor/cashier/update";
import Locations from "../views/vendor/locations";
import LocationsAdd from "../views/vendor/locations/add";
import LocationsUpdate from "../views/vendor/locations/update";
import VendorTransactions from "../views/vendor/transactions";

import AppRoute from "./AppRoute";
import VendorCustomers from "../views/vendor/customers";
import Courses from "../views/vendor/courses";
import StoreProfile from "../views/vendor/store/profile";



export default function VendorNavigation()
{
    return(
        <Switch>
            <AppRoute exact path='/' layout={VendorLayout} component={CompanyHome} />

            <AppRoute exact path='/store/profile' layout={VendorLayout} component={StoreProfile} />

            <AppRoute exact path='/locations' layout={VendorLayout} component={Locations} />
            <AppRoute exact path='/locations/add' layout={VendorLayout} component={LocationsAdd} />
            <AppRoute exact path='/locations/update' layout={VendorLayout} component={LocationsUpdate} />

            <AppRoute exact path='/cashiers' layout={VendorLayout} component={Cashiers} />
            <AppRoute exact path='/cashiers/add' layout={VendorLayout} component={CashiersAdd} />
            <AppRoute exact path='/cashiers/update' layout={VendorLayout} component={CashiersUpdate} />

            <AppRoute exact path='/customers' layout={VendorLayout} component={VendorCustomers} />

            <AppRoute exact path='/transactions' layout={VendorLayout} component={VendorTransactions} />

            <AppRoute exact path='/courses' layout={VendorLayout} component={Courses} />

            <AppRoute exact path='/password' layout={VendorLayout} component={ChangePassword} />
            
            {/**Para las rutas no encontradas */}
            <Route path="*">
                <Redirect path="/"/>
            </Route>
        </Switch>
    )
}