import { useContext, useReducer } from "react";
import { Box, Container, Grid, Paper, TextField, Typography, InputAdornment, Checkbox, Link, Button, IconButton } from "@mui/material";
import SectionTitle from "../../components/sectionTitle";


import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Password';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ModalDialog from "../../components/modalDialog";
import { useHistory } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { personSignUp } from "../../controllers/AuthController";
import AuthContext from "../../navigation/context/authContext";
import GoogleAuth from "./GoogleAuth";

import FacebookLogin from 'react-facebook-login';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SocialAuth from "./SocialAuth";

import img_login from '../../assets/images/website/login2.png';
import img_logo from '../../assets/images/logos/logo2.png';
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

//1769837390185381 FB ID

//DECLARAMOS LOS ESTADOS INICIALES
const initialState = {
      
    email : '', errorEmail : false,
    password : '', errorPassword : false,
    
    isLoading : false,
    errorSignIn : false,
    errorSignInMsg : '',

    isOpen : false,
    title:'',
    msg:''
  }
  
  function reducer( state, { field, value })
  {
    return{
        ...state,
        [field] : value
    }
  }
  

export default function SignIn()
{
    const [state,setState] = useReducer(reducer,initialState);
    const {
        email, errorEmail,
        password, errorPassword,

        isLoading,

        isOpen,
        title,
        msg,

    } = state;

    const { signIn } = useContext(AuthContext);

    const onChange = (e) => {
        setState({field : e.target.name, value : e.target.value});
    }

    const history = useHistory();
    const redirect = (route) => {
        history.push(route)
    }


    const formVerification = () => {
        var ban = 0;

        if(email === '' || emailReg.test(email) === false)
        {
            setState({field:'errorEmail',value:true});
            ban = 1;
        }
            
        if(password === '')
        {
            setState({field:'errorPassword',value:true});
            ban = 1;
        }
        
        return ban;
    }

    /**
     * this function checks if all input fields of the form are filled
     * then if the form fields are full, execute the submit
     */
    const handleSubmit = async() => {
        setState({field:'isLoading',value:true});
        if(formVerification() === 0)
        {
            await signIn({email,password});
            setState({field:'isLoading',value:false});
        }
        setState({field:'isLoading',value:false});
    }



    const handleclose = async () => {
        if(title === 'Operacion Exitosa!')
        {
            setState({field:'title',value:''});
            setState({field:'msg',value:''});
            setState({field:'isOpen',value:false});         
        }
        else
        {
            setState({field:'title',value:''});
            setState({field:'msg',value:''});
            setState({field:'isOpen',value:false});
        }
    }

    return(
        <Box
            sx={{
                display:'flex',
                flexDirection:'row',
                alignItems:'center',
                justifyContent:'center',
                minHeight:'95vh',
                pb:5
            }}
        >
            <Box sx={{flex:1}}>
                <Container>
                    <IconButton onClick={()=>redirect('/')}>
                        <FontAwesomeIcon icon={faArrowLeft}/>
                    </IconButton>
                    <Grid container spacing={3} justifyContent={'center'}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                <img
                                    src={img_logo}
                                    alt='GetBites Logo'
                                    style={{width:85,borderRadius:5}}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8} md={9} lg={6} xl={6}>
                            <Box
                                component='form'
                                sx={{
                                    width:'100%',
                                    '& .MuiTextField-root':{mb:3}
                                }}
                                spacing={2}
                                noValidate
                                autoComplete="off"
                            >
                                <Typography variant="h6" textAlign={'center'} mb={3}>Sign in to your account</Typography>
                    
                                <TextField
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    placeholder='Email'
                                    name='email'
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <EmailIcon />
                                        </InputAdornment>
                                        ),
                                    }}
                                    value={email}
                                    onChange = { onChange }
                                    onBlur={ () => (email === '' ? setState({field:'errorEmail',value:true}) : setState({field:'errorEmail',value:false}) ) }
                                    error = {errorEmail}
                                    helperText={errorEmail && 'Email is required'}
                                />

                                <TextField
                                    fullWidth
                                    id="password"
                                    name='password'
                                    label="Password"
                                    placeholder='Password'
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <PasswordIcon />
                                        </InputAdornment>
                                        ),
                                    }}
                                    type="password"
                                    value={password}
                                    onChange = { onChange }
                                    onBlur={ () => (password === '' ? setState({field:'errorPassword',value:true}) : setState({field:'errorPassword',value:false}) ) }
                                    error = {errorPassword}
                                    helperText={errorPassword && 'Password is required'}
                                />

                    
                                <LoadingButton
                                    fullWidth
                                    variant='contained'
                                    loading={isLoading}
                                    onClick={()=>handleSubmit()}
                                >
                                    Sign In
                                </LoadingButton>
                                {/**SOCIALS AUTH */}
                                <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',mt:2,mb:2}}>
                                    {/**separator */}
                                    <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',width:'100%'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={5} sm={5} md={5} lg={5} xl={5} sx={styles.separatorContainer}>
                                                <Box sx={styles.separator}/>
                                            </Grid>
                                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                                <Typography textAlign={'center'}>or</Typography>
                                            </Grid>
                                            <Grid item xs={5} sm={5} md={5} lg={5} xl={5} sx={styles.separatorContainer}>
                                                <Box sx={styles.separator}/>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>


                                <SocialAuth/>
                    
                                <Box sx={{marginTop:10}}>
                                    <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} justifyContent={'center'}>
                                            <Button sx={{textTransform:'none'}} onClick={()=>redirect('/auth/forgotPassword')}>
                                                <Typography variant="body1" textAlign={'center'} fontWeight={'bold'}>
                                                    Did you forget your password?
                                                </Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} justifyContent={'center'}>
                                            <Button sx={{textTransform:'none'}} onClick={()=>redirect('/privacypolicy')}>
                                                <Typography variant='body2'>
                                                    Privacy Policy
                                                </Typography>
                                            </Button>
                                        </Grid>
                                        
                                        {/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Button sx={{textTransform:'none'}} onClick={()=>redirect('/auth/userType')}>
                                                <Typography style={{fontSize:13}}>
                                                    ¿Eres nuevo? Registrate
                                                </Typography>
                                            </Button>
                                        </Grid>*/}
                                    </Grid>
                                </Box> 
                            </Box>  
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box sx={{flex:1,display:{xs:'none',sm:'none',md:'block',lg:'block',xl:'block'}}}>
                <img
                    src={img_login}
                    alt='img_login'
                    style={{width:'100%',height:'100vh',objectFit:'cover'}}
                />
            </Box>
        </Box>
    )
}

const styles = {
    separator:
    {
        border:'solid',
        borderWidth:1,
        justifyContent:'center',
        width:'100%',
        borderColor:'#CBCEC9'
    },
    separatorContainer:
    {
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center'
    }
}


/**
 * <Container maxWidth='sm'>
            <SectionTitle title=''/>
            
            <Paper elevation={6} sx={{pt:5,pb:5}}>
                <Typography variant="h6" style={{marginBottom:30}}>Sign in to your account</Typography>
                <Box
                    component='form'
                    sx={{
                        width:'100%',
                        '& .MuiTextField-root':{mb:3}
                    }}
                    spacing={2}
                    noValidate
                    autoComplete="off"
                >
                    {/**Email 
                    <TextField
                        fullWidth
                        id="email"
                        label="Email"
                        placeholder='Email'
                        name='email'
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                <EmailIcon />
                            </InputAdornment>
                            ),
                        }}
                        value={email}
                        onChange = { onChange }
                        onBlur={ () => (email === '' ? setState({field:'errorEmail',value:true}) : setState({field:'errorEmail',value:false}) ) }
                        error = {errorEmail}
                        helperText={errorEmail && 'Email is required'}
                    />

                    <TextField
                        fullWidth
                        id="password"
                        name='password'
                        label="Password"
                        placeholder='Password'
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                <PasswordIcon />
                            </InputAdornment>
                            ),
                        }}
                        type="password"
                        value={password}
                        onChange = { onChange }
                        onBlur={ () => (password === '' ? setState({field:'errorPassword',value:true}) : setState({field:'errorPassword',value:false}) ) }
                        error = {errorPassword}
                        helperText={errorPassword && 'Password is required'}
                    />



                    
                    <br/>
                    <LoadingButton
                        fullWidth
                        variant='contained'
                        loading={isLoading}
                        onClick={()=>handleSubmit()}
                    >
                        Continue
                    </LoadingButton>

                    {/**SOCIALS AUTH 
                    <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',mt:2,mb:2}}>
                        
                        <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',width:'100%'}}>
                            <Grid container spacing={1}>
                                <Grid item xs={3} sm={4} md={4} lg={4} xl={4} sx={styles.separatorContainer}>
                                    <Box sx={styles.separator}/>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                                    <Typography textAlign={'center'}>or continue with</Typography>
                                </Grid>
                                <Grid item xs={3} sm={4} md={4} lg={4} xl={4} sx={styles.separatorContainer}>
                                    <Box sx={styles.separator}/>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>

                    <SocialAuth/>
                    
                    <Box style={{marginTop:50}}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Button sx={{textTransform:'none'}} onClick={()=>redirect('/auth/forgotPassword')}>
                                    <Typography style={{fontSize:13}}>
                                        Forgot password!
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Button sx={{textTransform:'none'}} onClick={()=>redirect('/privacypolicy')}>
                                    <Typography style={{fontSize:13}}>
                                        Privacy Policy
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Button sx={{textTransform:'none'}} onClick={()=>redirect('/auth/userType')}>
                                    <Typography style={{fontSize:13}}>
                                        ¿Eres nuevo? Registrate
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>  
                </Box>
            </Paper>

            <ModalDialog
                open={isOpen}
                title={title}
                msg={msg}
                handleClose={()=>handleclose()}
                handleAction={()=>handleclose()}
            />
        </Container>
 */