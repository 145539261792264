import React, { useEffect } from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import WebsiteAppBar from "./WebsiteAppBar";
import Footer from "../../views/website/footer";
import { Box } from "@mui/material";


export function AuthLayout(props)
{
    useEffect(()=>{
        window.scrollTo(0,0);
    })



    return(
        <Box sx={{minHeight:'100vh'}}>
            {props.children}
        </Box>
    )
}