import { useContext, useEffect, useState } from "react";
import { Avatar, Box, CircularProgress, Container, Divider, Grid, List, ListItem, ListItemText, ListSubheader, Paper, Tooltip, Typography, useTheme } from "@mui/material";
import SectionTitle from "../../components/sectionTitle";
import { GetCountCustomers } from "../../controllers/admin/CustomersController";
import moment from "moment";
import CardInfo from "../../components/cardInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faInfoCircle, faPieChart, faQrcode, faSnowflake, faTrophy, faUserPlus, faUsers, faWind } from "@fortawesome/free-solid-svg-icons";
import { GetCountTransactions } from "../../controllers/admin/TransactionsController";
import UserContext from "../../navigation/context/userContext";
import { GetTop10, GetTransactionsStatiticsByVendor } from "../../controllers/vendor/TransactionsController";
import UserChart from "../admin/customers/userChart";
import { GetCustomersStatitics } from "../../controllers/vendor/UsersController";
import CustomersChart from "./customers/customersChart";
import CustomTooltip from "../../components/tooltip";


export default function CompanyHome()
{
    const {userData} = useContext(UserContext);

    const theme = useTheme();


    const [countCustomers,setCountCustomers] = useState([]);
    const [countTransactions,setCountTransactions] = useState([]);

    const [transactionsStatitics,setTransactionsStatitics] = useState([]);
    const [points,setPoints] = useState('');
    const [redeemed,setRedeemed] = useState('');
    const [unredeemed,setUnredeemed] = useState('');
    const [scans,setScans] = useState([]);

    const [customers,setCustomers] = useState([]);
    const [newCustomers,setNewCustomers] = useState(0);
    const [activeCustomers,setActiveCustomers] = useState(0);
    const [totalCustomers,setTotalCustomers] = useState(0);
    const [isCustomersLoading,setIsCustomersLoading] = useState(false);

    const [vendor,setVendor] = useState([]);
    const [cashiers,setCashiers] = useState([]);

    useEffect(()=>{
        handleGetCountCustomers();
        handleGetCountTransactions();
        handleGetTop10();
        handleGetTransactionsStatitics();
    },[])

    const handleGetTransactionsStatitics = async () => {
        let response = await GetTransactionsStatiticsByVendor(userData.user.commerceid);
        if(response.success === true)
        {
            setTransactionsStatitics(response.data.transactions)
            setPoints(response.data.points);
            setScans(response.data.scans);
            setRedeemed(response.data.redeemed);
            setUnredeemed(response.data.unredeemed);
        }
    }

    const handleGetCountCustomers = async () => {
        //const firstDay = moment().startOf('month').format('YYYY-MM-DD');
        let firstDay = moment().subtract(45, 'days').calendar();
        firstDay = moment(firstDay).format('YYYY-MM-DD'); 
        const lastDay   = moment().endOf('month').format('YYYY-MM-DD');
        setIsCustomersLoading(true);
        let response = await GetCustomersStatitics(userData.user.commerceid,firstDay,lastDay);
        if(response.success === true)
        {
            setNewCustomers(response.data.newCustomers);
            setActiveCustomers(response.data.activeCustomers);
            setTotalCustomers(response.data.totalCustomers);
        }
        setIsCustomersLoading(false);
    }

    const handleGetCountTransactions = async () => {
        let starDate = new Date('2021-01-01');
        starDate = moment(starDate).format('YYYY-MM-DD')
        console.log('DATE = ',starDate)
        //startDate = startDate()
        let response = await GetCountTransactions(starDate,'2024-01-01',userData.user.commerceid);
        if(response.success === true)
        {
            setCountTransactions(response.data);
        }
    }

    const handleGetTop10 = async () => {
        let response = await GetTop10(userData.user.commerceid);
        if(response.success === true)
        {
            setCustomers(response.data.customers);
            setVendor(response.data.vendor);
            setCashiers(response.data.cashiers);
        }
    }

    return(
        <Container>
            <SectionTitle title='Dashboard'/>

            <Grid container spacing={3} mb={5}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" fontWeight='bold'>Customers</Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper elevation={4} sx={{height:'100%'}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display='flex' flexDirection='row' alignItems='center'>
                                <Avatar sx={{bgcolor:'primary.main'}}>
                                    <FontAwesomeIcon icon={faUsers}/>
                                </Avatar>
                                <Box style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <Typography ml={1} variant="h6">Customers</Typography>
                                </Box>
                            </Grid>
                            {
                                
                                isCustomersLoading === true
                                ?
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                                        <CircularProgress/>
                                    </Box>
                                </Grid>
                                :
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <List sx={{width:'100%'}}>
                                        <ListItem sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                            <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                <Typography ml={1} fontSize={20}>New</Typography>
                                                &nbsp;&nbsp;&nbsp;
                                                <CustomTooltip
                                                    title='Customers that downloaded and registered for the GetBites App and completed at least one GetBite Scan in the last 45 days'
                                                    icon={<FontAwesomeIcon color="#b3b3b3" icon={faInfoCircle}/>}
                                                />
                                            </Box>
                                            <Typography fontSize={20}>{newCustomers}</Typography>
                                        </ListItem>
                                        <ListItem sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                            <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                <Typography ml={1} fontSize={20}>Active</Typography>
                                                &nbsp;&nbsp;&nbsp;
                                                <CustomTooltip
                                                    title='Customers that have completed a GetBite Scan in the last 45 days'
                                                    icon={<FontAwesomeIcon color="#b3b3b3" icon={faInfoCircle}/>}
                                                />
                                            </Box>
                                            <Typography fontSize={20}>{activeCustomers}</Typography>
                                        </ListItem>
                                        <ListItem sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                            <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                <Typography ml={1} fontSize={20}>All</Typography>
                                                &nbsp;&nbsp;&nbsp;
                                                <CustomTooltip
                                                    title='All customers that downloaded and registered for the GetBites App and completed a scan (all new, active and inactive customers)'
                                                    icon={<FontAwesomeIcon color="#b3b3b3" icon={faInfoCircle}/>}
                                                />
                                            </Box>
                                            <Typography fontSize={20}>{totalCustomers}</Typography>
                                        </ListItem>
                                    </List>
                                </Grid>
                            }
                            
                        </Grid>
                    </Paper>
                </Grid>

                {/**Grafica de usuarios */}
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper elevation={4}>
                        <Grid container spacing={3} maxHeight={350}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display='flex' flexDirection='row' alignItems='center'>
                                <Avatar sx={{bgcolor:'#000'}}>
                                    <FontAwesomeIcon icon={faPieChart}/>
                                </Avatar>
                                <Box style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <Typography ml={1} variant="h6">Active/Inactive customer</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <CustomersChart
                                    labels = {['Active','Inactive']}
                                    data = {[activeCustomers,parseFloat(totalCustomers)-parseFloat(activeCustomers)]}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" fontWeight='bold'>Transactions</Typography>
                </Grid>

                {/**Points/Snowflakes */}
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                    <Paper elevation={4} sx={{height:'100%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item lg={2}>
                                <Avatar sx={{ width: 70, height: 70, bgcolor:'primary.main' }}>
                                    <FontAwesomeIcon size='2x' icon={faSnowflake}/>
                                </Avatar>
                            </Grid>
                            <Grid item lg={10}>
                                <Grid container spacing={3} >
                                    <Grid item lg={12} xl={12}>
                                        <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                            <Typography ml={1} fontSize={20}>Points/Bites</Typography>
                                            &nbsp;&nbsp;&nbsp;
                                            <CustomTooltip
                                                title={'Traditionally known as punches on a punch card, these are every punch/point that a customer receives for each Bite they purchase. For example, if they buy three Bites they can receive three points/snowflakes.  When they qualify for a free Bite (have 6 points) and they receive the free Bite, they lose or rather redeem the 6 points in for the Free Bite.'}
                                                icon={<FontAwesomeIcon color="#b3b3b3" icon={faInfoCircle}/>}
                                            />
                                            
                                        </Box>
                                        <Typography ml={1} fontSize={20}>{points}</Typography>
                                    </Grid>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                {/**Scans */}
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper elevation={4}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
                                <Avatar sx={{ width: 70, height: 70, bgcolor:'#000' }}>
                                    <FontAwesomeIcon size='2x' icon={faQrcode}/>
                                </Avatar>
                            </Grid>
                            <Grid item xs={8} sm={8} md={10} lg={10} xl={10}>
                                <Grid container spacing={3} >
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                            <Typography ml={1} fontSize={20}>Scans</Typography>
                                            &nbsp;&nbsp;&nbsp;
                                            <CustomTooltip
                                                title='These are everytime you use your scanner in the GetBites Rewards app. This could be for giving points or giving free bites (removing 6 points). Each scan can be a different amount of Points or Rewards. For example, 1 scan can be for five points (if a customer bought 5 Bites). Likewise, 1 scan could be for multiple rewards.'
                                                icon={<FontAwesomeIcon color="#b3b3b3" icon={faInfoCircle}/>}
                                            />
                                        </Box>
                                    </Grid>
                                    {
                                        scans.length !== 0 &&
                                        scans.map((item)=>
                                            <Grid item lg={6} xl={6} sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                <Typography ml={1} mt={-3} fontSize={15}>{item.type}:</Typography>
                                                <Typography ml={1} mt={-3} fontSize={20}>{item.scans}</Typography>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>


                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper elevation={4}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item lg={2}>
                                <Avatar sx={{ width: 70, height: 70, bgcolor:'success.main' }}>
                                    <FontAwesomeIcon size='2x' icon={faTrophy}/>
                                </Avatar>
                            </Grid>
                            <Grid item lg={10}>
                                <Grid container spacing={3} >
                                    <Grid item lg={12} xl={12}>
                                        <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                            <Typography ml={1} fontSize={20}>Redeemed Rewards</Typography>
                                            &nbsp;&nbsp;&nbsp;
                                            <CustomTooltip
                                                title='These are the number of rewards that all your customers have redeemed / received free bites.'
                                                icon={<FontAwesomeIcon color="#b3b3b3" icon={faInfoCircle}/>}
                                            />
                                        </Box>
                                        <Typography ml={1} fontSize={20}>{redeemed}</Typography>
                                    </Grid>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>


                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper elevation={4}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item lg={2}>
                                <Avatar sx={{ width: 70, height: 70, bgcolor:'secondary.main' }}>
                                    <FontAwesomeIcon size='2x' icon={faWind}/>
                                </Avatar>
                            </Grid>
                            <Grid item lg={10}>
                                <Grid container spacing={3} >
                                    <Grid item lg={12} xl={12}>
                                        <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                            <Typography ml={1} fontSize={20}>Unredeemed Rewards</Typography>
                                            &nbsp;&nbsp;&nbsp;
                                            <CustomTooltip
                                                title='These are the number of rewards that all your customers have saved in their GetBites Rewards App and still can redeem for a free bite. (1 Unredeemed Reward = 6 saved points)'
                                                icon={<FontAwesomeIcon color="#b3b3b3" icon={faInfoCircle}/>}
                                            />
                                        </Box>
                                        <Typography ml={1} fontSize={20}>{unredeemed}</Typography>
                                    </Grid>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>



            <Grid container spacing={3} mb={5}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" fontWeight='bold'>Top 10</Typography>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper elevation={4}>
                        <Typography variant='h6' fontWeight='bold' textAlign='center' mb={2}>Customers</Typography>
                        <List
                            subheader={
                                <ListSubheader sx={{display:'flex',justifyContent:'space-between'}} component="div" id="nested-list-subheader">
                                    <Typography fontSize={20}>Customers</Typography>
                                    <Typography fontSize={20}>transactions</Typography>
                                </ListSubheader>
                            }
                        >
                            <Divider/>
                            {
                                customers.length !== 0 &&
                                customers.map(item=>
                                    <ListItem>
                                        <ListItemText
                                            primary={<Box sx={{display:'flex',justifyContent:'space-between'}}>
                                                <Typography fontSize={20}>{item.customer}</Typography>
                                                <Typography fontSize={20}>{item.nTransactions}</Typography>
                                            </Box>}
                                        />
                                    </ListItem>
                                )
                            }
                        </List>
                    </Paper>
                </Grid>

                


                {/**Cashiers top 10 */}
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper elevation={4}>
                        <Typography variant='h6' fontWeight='bold' textAlign='center' mb={2}>Cashiers</Typography>
                        <List
                            subheader={
                                <ListSubheader sx={{display:'flex',justifyContent:'space-between'}} component="div" id="nested-list-subheader">
                                    <Typography fontSize={20}>Cashier</Typography>
                                    <Typography fontSize={20}>transactions</Typography>
                                </ListSubheader>
                            }
                        >
                            <Divider/>
                            {
                                cashiers.length !== 0 &&
                                cashiers.map(item=>
                                    <ListItem>
                                        <ListItemText
                                            primary={<Box sx={{display:'flex',justifyContent:'space-between'}}>
                                                <Typography fontSize={20}>{item.cashier}</Typography>
                                                <Typography fontSize={20}>{item.nTransactions}</Typography>
                                            </Box>}
                                        />
                                    </ListItem>
                                )
                            }
                        </List>
                    </Paper>
                </Grid>

            </Grid>
        </Container>
    )
}










