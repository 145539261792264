import AdminRoutes from "../../apiRoutes/AdminRoutes";
import { GET, POST } from "../ConsumeApiController";

export async function GetAll()
{
    let response = await GET(AdminRoutes.Vendors.GetAll);
    return response;
}



export async function Search(userID)
{
    let response = await GET(AdminRoutes.Vendors.Search+userID);
    return response;
}


export async function Create(name,firstName,lastName,birthdate,sex,postalCode,phoneNumber,email)
{
    const params = [
        {name:'name',value:name},
        {name:'amount_sum',value:1},
        {name:'amount_subtraction',value:6},
        
        {name:'first_name',value:firstName},
        {name:'last_name',value:lastName},
        {name:'birthdate',value:birthdate},
        {name:'sex',value:sex},
        {name:'postal_code',value:postalCode},
        {name:'phone_number',value:phoneNumber},
        {name:'email',value:email}
    ];

    let response = await POST(AdminRoutes.Vendors.Create,params);
    return response;
}

export async function Update(id,name,firstName,lastName,birthdate,sex,postalCode,phoneNumber,email)
{
    const params = [
        {name:'id',value:id},
        {name:'name',value:name},
        {name:'amount_sum',value:1},
        {name:'amount_subtraction',value:6},
        {name:'first_name',value:firstName},
        {name:'last_name',value:lastName},
        {name:'birthdate',value:birthdate},
        {name:'sex',value:sex},
        {name:'postal_code',value:postalCode},
        {name:'phone_number',value:phoneNumber},
        {name:'email',value:email}
    ];

    let response = await POST(AdminRoutes.Vendors.Update+id,params);
    return response;
}


export async function ChangeStatus(id)
{
    let response = await GET(AdminRoutes.Vendors.ChangeStatus+id);
    return response;
}