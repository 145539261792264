import { Box, Button, Container, Divider, Grid, IconButton, Typography } from "@mui/material";

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';

import LanguageIcon from '@mui/icons-material/Language';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

//import sharkboy from '../../assets/images/sharkboy.png'

export default function Footer()
{
    return(
        <Box
            sx={{
                backgroundColor:'primary.main',
                minHeight:{xs:'95vh',sm:'95vh',md:'85vh',lg:'81.5vh',xl:'81.5vh'},
                pt:10,pb:5
            }}
        >
            <Container maxWidth='xl'>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{mb:5}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
                                <Typography variant='h6' component='p' color='primary.contrastText'>
                                It's easy to take advantage of your bites rewards with Get Bite Rewards! Simply download the app, 
                                    create your GetBites account on your phone, and you will get a reward after making six purchases! 
                                    After making six purchases, simply tell a member of your vendor's team when you are going to redeem 
                                    your reward and they will update your account so you can get your reward. 
                                    No more carrying cards around with you!
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Typography variant='h4' component='h2' color='primary.contrastText'>
                                    CONTACT US!
                                </Typography>
                                <Divider sx={{height:3,bgcolor:'secondary.main',width:'45%',mt:0,mb:3}}/>
                                
                                <Typography color='primary.contrastText' variant='h6' component='p' style={{display:'flex',alignItems:'center',marginBottom:20}}>
                                    <LocationOnIcon fontSize="large" /> Cincinnati, Ohio
                                </Typography>

                                <Typography color='primary.contrastText' variant='h6' component='p' sx={{display:{xs:'none',sm:'none',md:'flex',lg:'flex',xl:'flex'},alignItems:'center'}}>
                                    <EmailIcon fontSize="large" />&nbsp;info@getbitesrewards.com
                                </Typography>
                                <Typography color='primary.contrastText' variant='h6' component='p' sx={{display:{xs:'flex',sm:'none',md:'none',lg:'none',xl:'none'},alignItems:'center',fontSize:18}}>
                                    <EmailIcon fontSize="large" />&nbsp;info@getbitesrewards.com
                                </Typography>
                                <br/><br/>
                                <Typography variant='h4' component='h2' color='primary.contrastText'>
                                    FOLLOW US!
                                </Typography>
                                <Divider sx={{height:3,bgcolor:'secondary.main',width:'35%',mt:0,mb:3}}/>
                                <div style={{display:'flex',flexDirection:'row'}}>
                                    <IconButton sx={{color:'primary.contrastText'}} href='https://www.snowiecincinnati.com/' target='_blank'>
                                        <LanguageIcon fontSize="large" />
                                    </IconButton>
                                    <IconButton sx={{color:'primary.contrastText'}} href='https://www.instagram.com/snowiecincinnati/?hl=en' target='_blank'>
                                        <InstagramIcon fontSize="large" />
                                    </IconButton>
                                    <IconButton sx={{color:'primary.contrastText'}} href='https://www.facebook.com/SnowieCincinnati/' target='_blank' style={{marginLeft:10,marginRight:10}}>
                                        <FacebookIcon fontSize="large" />
                                    </IconButton>
                                    {/**
                                    <IconButton sx={{color:'primary.contrastText'}} href='https://twitter.com/qud_ve' target='_blank'>
                                        <TwitterIcon fontSize="large" />
                                    </IconButton> */}
                                </div>
                            </Grid>

                            {/**img shark boy 
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                                <img style={{width:'70%'}} src={sharkboy} alt='Technological Sharks'/>
                            </Grid>*/}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h6" component='p' color='primary.contrastText'>
                            © 2023 Snowie Marketing LLC. All rigth reserved.
                        </Typography>
                        <Button
                            variant="text"
                            sx={{textTransform:'none'}}
                            href="https://technologicalsharks.com/"
                            target="_blank"
                        >
                            <Typography variant="h6" component='p' color='primary.contrastText' style={{fontSize:15}}>
                                Powered by Technological Sharks
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}