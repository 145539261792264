import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography, CssBaseline } from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArchiveIcon from '@mui/icons-material/Archive';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCookieBite, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { faSnowflake, faUser } from '@fortawesome/free-regular-svg-icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';



export default function ClientBottomNavigation() {
    const [value, setValue] = React.useState(0);
    const ref = React.useRef(null);

    const history = useHistory();

    const redirect = (route) => {
        history.push(route)
    }

    return (
        <Box sx={{ pb: 7 }} ref={ref}>
            <CssBaseline />
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={2}>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                >
                    <BottomNavigationAction
                        label={<Typography variant='caption' fontWeight={'bold'}>Rewards</Typography>}  
                        icon={<FontAwesomeIcon size='2x' icon={faCookieBite}/>}
                        onClick={()=>redirect('/rewards')}
                    />

                    <BottomNavigationAction
                        label={<Typography variant='caption' fontWeight={'bold'}>Purchase ID</Typography>}  
                        icon={<FontAwesomeIcon size='2x' icon={faQrcode}/>}
                        onClick={()=>redirect('/')}
                    />
                    
                    <BottomNavigationAction
                        label={<Typography variant='caption' fontWeight={'bold'}>Profile</Typography>}  
                        icon={<FontAwesomeIcon size='2x' icon={faUser}/>}
                        onClick={()=>redirect('/profile')}
                    />
                </BottomNavigation>
            </Paper>
        </Box>
    );
}
