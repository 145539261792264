import { url } from "./apiBase";

const VendorRoutes = {
    Locations:
    {
        GetAll : url + 'v1/branch/kiosk_listing/', //complete with vendor ID
        Add : url + 'v1/branch/create',
        Update: url + 'v1/branch/edit/', //complete with branch ID
        ChangeStatus : url + 'v1/branch/destroy/', //complete with branch ID
    },
    Cashiers:
    {
        GetAll : url + 'v1/cashiers/byVendor/', //complete with vendor ID
        Add : url + 'v1/cashiers/store',
        Update: url + 'v1/users/edit', //complete with user ID
        ChangeStatus : url + 'v1/users/destroy/', //complete with branch ID
        ResendEmail : url + 'v1/cashiers/resendEmail/', //complete with {email}
    },
    Customers:
    {
        GetAll : url + 'v1/customers/listByVendor/',//complete with {vendor}
        GetCustomersStatitics : url + 'v1/dashboard/customersByVendor/', //complete with {vendor}/{initialDate}/{finalDate}
    },
    Transactions:
    {
        GetAll : url + 'v1/transaction/getAllTransactionsByVendor/', //complete with vendor ID
        GetTransactionsStatitics : url + 'v1/dashboard/transactions/statitics/byvendor/', //complete with vendor ID
        GetTop10 : url + 'v1/dashboard/transactions/top10/byvendor/', //completar con el vendor id
    },
    Users:
    {
        GetAllAdmin : url + 'v1/users/type/3',
        Create : url + 'v1/users/create',
        Update : url + 'v1/users/edit',
    },
    Auth:
    {
        ChangePassword : url + 'auth/password/reset',
    },
    Settings:
    {
        Index: url + 'v1/stores/settings',
        Update: url + 'v1/stores/settings',
        UpdateLogo: url + 'v1/stores/settings/logo'
    }
};


export default VendorRoutes;