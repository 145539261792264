import { useContext, useEffect, useReducer, useState } from "react";
import { Container, Grid, MenuItem, TextField, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import SectionTitle from "../../../components/sectionTitle";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { NumberFormatBase, PatternFormat } from "react-number-format";
import { LoadingButton } from "@mui/lab";
import ModalDialog from "../../../components/modalDialog";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { GetAll } from "../../../controllers/vendor/LocationsController";
import UserContext from "../../../navigation/context/userContext";
import { Add, Update } from "../../../controllers/vendor/CashiersController";

//Initial State
const initialState = {
    id:'',
    branchid:'',        errorBranchid: false,
    name : '',          errorName : false,
    lastName:'',        errorLastName: false,
    phoneNumber:'',     errorPhoneNumber: false,
    birthdate:'',       errorBirthdate: false,
    sex:'',             errorSex: false,
    postalCode:'',      errorPostalCode: false,
    email:'',           errorEmail: false,
    userType:'2',       errorUserType: false,
    password:'x1A2y!',  errorPassword: false,
    status : '',        errorStatus : false,
    
    isLoading : false,

    open: false,
    title: '',
    message: '',
    success: false
}

function reducer( state, { field, value })
{
    return{
        ...state,
        [field] : value
    }
}


export default function CashiersUpdate()
{
    const {userData} = useContext(UserContext);

    const [state,setState] = useReducer(reducer,initialState);
    const [date,setDate] = useState(new Date());

    const [locations,setLocations] = useState([]);

    const {
        id,
        branchid, errorBranchid,
        name, errorName, lastName, errorLastName, phoneNumber, errorPhoneNumber,
        birthdate, errorBirthdate, sex, errorSex, postalCode, errorPostalCode,
        email, errorEmail, userType, errorUserType, password, errorPassword,
        status, errorStatus,
        isLoading,
        open, title, message, success

    } = state;

    const history = useHistory();

    useEffect(()=>{
        handleGetBranchs();
        handleGetData();
    },[]);

    const handleGetData = async () => {
        const user = await JSON.parse(localStorage.getItem('SNOWIE_CASHIER'));
        console.log(user);
        setState({field:'id',value:user.id});
        setState({field:'branchid',value:user.branchid});
        setState({field:'name',value:user.name});
        setState({field:'lastName',value:user.last_name});
        setState({field:'phoneNumber',value:user.phone_number});
        setState({field:'birthdate',value:user.birthdate});
        setState({field:'sex',value:user.sex});
        setState({field:'postalCode',value:user.postal_code});
        setState({field:'email',value:user.email});
    }

    const handleGetBranchs = async () => {
        let response = await GetAll(userData.user.commerceid);
        if(response.success === true)
        {
            setLocations(response.data);
        }
    }

    const onChange = (e) => {
        setState({field : e.target.name, value : e.target.value});
    }

    const handleDateChange = (newValue) => {
        setDate(newValue);
        setState({field:'birthdate',value: moment(newValue).format('YYYY-MM-DD')})
    };

    const formVerification = () => {
        let ban = 0;

        /*if(branchid === '')
        {
            setState({field:'errorBranchId',value:true});
            ban = 1;
        }*/

        if(name === '')
        {
            setState({field:'errorName',value:true});
            ban = 1;
        }

        if(lastName === '')
        {
            setState({field:'errorLastName',value:true});
            ban = 1;
        }

        if(birthdate === '')
        {
            setState({field:'errorBirthdate',value:true});
            ban = 1;console.log('error is here')
        }

        if(sex === '')
        {
            setState({field:'errorSex',value:true});
            ban = 1;
        }

        if(postalCode === '')
        {
            setState({field:'errorPostalCode',value:true});
            ban = 1;
        }

        if(phoneNumber === '')
        {
            setState({field:'errorPhoneNumber',value:true});
            ban = 1;
        }

        if(email === '')
        {
            setState({field:'errorEmail',value:true});
            ban = 1;
        }

        

        return ban;
    }


    const handleSubmit = async () => {
        console.log('formVerification === ',formVerification())
        if(formVerification() === 0)
        {
            setState({field:'isLoading',value:true});
            let response = await Update(id,branchid,name,lastName,phoneNumber,birthdate,sex,postalCode,email,1,userData.user.commerceid);
            if(response.success === true)
            {
                setState({field:'title',value:'Success!'});
                setState({field:'message',value:'Employee updated successfully'});
                setState({field:'success',value:true});
                setState({field:'open',value:true});
            }
            else
            {
                setState({field:'title',value:'Error!'});
                setState({field:'message',value:'Could not update the employee at this time. try again later.'});
                setState({field:'success',value:false});
                setState({field:'open',value:true});
            }
            setState({field:'isLoading',value:false});
        }
    }

    return(
        <Container>
            <CustomBreadcrumbs
                returnTo='/cashiers'
                crumbs={[
                    {name:'Employees',link:'/cashiers'},
                    {name:'Update',link:'/cashiers/update'}
                ]}
            />
            <SectionTitle title='Update Employee'/>

            <Grid container spacing={3} justifyContent='center'>
                
                
                <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Grid container spacing={3}>
                        {/**Location
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                select
                                id="branchid"
                                name='branchid'
                                label="Location"
                                variant="outlined"
                                value = {branchid}
                                onChange = { onChange }
                                onBlur={ () => (branchid === '' ? setState({field:'errorBranchid',value:true}) : setState({field:'errorBranchid',value:false}) ) }
                                error = {errorBranchid}
                                helperText={errorBranchid && 'Select a location'}
                            >
                                <MenuItem value=''>Select a location</MenuItem>
                                {
                                    locations.length !== 0 &&
                                    locations.map((item)=>
                                        <MenuItem value={item.branchid}>{item.name}</MenuItem>
                                    )
                                }
                            </TextField>
                        </Grid> */}

                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <Typography variant="h6">Personal info</Typography>
                        </Grid>

                        {/** Name */}
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                fullWidth
                                label='First Name'
                                name='name'
                                id='name'
                                required
                                value={name}
                                onChange = { onChange }
                                onBlur={ () => (name === '' ? setState({field:'errorName',value:true}) : setState({field:'errorName',value:false}) ) }
                                error = {errorName}
                                helperText={errorName && 'Enter the name of Vendor'}
                            />
                        </Grid>

                        {/** Last name */}
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                fullWidth
                                label='Last Name'
                                name='lastName'
                                id='lastName'
                                required
                                value={lastName}
                                onChange = { onChange }
                                onBlur={ () => (lastName === '' ? setState({field:'errorLastName',value:true}) : setState({field:'errorLastName',value:false}) ) }
                                error = {errorLastName}
                                helperText={errorName && 'Enter the last name of Vendor'}
                            />
                        </Grid>

                        {/** Birthdate 
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <LocalizationProvider dateAdapter={ AdapterMoment }>
                                <MobileDatePicker
                                    label='Date of birth'
                                    inputFormat="MM/DD/YYYY"
                                    value={date}
                                    onChange={handleDateChange}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>*/}

                        {/**Sex 
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                select
                                id="sex"
                                name='sex'
                                label="sex"
                                variant="outlined"
                                value = {sex}
                                onChange = { onChange }
                                onBlur={ () => (sex === '' ? setState({field:'errorSex',value:true}) : setState({field:'errorSex',value:false}) ) }
                                error = {errorSex}
                                helperText={errorSex && 'Select the sex of Vendor'}
                            >
                                <MenuItem value=''>Select the sex</MenuItem>
                                <MenuItem value='F'>Female</MenuItem>
                                <MenuItem value='M'>Male</MenuItem>
                            </TextField>
                        </Grid>*/}

                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <Typography variant="h6">User info</Typography>
                        </Grid>

                        {/**Postal code 
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                label='Postal code'
                                name='postalCode'
                                id='postalCode'
                                required
                                value={postalCode}
                                onChange = { onChange }
                                onBlur={ () => (postalCode === '' ? setState({field:'errorPostalCode',value:true}) : setState({field:'errorPostalCode',value:false}) ) }
                                error = {errorPostalCode}
                                helperText={errorPostalCode && 'Enter the postal code of Vendor'}
                            />
                        </Grid>*/}

                        {/** Phone number */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <PatternFormat
                                fullWidth
                                format="+1 (###) #### ###"
                                label='Phone number'
                                name='phoneNumber'
                                id='phoneNumber'
                                customInput={TextField}
                                value={phoneNumber}
                                onChange={onChange}
                                onBlur={ () => (phoneNumber === '' ? setState({field:'errorPhoneNumber',value:true}) : setState({field:'errorPhoneNumber',value:false}) ) }
                                error = {errorPhoneNumber}
                                helperText={errorPhoneNumber && 'Enter the phone number of Vendor'}
                            />
                        </Grid>

                        {/**Email */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                label='Email'
                                name='email'
                                id='email'
                                required
                                value={email}
                                onChange = { onChange }
                                onBlur={ () => (email === '' ? setState({field:'errorEmail',value:true}) : setState({field:'errorEmail',value:false}) ) }
                                error = {errorEmail}
                                helperText={errorEmail && 'Enter the email of Vendor'}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <LoadingButton
                                loading={isLoading}
                                fullWidth
                                variant="contained"
                                onClick={()=>handleSubmit()}
                            >
                                <Typography>
                                    Update Employee
                                </Typography>
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            
            <ModalDialog
                open={open}
                title={title}
                msg={message}
                action={success === true ? 'Done' : 'Try again'}
                handleAction={()=> success === true ? history.push('/cashiers') : setState({field:'open',value:false}) }
            />
        </Container>
    )
}