import { useContext, useEffect } from 'react';
import { Container, Box, Grid, Typography, Button } from '@mui/material';
import SectionName from '../../../components/SectionName';
import UserContext from '../../../navigation/context/userContext';
import AuthContext from '../../../navigation/context/authContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { motion } from "framer-motion"

import googlePlay from '../../../assets/images/stores/GooglePlay.png';
import appleStore from '../../../assets/images/stores/AppleStore.png';



export default function Profile()
{
    const { userData } = useContext(UserContext);
    const { signOut } = useContext(AuthContext);

    useEffect(()=>{
        window.scrollTo(0,0);
    },[]);

    const history = useHistory();
    const handleSignOut = () => {
        history.replace('/');
        signOut();
    }

    return(
        <Box sx={{minHeight:'95vh'}}>
            <SectionName title={'Profile'}/>
            <Container sx={{mt:17,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',minHeight:'60vh'}}>
                <Grid container spacing={3} justifyContent={'center'}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant='h5' fontWeight={'bold'} textAlign={'center'}>User Profile</Typography>
                    </Grid>
                    
                    {/**PlayStore and AppStore buttons */}
                    
                    <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.7 }}
                    >
                        <Grid container spacing={3} mt={5} justifyContent={'center'} style={{marginLeft:0}}>
                            <Grid item xs={8} sm={8} md={6} lg={6} xl={6} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                <Button fullWidth href='https://play.google.com/store/apps/details?id=com.technologicalsharks.getbitesrewards&hl=en_US' target='_blank'>
                                    <Box sx={{width:{xs:200,sm:200,md:250,lg:300,xl:300}}}>
                                        <img style={{width:'100%'}} src={googlePlay} alt='Google Play'/>
                                    </Box>
                                </Button>
                            </Grid>
                            <Grid item xs={8} sm={8} md={6} lg={6} xl={6} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                <Button fullWidth href='https://apps.apple.com/us/app/getbites-rewards/id6479451401' target='_blank'>
                                    <Box sx={{width:{xs:200,sm:200,md:250,lg:300,xl:300}}}>
                                        <img style={{width:'100%'}} src={appleStore} alt='App Store'/>
                                    </Box>
                                </Button> 
                            </Grid>
                        </Grid>
                    </motion.div>
                    
                    <Grid item xs={8} sm={8} md={6} lg={6} xl={6} display={'flex'} justifyContent={'center'} mt={5}>
                        <Button variant='outlined' fullWidth sx={{p:2, borderRadius:10, borderColor:'#595959'}}
                            onClick={()=>handleSignOut()}
                        >
                            <Typography fontWeight={'bold'} color={'primary.contrastAux'}>
                                Log Out
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                        <Button variant='text' LinkComponent={'a'} target='_blank' href='https://rewards.snowie.com/app/privacypolicy'>
                            <Typography fontWeight={'bold'}>
                                Privacy Policies
                            </Typography>
                        </Button>
                        <Button variant='text'>
                            <Typography fontWeight={'bold'}>
                                Terms of Service
                            </Typography>
                        </Button>
                        <Button variant='text' color='error'>
                            <Typography fontWeight={'bold'}>Delete account</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}