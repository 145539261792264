import React, { useState } from 'react';
import { AppBar, Box, Toolbar, IconButton, Menu, Typography, Container, Button, Drawer, ListItem, List, Divider, Link, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useHistory } from 'react-router-dom';



import logo from '../../assets/images/logos/GetBitesRewards_logo.png';
import ScrollToColor01 from './ScrollToColor';




const ResponsiveAppBar = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [drawerIsOpen, setDrawerIsOpen] = React.useState(false);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const history = useHistory()
    const redirect = (route) => {
        history.push(route);
        setDrawerIsOpen(false)
    }
  

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
        }

        setDrawerIsOpen(open);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

 

    return (
        <ScrollToColor01>
            <AppBar position="fixed" color="transparent" elevation={1}>
                <Container maxWidth="lg">
                    <Toolbar component='div' disableGutters sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                        <Button onClick={()=>history.push('/')} sx={{display:{xs:'none',md:'flex'}}}>
                            <img style={{width:160}} src={logo} alt='GetBitesRewards' />
                        </Button>

                        <Button onClick={()=>history.push('/')} sx={{display:{xs:'flex',md:'none'}}}>
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{ mr: 5,flexGrow:1}}
                            >
                                <img style={{width:150}} src={logo} alt='GetBitesRewards' />
                            </Typography>
                        </Button>

                        <Box sx={{ flexGrow: 0, display:{xs:'none',md:'flex'},alignItems:'center' }}>
                            
                            
                            <Button
                                onClick={()=> redirect('/') }
                                sx={{ my: 2, color:'primary.main', display: 'block' }}
                            >
                                <Typography>Home</Typography>
                            </Button>

                            <Button
                                onClick={()=> redirect('/auth/vendorSignUp') }
                                sx={{ my: 2, color:'primary.main', display: 'block' }}
                            >
                                <Typography>Vendor</Typography>
                            </Button>

                            <Button
                                onClick={()=> redirect('/auth/signIn') }
                                sx={{ my: 2,ml:2, display: 'block', color:'primary.contrastText' }}
                                variant='contained'
                                color='primary'
                            >
                                <Typography>Login</Typography>
                            </Button>

                            
                            {/*<MyApp mode={props.mode}/>*/}
                        </Box>

                        <Box sx={{ flexGrow: 0, display:{xs:'flex',md:'none'},alignItems:'center' }}>
                            
                            <IconButton 
                                onClick={ toggleDrawer(true) }
                                color="inherit"
                            >
                                <MenuIcon/>
                            </IconButton>
                        </Box>
                
                        <Drawer
                            anchor='right'
                            open={drawerIsOpen}
                            onClose={toggleDrawer(false)}
                        >
                            <div style={{width:250}}>
                                <List style={{paddingLeft:10,paddingRight:10}}>
                                    <ListItem>
                                        <Button onClick={()=>redirect('/')}>
                                            <Typography sx={{textTransform:'none'}}>
                                                Home
                                            </Typography>
                                        </Button>
                                    </ListItem>

                                    <ListItem>
                                        <Button onClick={()=>redirect('/auth/vendorSignUp')}>
                                            <Typography sx={{textTransform:'none'}}>
                                                Vendor
                                            </Typography>
                                        </Button>
                                    </ListItem>
                                    
                                    
                                    <Divider/>
                                    <ListItem>
                                        <Button onClick={()=>redirect('/auth/signIn')}>
                                            <Typography sx={{textTransform:'none'}}>
                                                Login
                                            </Typography>
                                        </Button>
                                    </ListItem>
                                </List>
                            </div>
                        </Drawer>
                    </Toolbar>
                </Container>
            </AppBar>
        </ScrollToColor01>
    );
};
export default ResponsiveAppBar;
