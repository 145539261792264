import VendorRoutes from "../../apiRoutes/VendorRoutes";
import { GET, POST } from "../ConsumeApiController";

export async function GetAll(vendor_id)
{
    let response = await GET(VendorRoutes.Transactions.GetAll+vendor_id);
    return response;
}

export async function GetTransactionsStatiticsByVendor(vendor_id)
{
    let response = await GET(VendorRoutes.Transactions.GetTransactionsStatitics+vendor_id);
    return response;
}

export async function GetTop10(vendor)
{
    let response = await GET(VendorRoutes.Transactions.GetTop10+vendor);
    return response;
}

export async function GetAllCustomersByVendor(vendor)
{
    let response = await GET(VendorRoutes.Customers.GetAll+vendor);
    return response;
}