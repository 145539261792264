import React from "react";
import { Button, Dialog, DialogContent, Grid, IconButton, Slide, Typography, useTheme } from "@mui/material";
import QRCode from "react-qr-code";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function UserQr(props)
{
    const theme = useTheme();
    //const [open,setOpen] = useState(props.open)
    return(
        <Dialog
            fullScreen
            open={props.open}
            onClose={props.onClose}
            TransitionComponent={Transition}
        >
            <DialogContent>
                <Grid container spacing={3} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <IconButton sx={{borderRadius:10}} onClick={props.onClose}>
                            <FontAwesomeIcon color={theme.palette.error.main} icon={faClose}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" textAlign={'center'}>Present this QR Code to the</Typography>
                        <Typography variant="h6" fontWeight={'bold'} textAlign={'center'}>{props.provider}</Typography>
                        <Typography variant="h6" textAlign={'center'}>Employee</Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} md={4} lg={4} xl={4} display={'flex'} justifyContent={'center'} mt={5}>
                        <QRCode
                            style={{ width:'100%'}}
                            value={props.value}
                        />
                    </Grid>

                    <Grid item xs={12} sm={8} md={4} lg={4} xl={4} mt={5}>
                        <Button onClick={props.onClose} fullWidth variant="contained" color="primary">Done</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}