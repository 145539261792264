import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut, Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const data = {
  labels: ['New (Last added)', 'Active',],
  datasets: [
    {
      label: '# of Users',
      data: [25,100],
      backgroundColor: [
        'rgba(54, 162, 235, 0.2)',
        'rgba(75, 192, 192, 0.2)',
      ],
      borderColor: [
        'rgba(54, 162, 235, 1)',
        'rgba(75, 192, 192, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

export default function CustomersChart(props) {
  return(
    <Pie
        style={{maxHeight:200}}
        data={{
            labels: props.labels,
            datasets:[
                {
                    label: '(last month)',
                    data: props.data,
                    backgroundColor: [
                      'rgba(20, 122, 194, 0.2)',
                      'rgba(243, 50, 50, 0.2)',
                    ],
                    borderColor: [
                      'rgba(20, 122, 194, 1)',
                      'rgba(243, 50, 50, 1)',
                    ],
                    borderWidth: 1,
                }
            ]
            
        }}
    />
  )
}
