import { useState } from "react";
import { ClickAwayListener, Tooltip, Typography, tooltipClasses } from "@mui/material";
import styled from "styled-components";


const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  });


export default function CustomTooltip(props)
{
    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return(
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <div >
                <CustomWidthTooltip
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    onClick={handleTooltipOpen}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                        <Typography>
                            {props.title}
                        </Typography>
                    }
                >
                    {props.icon}
                </CustomWidthTooltip>
            </div>
        </ClickAwayListener>
    )
}