import VendorRoutes from "../../apiRoutes/VendorRoutes";
import { GET, POST } from "../ConsumeApiController";


export async function GetAll(vendor_id)
{
    let response = await GET(VendorRoutes.Locations.GetAll+vendor_id);
    return response;
}


export async function Add(name,description,street,city,state,zip_code,status,commerceid)
{
    const params = [
        {name:'name',value:name},
        {name:'description',value:description},
        {name:'street',value:street},
        {name:'city',value:city},
        {name:'state',value:state},
        {name:'zip_code',value:zip_code},
        {name:'status',value:status},
        {name:'commerceid',value:commerceid}
    ];

    let response = await POST(VendorRoutes.Locations.Add,params);
    return response;
}


export async function Update(id,name,description,street,city,state,zip_code,commerceid)
{
    const params = [
        {name:'id',value:id},
        {name:'name',value:name},
        {name:'description',value:description},
        {name:'street',value:street},
        {name:'city',value:city},
        {name:'state',value:state},
        {name:'zip_code',value:zip_code},
        {name:'commerceid',value:commerceid},
    ];

    let response = await POST(VendorRoutes.Locations.Update+id,params);
    return response;
}

export async function ChangeStatus(id)
{
    let response = await GET(VendorRoutes.Locations.ChangeStatus+id);
    return response;
}