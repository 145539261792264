import AdminRoutes from "../../apiRoutes/AdminRoutes";
import { GET, POST } from "../ConsumeApiController";


export async function GetAll()
{
    let response = await GET(AdminRoutes.Customers.GetAll);
    return response;
}


export async function GetCustomersStatitics(initialDate,finalDate)
{
    let response = await GET(AdminRoutes.Customers.GetCustomersStatitics+initialDate+'/'+finalDate);
    return response;
}

export async function ChangeStatus(id)
{
    let response = await GET(AdminRoutes.Customers.ChangeStatus+id);
    return response;
}

export async function RemoveCustomer(id)
{
    let response = await GET(AdminRoutes.Customers.Remove+id);
    return response;
}