import { useReducer } from "react";
import { Box, Container, Grid, Paper, TextField, Typography, InputAdornment, Checkbox, Link, Button } from "@mui/material";
import SectionTitle from "../../components/sectionTitle";


import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Password';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ModalDialog from "../../components/modalDialog";
import { useHistory } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { personSignUp } from "../../controllers/AuthController";


const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;


//DECLARAMOS LOS ESTADOS INICIALES
const initialState = {
      
    email : '', errorEmail : false,
    username:'', errorUsername: false,
    password : '', errorPassword : false,
    passwordConfirm : '', errorPasswordConfirm : false,
    agree:false, errorAgree: false,
    
    isLoading : false,
    registrado: false,
    errorSignIn : false,
    errorSignInMsg : '',
    redirectToVerify : false,

    isOpen : false,
    title:'',
    msg:''
  }
  
  function reducer( state, { field, value })
  {
    return{
        ...state,
        [field] : value
    }
  }
  



export default function PersonSignUp()
{
    const [state,setState] = useReducer(reducer,initialState);
    const {
        email, errorEmail,
        username, errorUsername,
        password, errorPassword,
        passwordConfirm, errorPasswordConfirm,
        agree, errorAgree,

        isLoading,
        errorSignIn, errorSignInMsg,
        redirectToVerify,
        registrado,

        isOpen,
        title,
        msg,

    } = state;

    const onChange = (e) => {
        setState({field : e.target.name, value : e.target.value});
    }

    const history = useHistory();
    const redirect = (route) => {
        history.push(route)
    }


    const formVerification = () => {
        var ban = 0;

        if(email === '' || emailReg.test(email) === false)
        {
            setState({field:'errorEmail',value:true});
            ban = 1;
        }

        if(username === '')
        {
            setState({field:'errorUsername',value:true});
            ban = 1;
        }
        
            
        if(password === '')
        {
            setState({field:'errorPassword',value:true});
            ban = 1;
        }

        if(passwordConfirm === '')
        {
            setState({field:'errorPasswordConfirm',value:true});
            ban = 1;
        }

        if(password !== passwordConfirm)
        {
            setState({field:'errorPasswordConfirm',value:true});
            ban = 1;
        }

        if(agree !== true)
        {
            setState({field:'errorAgree',value:true});
            ban = 1;
        }
        
        return ban;
    }

    const handleSubmit = async () => {
        if(formVerification() === 1)
            return;
            
        setState({field:'isLoading',value:true})
        let response = await personSignUp(email,username,password);
        if(response.success === true)
        {
            setState({field:'title',value:'Operacion Exitosa!'});
            setState({field:'msg',value:response.message});
            setState({field:'isOpen',value:true});
        }
        else if(response.success === false)
        {
            setState({field:'title',value:'Operacion Fallida!'});
            setState({field:'msg',value:response.message});
            setState({field:'isOpen',value:true});
        }
        else
        {
            setState({field:'title',value:'Operacion Fallida!'});
            setState({field:'msg',value:'En estos momentos su solicitidu no puede ser procesada!'});
            setState({field:'isOpen',value:true});
        }
        
        setState({field:'isLoading',value:false})
    }


    const handleclose = async () => {
        if(title === 'Operacion Exitosa!')
        {
            setState({field:'title',value:''});
            setState({field:'msg',value:''});
            setState({field:'isOpen',value:false});

            redirect('/auth/login');
        }
        else
        {
            setState({field:'title',value:''});
            setState({field:'msg',value:''});
            setState({field:'isOpen',value:false});
        }
    }

    return(
        <Box
            sx={{
                display:'flex',
                flexDirection:'column',
                alignItems:'center',
                justifyContent:'center',
                pb:5
            }}
        >
        <Container maxWidth='sm'>
            <SectionTitle title='Registro de Persona'/>
            
            <Paper elevation={6} sx={{pt:5,pb:5}}>
                <Box
                    component='form'
                    sx={{
                        width:'100%',
                        '& .MuiTextField-root':{mb:3}
                    }}
                    spacing={2}
                    noValidate
                    autoComplete="off"
                >
                    {/**Email */}
                    <TextField
                        fullWidth
                        id="email"
                        label="Correo"
                        placeholder='Correo'
                        name='email'
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                <EmailIcon />
                            </InputAdornment>
                            ),
                        }}
                        value={email}
                        onChange = { onChange }
                        onBlur={ () => (email === '' ? setState({field:'errorEmail',value:true}) : setState({field:'errorEmail',value:false}) ) }
                        error = {errorEmail}
                        helperText={errorEmail && 'Ingrese el correo electronico'}
                    />

                    {/**Username */}
                    <TextField
                        fullWidth
                        id="username"
                        label="Usuario"
                        placeholder='Nombre de usuario'
                        name='username'
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                <AlternateEmailIcon />
                            </InputAdornment>
                            ),
                        }}
                        value={username}
                        onChange = { onChange }
                        onBlur={ () => (username === '' ? setState({field:'errorUsername',value:true}) : setState({field:'errorUsername',value:false}) ) }
                        error = {errorUsername}
                        helperText={errorUsername && 'Ingrese el nombre de usuario'}
                    />
                    <TextField
                        fullWidth
                        id="password"
                        name='password'
                        label="Contraseña"
                        placeholder='Contraseña'
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                <PasswordIcon />
                            </InputAdornment>
                            ),
                        }}
                        type="password"
                        value={password}
                        onChange = { onChange }
                        onBlur={ () => (password === '' ? setState({field:'errorPassword',value:true}) : setState({field:'errorPassword',value:false}) ) }
                        error = {errorPassword}
                        helperText={errorPassword && 'Ingrese la contraseña'}
                    />

                    <TextField
                        fullWidth
                        id="passwordConfirm"
                        name='passwordConfirm'
                        label="Confirmar Contrasena"
                        placeholder='Confirmar Contrasena'
                        variant="outlined"
                            InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                <PasswordIcon />
                            </InputAdornment>
                            ),
                        }}
                        type="password"
                        value={passwordConfirm}
                        onChange = { onChange }
                        onBlur={ () => (passwordConfirm === '' ? setState({field:'errorPasswordConfirm',value:true}) : setState({field:'errorPasswordConfirm',value:false}) ) }
                        error = {errorPasswordConfirm}
                        helperText = {(password !== '' && passwordConfirm === '') && 'Ingrese la Confirmación de Contraseña' || passwordConfirm !== password && 'La contraseña no coincide'}
                    />

                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:30}}>
                        <Checkbox
                                checked={agree} 
                                onChange={()=>{ 
                                    setState({field:'agree',value:!agree})} 
                                }
                                name="agree"
                                id='agree' 
                                onBlur={ () => (agree === false ? setState({field:'errorAgree',value:true}) : setState({field:'errorAgree',value:false}) ) }
                            />
                        <Typography>Declaro que he leído y acepto los <Link sx={{color:'secondary.main',textDecoration:'none'}} component='a' href='https://anzteenamora.com/TerminosCondicionesATE.pdf' target='_blank'>Términos y Condiciones</Link> de uso de QUD. </Typography>
                    </div>
                    {
                        errorAgree === true
                        ?<Typography style={{color:'#ff0000'}}>Debe leer y aceptar los Términos y Condiciones de uso de QUD</Typography>
                        :null
                    }
                    <br/>
                    <LoadingButton
                        fullWidth
                        variant='contained'
                        loading={isLoading}
                        onClick={()=>handleSubmit()}
                    >
                        Registrarme
                    </LoadingButton>
                        
                </Box>
            </Paper>

            <ModalDialog
                open={isOpen}
                title={title}
                msg={msg}
                handleClose={()=>handleclose()}
                handleAction={()=>handleclose()}
            />
        </Container>
        </Box>
    )
}