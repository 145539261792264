import { useEffect, useState } from "react";
import { Avatar, Box, CircularProgress, Container, Divider, Grid, List, ListItem, ListItemText, ListSubheader, Paper, Typography } from "@mui/material";
import SectionTitle from "../../components/sectionTitle";
import { GetCountCustomers, GetCustomersStatitics } from "../../controllers/admin/CustomersController";
import moment from "moment";
import CardInfo from "../../components/cardInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCookieBite, faInfo, faInfoCircle, faLineChart, faPieChart, faQrcode, faSnowflake, faTrophy, faUserPlus, faUsers, faWind } from "@fortawesome/free-solid-svg-icons";
import { GetCountTransactions, GetTop10, GetTransactionsStatitics } from "../../controllers/admin/TransactionsController";
import UserChart from "./customers/userChart";
import AdminCustomersChart from "./customers/userChart";
import CustomTooltip from "../../components/tooltip";

export default function DashboardAdmin()
{
    const [countCustomers,setCountCustomers] = useState([]);
    const [countTransactions,setCountTransactions] = useState([]);

    const [transactionsStatitics,setTransactionsStatitics] = useState([]);

    const [customers,setCustomers] = useState([]);
    const [vendor,setVendor] = useState([]);
    const [cashiers,setCashiers] = useState([]);

    const [points,setPoints] = useState('');
    const [redeemed,setRedeemed] = useState('');
    const [unredeemed,setUnredeemed] = useState('');
    const [scans,setScans] = useState([]);

    const [newCustomers,setNewCustomers] = useState(0);
    const [activeCustomers,setActiveCustomers] = useState(0);
    const [totalCustomers,setTotalCustomers] = useState(0);
    const [isCustomersLoading,setIsCustomersLoading] = useState(false);

    useEffect(()=>{
        handleGetCountCustomers();
        handleGetCountTransactions();
        handleGetTop10();
        handleGetTransactionsStatitics();
    },[])

    /*const handleGetCountCustomers = async () => {
        let starDate = new Date('2021-01-01');
        starDate = moment(starDate).format('YYYY-MM-DD')
        console.log('DATE = ',starDate)
        //startDate = startDate()
        let response = await GetCountCustomers(starDate,'2024-01-01');
        if(response.success === true)
        {
            setCountCustomers(response.data);
        }
    }*/

    const handleGetCountCustomers = async () => {
        //const firstDay = moment().startOf('month').format('YYYY-MM-DD');
        let firstDay = moment().subtract(45, 'days').calendar();
        firstDay = moment(firstDay).format('YYYY-MM-DD'); 
        //return console.log('DATE ===> ',firstDay);
        const lastDay   = moment().endOf('month').format('YYYY-MM-DD');
        setIsCustomersLoading(true);
        let response = await GetCustomersStatitics(firstDay,lastDay);
        if(response.success === true)
        {
            setNewCustomers(response.data.newCustomers);
            setActiveCustomers(response.data.activeCustomers);
            setTotalCustomers(response.data.totalCustomers);
        }
        setIsCustomersLoading(false);
    }

    const handleGetCountTransactions = async () => {
        let starDate = new Date('2021-01-01');
        starDate = moment(starDate).format('YYYY-MM-DD')
        //console.log('DATE = ',starDate)
        //startDate = startDate()
        let response = await GetCountTransactions(starDate,'2024-01-01');
        if(response.success === true)
        {
            setCountTransactions(response.data);
        }
    }
    


    const handleGetTransactionsStatitics = async () => {
        let response = await GetTransactionsStatitics();
        if(response.success === true)
        {
            setTransactionsStatitics(response.data.transactions)
            setPoints(response.data.points);
            setScans(response.data.scans);
            setRedeemed(response.data.redeemed);
            setUnredeemed(response.data.unredeemed);
        }
    }



    const handleGetTop10 = async () => {
        let response = await GetTop10();
        if(response.success === true)
        {
            setCustomers(response.data.customers);
            setVendor(response.data.vendor);
            setCashiers(response.data.cashiers);
        }
    }




    return(
        <Container>
            <SectionTitle title='Dashboard'/>

            <Grid container spacing={3} mb={5}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" fontWeight='bold'>Customers</Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper elevation={4} sx={{height:'100%'}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display='flex' flexDirection='row' alignItems='center'>
                                <Avatar sx={{bgcolor:'primary.main'}}>
                                    <FontAwesomeIcon icon={faUsers}/>
                                </Avatar>
                                <Box style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <Typography ml={1} variant="h6">Customers</Typography>
                                </Box>
                            </Grid>
                            {
                                
                                isCustomersLoading === true
                                ?
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                                        <CircularProgress/>
                                    </Box>
                                </Grid>
                                :
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <List sx={{width:'100%'}}>
                                        <ListItem sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                            <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                <Typography ml={1} fontSize={20}>New </Typography>
                                                &nbsp;&nbsp;&nbsp;
                                                <CustomTooltip
                                                    title='Customers that downloaded and registered for the GetBites App in the last 45 days'
                                                    icon={<FontAwesomeIcon color="#b3b3b3" icon={faInfoCircle}/>}
                                                />
                                            </Box>
                                            <Typography fontSize={20}>{newCustomers}</Typography>
                                        </ListItem>
                                        <ListItem sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                            <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                <Typography ml={1} fontSize={20}>Active </Typography>
                                                &nbsp;&nbsp;&nbsp;
                                                <CustomTooltip
                                                    title='Customers that have completed a GetBites Scan in the last 45 days'
                                                    icon={<FontAwesomeIcon color="#b3b3b3" icon={faInfoCircle}/>}
                                                />
                                            </Box>
                                            <Typography fontSize={20}>{activeCustomers}</Typography>
                                        </ListItem>
                                        <ListItem sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                            <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                <Typography ml={1} fontSize={20}>All</Typography>
                                                &nbsp;&nbsp;&nbsp;
                                                <CustomTooltip
                                                    title='Customers that downloaded and registered for the GetBites App'
                                                    icon={<FontAwesomeIcon color="#b3b3b3" icon={faInfoCircle}/>}
                                                />
                                            </Box>
                                            <Typography fontSize={20}>{totalCustomers}</Typography>
                                        </ListItem>
                                    </List>
                                </Grid>
                            }
                            
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper elevation={4}>
                        <Grid container spacing={3} maxHeight={350}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display='flex' flexDirection='row' alignItems='center'>
                                <Avatar sx={{bgcolor:'#000'}}>
                                    <FontAwesomeIcon icon={faPieChart}/>
                                </Avatar>
                                <Box style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <Typography ml={1} variant="h6">Active/Inactive customer</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <AdminCustomersChart
                                    labels = {['Active','Inactive']}
                                    data = {[activeCustomers,parseFloat(totalCustomers)-parseFloat(activeCustomers)]}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" fontWeight='bold'>Transactions</Typography>
                </Grid>

                {/**Points/Snowflakes */}
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                    <Paper elevation={4} sx={{height:'100%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item lg={2}>
                                <Avatar sx={{ width: 70, height: 70, bgcolor:'primary.main' }}>
                                    <FontAwesomeIcon size='2x' icon={faCookieBite}/>
                                </Avatar>
                            </Grid>
                            <Grid item lg={10}>
                                <Grid container spacing={3} >
                                    <Grid item lg={12} xl={12}>
                                        <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                            <Typography ml={1} fontSize={20}>Points/Bites</Typography>
                                            &nbsp;&nbsp;&nbsp;
                                            <CustomTooltip
                                                title={'Traditionally known as punches on a punch card, these are every punch/point that a customer receives for each Bite they purchase. For example, if they buy three Bites they can receive three points. When they qualify for a free Bite (have 6 points) and they receive the free Bite, they lose or rather redeem the 6 points in for the Free Bite'}
                                                icon={<FontAwesomeIcon color="#b3b3b3" icon={faInfoCircle}/>}
                                            />
                                            
                                        </Box>
                                        <Typography ml={1} fontSize={20}>{points}</Typography>
                                    </Grid>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                {/**Scans */}
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper elevation={4}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
                                <Avatar sx={{ width: 70, height: 70, bgcolor:'#000' }}>
                                    <FontAwesomeIcon size='2x' icon={faQrcode}/>
                                </Avatar>
                            </Grid>
                            <Grid item xs={8} sm={8} md={10} lg={10} xl={10}>
                                <Grid container spacing={3} >
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',zIndex:100}}>
                                            <Typography ml={1} fontSize={20}>Scans</Typography>
                                            &nbsp;&nbsp;&nbsp;
                                            <CustomTooltip
                                                title='These are everytime you use your scanner in the GetBites Rewards app. This could be for giving points or giving free bites (removing 6 points).  Each scan can be a different amount of Bites or Rewards. For example, 1 scan can be for five points (if a customer bought 5 Bites). Likewise, 1 scan could be for multiple rewards.'
                                                icon={<FontAwesomeIcon color="#b3b3b3" icon={faInfoCircle}/>}
                                            />
                                        </Box>
                                    </Grid>
                                    {
                                        scans.length !== 0 &&
                                        scans.map((item)=>
                                            <Grid item lg={6} xl={6} sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                <Typography ml={1} mt={-3} fontSize={15}>{item.type}:</Typography>
                                                <Typography ml={1} mt={-3} fontSize={20}>{item.scans}</Typography>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>


                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper elevation={4}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item lg={2}>
                                <Avatar sx={{ width: 70, height: 70, bgcolor:'success.main' }}>
                                    <FontAwesomeIcon size='2x' icon={faTrophy}/>
                                </Avatar>
                            </Grid>
                            <Grid item lg={10}>
                                <Grid container spacing={3} >
                                    <Grid item lg={12} xl={12}>
                                        <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                            <Typography ml={1} fontSize={20}>Redeemed Rewards</Typography>
                                            &nbsp;&nbsp;&nbsp;
                                            <CustomTooltip
                                                title='These are the number of rewards that all your customers have redeemed / received free bites.'
                                                icon={<FontAwesomeIcon color="#b3b3b3" icon={faInfoCircle}/>}
                                            />
                                        </Box>
                                        <Typography ml={1} fontSize={20}>{redeemed}</Typography>
                                    </Grid>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>


                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper elevation={4}>
                        <Grid container spacing={3} alignItems='center'>
                            <Grid item lg={2}>
                                <Avatar sx={{ width: 70, height: 70, bgcolor:'secondary.main' }}>
                                    <FontAwesomeIcon size='2x' icon={faWind}/>
                                </Avatar>
                            </Grid>
                            <Grid item lg={10}>
                                <Grid container spacing={3} >
                                    <Grid item lg={12} xl={12}>
                                        <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                            <Typography ml={1} fontSize={20}>Unredeemed Rewards</Typography>
                                            &nbsp;&nbsp;&nbsp;
                                            <CustomTooltip
                                                title='These are the number of rewards that all your customers have saved in their GetBites Rewards App and still can redeem for a free bite. (1 Unredeemed Reward = 6 saved points).'
                                                icon={<FontAwesomeIcon color="#b3b3b3" icon={faInfoCircle}/>}
                                            />
                                        </Box>
                                        <Typography ml={1} fontSize={20}>{unredeemed}</Typography>
                                    </Grid>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container spacing={3} mb={5}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" fontWeight='bold'>Top 10</Typography>
                </Grid>
            </Grid>

            <Grid container spacing={3}>

                {/**Vendor top 10 */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Paper>
                        <Typography variant='h6' fontWeight='bold' textAlign='center' mb={2}>Vendor</Typography>
                        <List
                            subheader={
                                <ListSubheader sx={{display:'flex',justifyContent:'space-between'}} component="div" id="nested-list-subheader">
                                    <Typography fontSize={20}>Vendor</Typography>
                                    <Typography fontSize={20}>transactions</Typography>
                                </ListSubheader>
                            }
                        >
                            <Divider/>
                            {
                                vendor.length !== 0 &&
                                vendor.map(item=>
                                    <ListItem>
                                        <ListItemText
                                            primary={<Box sx={{display:'flex',justifyContent:'space-between'}}>
                                                <Typography fontSize={20}>{item.branch}</Typography>
                                                <Typography fontSize={20}>{item.nTransactions}</Typography>
                                            </Box>}
                                        />
                                    </ListItem>
                                )
                            }
                        </List>
                    </Paper>
                </Grid>


                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper>
                        <Typography variant='h6' fontWeight='bold' textAlign='center' mb={2}>Customers</Typography>
                        <List
                            subheader={
                                <ListSubheader sx={{display:'flex',justifyContent:'space-between'}} component="div" id="nested-list-subheader">
                                    <Typography fontSize={20}>Customers</Typography>
                                    <Typography fontSize={20}>transactions</Typography>
                                </ListSubheader>
                            }
                        >
                            <Divider/>
                            {
                                customers.length !== 0 &&
                                customers.map(item=>
                                    <ListItem>
                                        <ListItemText
                                            primary={<Box sx={{display:'flex',justifyContent:'space-between'}}>
                                                <Typography fontSize={20}>{item.customer}</Typography>
                                                <Typography fontSize={20}>{item.nTransactions}</Typography>
                                            </Box>}
                                        />
                                    </ListItem>
                                )
                            }
                        </List>
                    </Paper>
                </Grid>

                


                {/**Cashiers top 10 */}
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper>
                        <Typography variant='h6' fontWeight='bold' textAlign='center' mb={2}>Cashiers</Typography>
                        <List
                            subheader={
                                <ListSubheader sx={{display:'flex',justifyContent:'space-between'}} component="div" id="nested-list-subheader">
                                    <Typography fontSize={20}>Cashier</Typography>
                                    <Typography fontSize={20}>transactions</Typography>
                                </ListSubheader>
                            }
                        >
                            <Divider/>
                            {
                                cashiers.length !== 0 &&
                                cashiers.map(item=>
                                    <ListItem>
                                        <ListItemText
                                            primary={<Box sx={{display:'flex',justifyContent:'space-between'}}>
                                                <Typography fontSize={20}>{item.cashier}</Typography>
                                                <Typography fontSize={20}>{item.nTransactions}</Typography>
                                            </Box>}
                                        />
                                    </ListItem>
                                )
                            }
                        </List>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )






    return(
        <Container>
            <SectionTitle title='Dashboard'/>

            <Grid container spacing={3} mb={5}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" fontWeight='bold'>Customers</Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper elevation={4}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display='flex' flexDirection='row' alignItems='center'>
                                <Avatar sx={{bgcolor:'primary.main'}}>
                                    <FontAwesomeIcon icon={faUsers}/>
                                </Avatar>
                                <Box style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <Typography ml={1} variant="h6">Customers</Typography>
                                    <Typography ml={1} mt={1} variant="caption">(Last 30 Days)</Typography>
                                </Box>
                                
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <List sx={{width:'100%'}}>
                                    <ListItem sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                        <Typography>New &nbsp;&nbsp;&nbsp;<FontAwesomeIcon color="#b3b3b3" icon={faInfoCircle}/></Typography>
                                        <Typography>25</Typography>
                                    </ListItem>
                                    <ListItem sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                        <Typography>Active &nbsp;&nbsp;&nbsp;<FontAwesomeIcon color="#b3b3b3" icon={faInfoCircle}/></Typography>
                                        <Typography>100</Typography>
                                    </ListItem>
                                    <ListItem sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                        <Typography>Total &nbsp;&nbsp;&nbsp;<FontAwesomeIcon color="#b3b3b3" icon={faInfoCircle}/></Typography>
                                        <Typography>125</Typography>
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper elevation={4}>
                        <Grid container spacing={3} maxHeight={250}>
                            
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <UserChart/>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>



                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" fontWeight='bold'>Transactions</Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Paper elevation={4}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display='flex' flexDirection='row' alignItems='center'>
                                <Avatar sx={{bgcolor:'success.main'}}>
                                    <FontAwesomeIcon icon={faLineChart}/>
                                </Avatar>
                                <Typography ml={1} variant="h6">{'Rewards'}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <List sx={{width:'100%'}}>
                                    <ListItem sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                        <Typography>Total Redeemed &nbsp;&nbsp;&nbsp;<FontAwesomeIcon color="#b3b3b3" icon={faInfoCircle}/></Typography>
                                        <Typography>{1}</Typography>
                                    </ListItem>
                                    <ListItem sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                        <Typography>Total Unredeemed &nbsp;&nbsp;&nbsp;<FontAwesomeIcon color="#b3b3b3" icon={faInfoCircle}/></Typography>
                                        <Typography>{4}</Typography>
                                    </ListItem>
                                    <ListItem sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                        <Typography>Total Rewarded  &nbsp;&nbsp;&nbsp;<FontAwesomeIcon color="#b3b3b3" icon={faInfoCircle}/></Typography>
                                        <Typography>{5}</Typography>
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" fontWeight='bold'>Transactions Type</Typography>
                </Grid>
                {
                    transactionsStatitics.length !== 0 &&
                    transactionsStatitics.map(item =>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Paper elevation={4}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display='flex' flexDirection='row' alignItems='center'>
                                        <Avatar sx={{bgcolor:item.transaction_type === 'rewarded' ? 'primary.main' : 'secondary.main'}}>
                                            {
                                                item.transaction_type === 'rewarded'
                                                ?<FontAwesomeIcon icon={faSnowflake}/>
                                                :<FontAwesomeIcon icon={faWind}/>
                                            }
                                        </Avatar>
                                        <Typography ml={1} variant="h6">{item.transaction_type} &nbsp;&nbsp;&nbsp;<FontAwesomeIcon color="#b3b3b3" icon={faInfoCircle}/></Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <List sx={{width:'100%'}}>
                                            <ListItem sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                                <Typography>Total Scans</Typography>
                                                <Typography>{item.scans}</Typography>
                                            </ListItem>
                                            <ListItem sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                                <Typography>Total Points</Typography>
                                                <Typography>{item.points}</Typography>
                                            </ListItem>
                                            <ListItem sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                                <Typography>Total Snowies</Typography>
                                                <Typography>{item.snowies}</Typography>
                                            </ListItem>
                                        </List>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    )
                                        }
            </Grid>


            {/** 
            <Grid container spacing={3} mb={5}>
                <CardInfo
                    bgcolor='primary.main'
                    title='total customers'
                    value={countCustomers.clientes_totales}
                    icon={<FontAwesomeIcon icon={faUsers} />}
                />
                <CardInfo
                    bgcolor='success.main'
                    title='new customers'
                    value={countCustomers.clientes_nuevos}
                    icon={<FontAwesomeIcon icon={faUserPlus} />}
                />

                <CardInfo
                    bgcolor='primary.main'
                    title='total rewards'
                    value={countTransactions.rewards}
                    icon={<FontAwesomeIcon icon={faSnowflake} />}
                />
                <CardInfo
                    bgcolor='secondary.main'
                    title='total redemptions'
                    value={countTransactions.redemptions}
                    icon={<FontAwesomeIcon icon={faWind} />}
                />
            </Grid>*/}

            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <Paper>
                        <Typography variant='body1' fontWeight='bold' textAlign='center' mb={2}>Customers Top 10</Typography>
                        <List
                            subheader={
                                <ListSubheader sx={{display:'flex',justifyContent:'space-between'}} component="div" id="nested-list-subheader">
                                    <Typography variant='body2'>Customers</Typography>
                                    <Typography variant='body2'>transactions</Typography>
                                </ListSubheader>
                            }
                        >
                            <Divider/>
                            {
                                customers.length !== 0 &&
                                customers.map(item=>
                                    <ListItem>
                                        <ListItemText
                                            primary={<Box sx={{display:'flex',justifyContent:'space-between'}}>
                                                <Typography variant='body2'>{item.customer}</Typography>
                                                <Typography variant='body2'>{item.nTransactions}</Typography>
                                            </Box>}
                                        />
                                    </ListItem>
                                )
                            }
                        </List>
                    </Paper>
                </Grid>

                {/**Vendor top 10 */}
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <Paper>
                        <Typography variant='body1' fontWeight='bold' textAlign='center' mb={2}>Vendor Top 10</Typography>
                        <List
                            subheader={
                                <ListSubheader sx={{display:'flex',justifyContent:'space-between'}} component="div" id="nested-list-subheader">
                                    <Typography variant='body2'>Vendor</Typography>
                                    <Typography variant='body2'>transactions</Typography>
                                </ListSubheader>
                            }
                        >
                            <Divider/>
                            {
                                vendor.length !== 0 &&
                                vendor.map(item=>
                                    <ListItem>
                                        <ListItemText
                                            primary={<Box sx={{display:'flex',justifyContent:'space-between'}}>
                                                <Typography variant='body2'>{item.branch}</Typography>
                                                <Typography variant='body2'>{item.nTransactions}</Typography>
                                            </Box>}
                                        />
                                    </ListItem>
                                )
                            }
                        </List>
                    </Paper>
                </Grid>


                {/**Cashiers top 10 */}
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <Paper>
                        <Typography variant='body1' fontWeight='bold' textAlign='center' mb={2}>Cashiers Top 10</Typography>
                        <List
                            subheader={
                                <ListSubheader sx={{display:'flex',justifyContent:'space-between'}} component="div" id="nested-list-subheader">
                                    <Typography variant='body2'>Cashier</Typography>
                                    <Typography variant='body2'>transactions</Typography>
                                </ListSubheader>
                            }
                        >
                            <Divider/>
                            {
                                cashiers.length !== 0 &&
                                cashiers.map(item=>
                                    <ListItem>
                                        <ListItemText
                                            primary={<Box sx={{display:'flex',justifyContent:'space-between'}}>
                                                <Typography variant='body2'>{item.cashier}</Typography>
                                                <Typography variant='body2'>{item.nTransactions}</Typography>
                                            </Box>}
                                        />
                                    </ListItem>
                                )
                            }
                        </List>
                    </Paper>
                </Grid>

            </Grid>
        </Container>
    )
}