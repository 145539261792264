import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress, IconButton, Link, Tooltip } from "@mui/material";
import { useState } from "react";
import { ResendEmail } from "../../../controllers/vendor/CashiersController";
import ModalDialog from "../../../components/modalDialog";

export default function ForwardEmailToCashier(props)
{
    const [isLoading,setIsLoading] = useState(false);
    const [open,setOpen] = useState(false);
    const [title,setTitle] = useState('');
    const [msg,setMsg] = useState('');
    const [action,setAction] = useState('');

    const handleResendEmail = async (email) => {
        setIsLoading(true);
        let response = await ResendEmail(email);
        if(response.success === true)
        {
            setTitle('Success');
            setMsg(response.message);
            setAction('Done');
            setOpen(true);
        }
        else
        {
            setTitle('Error');
            setMsg('Email cannot be sent at this time');
            setAction('Try again later');
            setOpen(true);
        }
        setIsLoading(false);
    }

    if(isLoading)
        return(
            <CircularProgress/>
        )
    
    return(
        <>
            <Link
                //to='/cashiers' 
                onClick = { () => handleResendEmail(props.email)  }  
            >
                <IconButton>
                    <Tooltip title='Resend email' placement="top">
                        <FontAwesomeIcon icon={faPaperPlane}/>
                    </Tooltip> 
                </IconButton>
            </Link>
            <ModalDialog
                open={open}
                handleClose={()=>setOpen(false)}
                title={title}
                msg={msg}
                action={action}
                handleAction={()=>setOpen(false)}

            />
        </>
    )
}