import { Redirect, Route, Switch } from "react-router-dom";
import AdminLayout from "../layouts/DashboardAdmin/adminLayout";
import DashboardAdmin from "../views/admin";
import Customers from "../views/admin/customers";
import Transactions from "../views/admin/transactions";
import Users from "../views/admin/users";
import UsersAdd from "../views/admin/users/add";
import UsersUpdate from "../views/admin/users/update";
import Vendor from "../views/admin/vendor";
import VendorAdd from "../views/admin/vendor/add";
import VendorDetails from "../views/admin/vendor/details";
import VendorUpdate from "../views/admin/vendor/update";
import ChangePassword from "../views/auth/changePassword";



import AppRoute from "./AppRoute";





export default function AdminNavigation()
{
    return(
        <Switch>
            <AppRoute exact path='/' layout={AdminLayout} component={DashboardAdmin} />

            <AppRoute exact path='/vendor' layout={AdminLayout} component={Vendor} />
            <AppRoute exact path='/vendor/add' layout={AdminLayout} component={VendorAdd} />
            <AppRoute exact path='/vendor/update' layout={AdminLayout} component={VendorUpdate} />
            <AppRoute exact path='/vendor/details/:id' layout={AdminLayout} component={VendorDetails} />

            <AppRoute exact path='/transactions' layout={AdminLayout} component={Transactions} />

            <AppRoute exact path='/customers' layout={AdminLayout} component={Customers} />

            <AppRoute exact path='/users' layout={AdminLayout} component={Users} />
            <AppRoute exact path='/users/add' layout={AdminLayout} component={UsersAdd} />
            <AppRoute exact path='/users/update' layout={AdminLayout} component={UsersUpdate} />

            <AppRoute exact path='/password' layout={AdminLayout} component={ChangePassword} />

            {/**Para las rutas no encontradas */}
            <Route path="*">
                <Redirect path="/"/>
            </Route>
            
        </Switch>
    )
}