import { GET, POST } from "../ConsumeApiController";
import VendorRoutes from "../../apiRoutes/VendorRoutes";

export async function getSettings()
{
    let response = await GET(VendorRoutes.Settings.Index);
    return response;
}

export async function updateSettings(name,description)
{
    let params = [
        {name:'name',value:name},
        {name:'description',value:description},
    ];
    let response = await POST(VendorRoutes.Settings.Update,params);
    return response;
}

export async function updateLogo(logo)
{
    const params = [
        {name:'logo',value:logo}
    ];
    let response = await POST(VendorRoutes.Settings.UpdateLogo,params);
    
    return response;
}