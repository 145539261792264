import { useState } from "react";
import { Chip, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faPlay, faTrash } from "@fortawesome/free-solid-svg-icons";
import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";

export default function LocationsDataTable(props)
{
    const [locations,setLocations] = useState(props.data);

    const columns = [
        {
            name : 'name',
            label : 'Name',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'city',
            label : 'City',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'zip_code',
            label : 'Zip Code',
            options : 
            {
                filter:false,
                searchable:true
            }
        },
        {
            name : 'status',
            label : 'status',
            options : 
            {
                customBodyRender : (value, tableMeta, updateValue) => (
                    value === 1
                    ? <Chip label='active' color='success'/>
                    : <Chip label='inactive' color='error' />
                )            
            }
        }
    ];

    const ChangeStatus = async (id) => {
        
    }

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
    };

    return(
        <MUIDataTable
            title='Locations List'
            data={locations}
            columns={columns}
            options={options}
        />
    )
}