import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AppRoute from './AppRoute';

import ClientHome from '../views/client';
import ClientLayout from '../layouts/DashboardCliente/clientLayout';
import Profile from '../views/client/profile';
import Rewards from '../views/client/rewards';
import ProviderDetail from '../views/client/rewards/detail';




export default function ClientNavigation()
{
 
    
    return(
        <Switch>
            <AppRoute exact path='/' layout={ClientLayout} component={ClientHome} />
            <AppRoute exact path='/rewards' layout={ClientLayout} component={Rewards} />
            <AppRoute exact path='/rewards/details/:providerID/:providerName' layout={ClientLayout} component={ProviderDetail} />
            <AppRoute exact path='/rewards/history/:providerID' layout={ClientLayout} component={Rewards} />
            <AppRoute exact path='/profile' layout={ClientLayout} component={Profile} />
            <Route path="*">
                <Redirect path="/"/>
            </Route>
        </Switch>
    )
}
