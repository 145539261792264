import React, { useContext, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import menuIconSnowie from '../../assets/images/logos/MenuSnowie.webp';
import logoQUD from '../../assets/images/logos/logo_snowie.webp';
import { Avatar, Button, Container, Drawer, Menu, MenuItem } from '@mui/material';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import AuthContext from '../../navigation/context/authContext';
import { imagesURL } from '../../apiRoutes/apiBase';
import UserContext from '../../navigation/context/userContext';
import MainListItems from './mainList';
import { useHistory } from 'react-router-dom';
import Copyright from '../../components/copyRight';
import ScrollToColor from '../Website/ScrollToColor';
import ClientBottomNavigation from './clientBottomNavigation';

const drawerWidth = 258;
const tamLogo = 40.3;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));






export default function ClientLayout(props) {
  const theme = useTheme();
  const {userData,setUserData} = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const { signOut } = useContext(AuthContext);

  const history = useHistory();

  useEffect(()=>{ console.log(imagesURL+'users/'+ userData.user.imagen) },[])

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
	setOpen(open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
      history.replace('/');
      signOut();
  }

  	return (
      	<Box sx={{bgcolor:'#FFF' }}>
			
			{/*<ScrollToColor>
			<AppBar position="fixed" open={open}
				sx={{
				bgcolor:'#FFF',
				borderRadius:0,
				padding:0,
				zIndex: (theme) => theme.zIndex.drawer + 1
				}}
			>
				
					<Toolbar component='div' disableGutters sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
					
						{
						open === false &&
						<Button onClick={handleDrawerOpen}>
							<img src={menuIconSnowie} style={{height:tamLogo}} alt="Snowie"/>
						</Button>
						}
						<Box sx={{ flexGrow: 0, display:{xs:'flex',md:'flex'},alignItems:'center' }}>
							<Typography color={'primary.main'}>{userData.user.name}</Typography>
							<Button onClick={handleMenu} aria-controls="menu-appbar">
								<Avatar src={imagesURL+'user/'+userData.user.imagen}/>
							</Button>
							
							<Menu
								anchorEl={anchorEl}
								id="account-menu"
								open={Boolean(anchorEl)}
								onClose={handleClose}
								onClick={handleClose}
								PaperProps={{
									elevation: 0,
									sx:
									{
									overflow: 'visible',
									filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
									mt: 1.5,
									'& .MuiAvatar-root': {
										width: 32,
										height: 32,
										ml: -0.5,
										mr: 1,
									},
									'&:before': {
										content: '""',
										display: 'block',
										position: 'absolute',
										top: 0,
										right: 14,
										width: 10,
										height: 10,
										bgcolor: 'background.paper',
										transform: 'translateY(-50%) rotate(45deg)',
										zIndex: 0,
									},
									},
								}}
								transformOrigin={{ horizontal: 'right', vertical: 'top' }}
								anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
							>
							<MenuItem onClick={()=> handleSignOut()}>
								<ListItemIcon>
								<ExitToAppIcon fontSize="small" />
								</ListItemIcon>
								Logout
							</MenuItem>
							</Menu>
						</Box>
					</Toolbar>
				
			</AppBar>
			{/*</ScrollToColor>

			<Drawer
				open={open}
				sx={{position:'relative',whiteSpace:'nowrap','& .MuiPaper-root':{padding:0,borderRadius:0},width:drawerWidth}}
				PaperProps={{
					sx:{
					backgroundColor:'primary.contrastText',
					color:'primary.contrastAux',
					borderRadius:0
					}
				}}
				onClose={toggleDrawer(false)}
			>
				<Toolbar>
					<img src={logoQUD} style={{height:tamLogo}} alt="QUDLogo"/>
					<IconButton style={{marginLeft:25,color:theme.palette.primary.contrastAux}} onClick={handleDrawerClose}>
					{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
					</IconButton>
				</Toolbar>

				<Divider/>
				
				<List>
					<Box
					role="presentation"
					onClick={toggleDrawer(false)}
					onKeyDown={toggleDrawer(false)}
					>
						<MainListItems/>
					</Box>
					
				</List>
			
				<Divider />
			</Drawer>*/}
			<Box component="main" sx={{ flexGrow: 1,bgcolor:'#FFF' }}>
				<Box sx={{ bgcolor:'#FFF', minHeight:'95vh',pt:1, pb:3,mt:-7}}>
					{props.children}
				</Box>
				<ClientBottomNavigation/>
			</Box>
        
		</Box>
    );
}