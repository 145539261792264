import { url } from "./apiBase";


const AuthRoutes = {
    personSignUp: url + 'register2',
    vendorSignUp: url + 'auth/vendor_signup',
    RegisterVerify : url + 'auth/register/verify/', //completar con {code}
    signIn: url + 'auth/login',
    signIn2: url + 'auth/login2',
    verify : url + 'register/email_verification',
    changePassword : url + 'auth/password/reset',
    //changePasswordWithToken: url + 'passwordReset/changePasswordWithToken',
    changePasswordWithToken: url + 'auth/password/forgot/reset',
    requestCodeToResetPassword: url+'auth/password/email',//To request code to reset password
    verifyRequestedCode : url + 'auth/password/verify',
    forgotPassword : 
    {
        requestCode : url + 'auth/password/email',
        verifyCode : url + 'auth/password/verify',
        resetPassword : url + 'auth/password/forgot/reset'
    }
    /**
     * CompanySignup : url + 'companysignup',
     */
};


export default AuthRoutes;